import { useEffect, useState } from 'react';
import { TextField, Typography, MenuItem } from '@mui/material';
import { useDebounce } from '../../hooks/useDebounce';
import { isMobile, renderTextWithBreakLines } from '../../utils';
// import CustomLatex from '../../components/CustomLatex';
import { Check } from '@mui/icons-material';

const EditableMultipleQuestion = ({ languageData, questionIndex, title, grade, options, correctAnswer, explanation, isEditing = false, onChange, handleGradeChange, gradeError }) => {
  const [gradeState, setGradeState] = useState(grade);
  const [titleState, setTitleState] = useState(title);
  const [optionsState, setOptionsState] = useState(options);
  const [correctAnswerState, setCorrectAnswerState] = useState(correctAnswer);
  const [explanationState, setExplanationStates] = useState(explanation);
  const debouncedGradeChange = useDebounce(handleGradeChange, 200);
  const debouncedChange = useDebounce(() => onChange ? onChange(questionIndex, titleState, optionsState, [correctAnswerState], explanationState) : null, 150);

  useEffect(() => {
    debouncedChange();
  }, [titleState, optionsState, correctAnswerState, explanationState])

  useEffect(() => {
    setTitleState(title);
    setOptionsState(options);
    setCorrectAnswerState(correctAnswer);
    setExplanationStates(explanation);
    setGradeState(grade);
  }, [title, options, correctAnswer, explanation, grade]);

  const handleCurrentGradeChange = (event) => {
    setGradeState(event.target.value);
    debouncedGradeChange(event);
  };

  return (
    <div>
      {isEditing ? (
        <>
          <TextField
            label={languageData.grade_label}
            value={gradeState ?? ""}
            onChange={e => handleCurrentGradeChange(e)}
            type='number'
            fullWidth
            variant="outlined"
            margin="normal"
            error={gradeError}
          />
          <TextField
            label={languageData.question_title}
            value={titleState}
            onChange={(e) => setTitleState(e.target.value)}
            fullWidth
            multiline
            variant="outlined"
            margin="normal"
            error={!titleState}
          />
          {optionsState?.map((option, index) => (
            <TextField
              key={index}
              label={`${languageData?.question_option_label ?? 'Option'} ${index + 1}.`}
              value={option}
              multiline
              onChange={(e) => {
                const newOptions = [...options];
                newOptions[index] = e.target.value;
                setOptionsState(newOptions);
              }}
              fullWidth
              variant="outlined"
              margin="normal"
              error={!option}
            />
          ))}
          <TextField
            select
            label={languageData.correct_answer}
            value={correctAnswerState}
            onChange={(e) => setCorrectAnswerState(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
            error={!correctAnswerState || !optionsState.includes(correctAnswerState)}
            sx={{
              '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderWidth: '4px',
              },
            }}
          >
            {optionsState?.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label={languageData?.preview?.explanation_label || "Explanation"}
            value={explanationState}
            onChange={(e) => setExplanationStates(e.target.value)}
            fullWidth
            multiline
            variant="outlined"
            margin="normal"
          />
        </>
      ) : (
        <>
          <Typography variant={isMobile ? "body1" : "h6"} gutterBottom sx={{ fontWeight: "bolder", margin: '10px 0px' }}>
            {renderTextWithBreakLines(title)}
          </Typography>
          {options?.map((option, index) => (
            <Typography key={index} variant="body1" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px'
            }}>
              {`${index + 1}. `}
              {/* <CustomLatex>{option}</CustomLatex> */}
              <div>
                {renderTextWithBreakLines(option)}
              </div>
              {option === correctAnswer && (
                <Check color="success" fontSize="medium" />
              )}
            </Typography>
          ))}
        </>
      )
      }
    </div >
  );
};

export default EditableMultipleQuestion;
