/**
 * @typedef {string} SsoType
 * @readonly
 * @enum {SsoType}
 */
const SSO = {
    types: {
        MoeIL: 'moe_il',
        Ort: 'ort'
    },
    /**
     * @param {SsoType} ssoType
     * @returns {boolean}
     */
    isMoeIL: (ssoType) => ssoType === SSO.types.MoeIL,
    /**
     * @param {SsoType[]} ssoTypes
     * @returns {boolean}
     */
    any: (ssoTypes) => ssoTypes.some(type => type === SSO.types.MoeIL || type === SSO.types.Ort),
    // Here we can add more functions and requests for different SSO types
}
export default SSO;