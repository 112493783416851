import api from "./api";
import { processResponsePromise } from "./utils";

class UserAPI {
    static async getProfile(userId, token) {
        return processResponsePromise(api.get(`/users/${userId}/profile`, { headers: { "X-Token": token } }))
    }

    static async getAllStudents(userId) {
        return processResponsePromise(api.get(`/users/${userId}/students/responses`))
    }

    static async getOrgSSOTypeWithToken(userId, token) {
        if (!userId)
            return { status: 404, data: { message: 'User not found' } }
        return processResponsePromise(api.get(`/users/${userId}/org/sso-type`, { headers: { "X-Token": token } }))
    }

    static async getUserOrgSSOType(userId) {
        return processResponsePromise(api.get(`/users/${userId}/org/sso-type`))
    }
}

export default UserAPI;