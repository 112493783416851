import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import { colors } from '../../utils/customTheme';
import validationGif from '../../assets/validation.gif';
import { useLanguage } from '../../contexts/languageContext';


const CountdownTimer = ({
  duration,
  startTime,
  endTime,
  variant = "default",
  onTimerFinish,
}) => {
  const { languageData, isRTL } = useLanguage();
  const initialTime = useMemo(() => {
    if (duration) return duration;
    const now = roundToSeconds(new Date());
    const start = roundToSeconds(new Date(startTime));
    const end = roundToSeconds(new Date(endTime));

    if (!start || !end) return 0;
    if (now < start) return start.getTime() - now.getTime();
    if (now >= start && now <= end) return end.getTime() - now.getTime();

    return 0;
  }, [duration, startTime, endTime]);

  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const [status, setStatus] = useState('pending'); // 'pending', 'ongoing', 'ended'

  useEffect(() => {
    if (startTime && endTime) {
      const now = roundToSeconds(new Date());
      const start = roundToSeconds(new Date(startTime));
      const end = roundToSeconds(new Date(endTime));
      if (!start || !end) {
        setStatus('error');
      } else if (now < start) {
        setStatus('pending');
      } else if (now >= start && now <= end) {
        setStatus('ongoing');
      } else {
        setStatus('ended');
      }
    } else {
      setStatus(timeRemaining > 0 ? 'ongoing' : 'ended');
    }
  }, [startTime, endTime, timeRemaining]);

  useEffect(() => {
    if (initialTime <= 0) {
      onTimerFinish && onTimerFinish();
      return;
    }
    const timer = setInterval(() => {
      setTimeRemaining(prev => {
        if (prev <= 1000) {
          clearInterval(timer);
          onTimerFinish && onTimerFinish();
          return 0;
        }
        return prev - 1000;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Format time as days, hours, minutes, seconds.
  const formattedTime = useMemo(() => {
    const totalSeconds = Math.floor(timeRemaining / 1000);

    if (variant === "header") {
      // Show hh:mm:ss format with zero-padding
      const rawHours = Math.floor(totalSeconds / 3600);
      const hours = String(rawHours).padStart(2, '0');

      const rawMinutes = Math.floor((totalSeconds % 3600) / 60);
      const minutes = String(rawMinutes).padStart(2, '0');

      const rawSeconds = totalSeconds % 60;
      const seconds = String(rawSeconds).padStart(2, '0');

      return `${hours}:${minutes}:${seconds}`;
    } else {
      const days = Math.floor(totalSeconds / (24 * 3600));
      const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }
  }, [timeRemaining, variant]);

  // Message helper for full-screen variant.
  const getMessage = () => {
    if (startTime && endTime) {
      const now = new Date();
      if (now < new Date(startTime)) return languageData?.quiz?.exam_not_started || 'Exam has not started yet';
      if (now > new Date(endTime)) return languageData?.quiz?.exam_ended || 'Exam has ended';
    }
    return languageData?.quiz?.exam_time_error || 'Unable to determine exam time';
  };

  if (variant === "header") {
    // Minimal header style (no gif or background)
    return (
      <Typography
        variant="subtitle1"
        sx={{
          color: 'black',
          padding: '4px 8px',
          borderRadius: '4px',
          fontSize: '1.5rem'
        }}
      >
        {formattedTime}
      </Typography>
    );
  }

  // Default full-screen variant: Always show background and gif.
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.onboarding,
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        direction: isRTL ? 'rtl' : 'ltr'
      }}
    >
      {(status === 'pending' || status === 'ended' || status === 'error') && (
        <Typography variant="h6" gutterBottom>
          {getMessage()}
        </Typography>
      )}
      {/* Always show the gif for the full-screen timer */}
      <img src={validationGif} alt="validation" width={80} height={80} style={{ marginBottom: '16px' }} />
      {status === 'ongoing' && timeRemaining > 0 ? (
        <Typography variant="h5" sx={{ mt: 2, color: 'green' }}>
          {languageData?.exam_ongoing || 'Exam has started'}: {formattedTime}
        </Typography>
      ) : timeRemaining > 0 ? (
        <Typography variant="h5" sx={{ mt: 2 }}>
          {formattedTime}
        </Typography>
      ) : null}
    </Box>
  );
};

export default CountdownTimer;

function roundToSeconds(date) {
  if (!date || isNaN(date.getTime())) return null;
  date.setMilliseconds(0);
  return date;
}

function parseDate(dateInput) {
  if (!dateInput) return null;
  const parsedDate = new Date(dateInput);
  if (isNaN(parsedDate.getTime())) {
    console.error('Invalid date input:', dateInput);
    return null;
  }
  return parsedDate;
}
