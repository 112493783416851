import React from 'react';
import { Card, CardActionArea, CardContent, Typography, CardMedia } from '@mui/material';

const QuestionTypeCard = ({ questionType, title, subtitle, onSelect, image }) => {
  return (
    <Card sx={{ border: '1px solid #cdcdcd' }}>
      <CardActionArea
        onClick={() => onSelect(questionType)}
        sx={{
          width: 200,
          height: 150,
          border: '2px solid transparent',
          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: '#1E90FF'
          },
          '& .MuiCardActionArea-focusHighlight': {
            backgroundColor: 'transparent',
            opacity: 0
          }
        }}
      >
        {image && (
          <CardMedia
            component="img"
            height="100"
            image={image}
            alt={`${title} image`}
            sx={{ objectFit: 'contain' }}
          />
        )}
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography variant="h7" component="div">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default QuestionTypeCard;
