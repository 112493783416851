import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Box
} from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';

const countdownTime = 7; // Set countdown duration in seconds
const defaultLeavingNotice = `The system detected that you are trying to leave the exam. If you continue, the exam will be auto-submitted. You have ${countdownTime} seconds to return`;

const SupervisionApprove = ({ open, onClose, onAccept, onLeft, leaving = false }) => {
  const { languageData, isRTL } = useLanguage();
  const supervisionTexts = languageData?.quiz?.supervision_approve_dialog;
  const [timeLeft, setTimeLeft] = useState(countdownTime);
  const timerIntervalRef = useRef(null);
  const [checks, setChecks] = useState([false, false, false]);

  useEffect(() => {
    if (leaving && open) 
      setTimeLeft(countdownTime); // Reset the countdown when the dialog opens for leaving
  }, [leaving, open]);

  useEffect(() => {
    if (leaving && open && timeLeft > 0) {
      timerIntervalRef.current = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);

      return () => clearInterval(timerIntervalRef.current);
    } else if (leaving && timeLeft === 0) {
      clearInterval(timerIntervalRef.current);
      onLeft(); // Trigger the onLeft function when countdown reaches zero
    }
  }, [leaving, open, timeLeft, onLeft]);

   const checklistItems = supervisionTexts?.checkboxes || [
    "I understand that if the computer/phone goes into sleep mode, the exam will be automatically submitted - make sure the device does not go into sleep mode.",
    "I understand that if I leave the exam or the browser, the exam will be automatically submitted.",
    "I understand that the system is extra sensitive on mobile devices, so I will pay close attention to details and avoid any accidental interruptions."
  ];

  const allChecked = checks ? checks.every(check => check) : false;

  const handleCheckChange = (index) => {
    const newChecks = [...checks];
    newChecks[index] = !newChecks[index];
    setChecks(newChecks);
  };

  const handleChecklistConfirm = () => {
    onAccept();
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" || reason === "escapeKeyDown") return;
    onClose();
  };

  const handleClick = () => {
    onAccept();
    setTimeLeft(countdownTime); // Reset countdown if user clicks the button manually
  };

  return (
    <Dialog
      key={leaving ? 'leaving' : 'pre'}  // Force remount when leaving changes
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: {direction: isRTL ? 'rtl' : 'ltr' }}}
    >
      <DialogTitle sx={{ color: 'red', textAlign: 'center' }}>
        {(supervisionTexts?.title ?? 'Supervised Exam Notice')}
      </DialogTitle>
      <DialogContent>
        {leaving ? (
          <DialogContentText>
            {supervisionTexts?.leaving_notice ?? defaultLeavingNotice}
          </DialogContentText>
        ) : (
          <>
            <DialogContentText sx ={{ textAlign: 'center', marginBottom: "20px"}}>
              {supervisionTexts?.supervision_content_pre ||
                "Please acknowledge the following before proceeding with the exam:"}
            </DialogContentText>
            <Box sx={{gap: 0.5}}>
              {checklistItems.map((item, index) => (
                <FormControlLabel
                  sx={{margin: "10px", alignItems: "flex-start",  }}              
                  key={index}
                  control={
                    <Checkbox
                      sx ={{top: '-5px'}} 
                      checked={checks[index]}
                      onChange={() => handleCheckChange(index)}
                    />
                  }
                  label={item}
                />
              ))}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={leaving ? handleClick : handleChecklistConfirm}
          variant="contained"
          color={"error"}
          sx={{ minWidth: '120px' }}
          disabled={leaving ? false : !allChecked}
          fullWidth
        >
          {leaving && timeLeft > 0 
            ? `${supervisionTexts?.button ?? "I understand"} (${timeLeft})`
            : `${supervisionTexts?.button ?? "I understand"}`
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SupervisionApprove;
