import { useState } from "react";
import { Fab, IconButton } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "../hooks/useUser";
import ROLES from "../utils/roles";

const WHATSAPP_LINK = "https://wa.me/message/YUQAWJUTOZ2FH1";
const WhatsAppFab = () => {
    const { user } = useUser();
    const [isVisible, setIsVisible] = useState(sessionStorage.getItem("hideWhatsAppFab") !== "true");

    if (!user) return null;
    if (!user?.roles?.includes(ROLES.IL_TEACHER.label) && !user?.org_id) return null;


    const hideFab = () => {
        setIsVisible(false);
        // sessionStorage.setItem("hideWhatsAppFab", "true");
    };

    if (!isVisible) return null;

    return (
        <div style={{ position: "fixed", bottom: 16, right: 16, zIndex: 1000, display: "flex", alignItems: "center", gap: 8 }}>
            <Fab
                sx={{ backgroundColor: "#25D366", color: "white", "&:hover": { backgroundColor: "#25D366" } }}
                href={WHATSAPP_LINK}
                target="_blank"
                rel="noopener noreferrer"
            >
                <WhatsAppIcon />
            </Fab>
            <IconButton onClick={hideFab} color="white" size="small"
                sx={{ position: 'absolute', left: '-4px', top: '-4px', zIndex: 1501, backgroundColor: '#FFFFFFAA' }}
            >
                <CloseIcon sx={{ width: '0.75rem', height: '0.75rem' }} />
            </IconButton>
        </div >
    );
};

export default WhatsAppFab;
