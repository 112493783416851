import React, { useEffect, useMemo, useState } from 'react';
import { Chip, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useDebounce } from '../../hooks/useDebounce';
import { isMobile, renderTextWithBreakLines } from '../../utils';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const EditableOpenQuestion = ({ languageData, questionIndex, title, grade, explanation, indicator, isEditing = false, onChange, gradeError, use_indicator, pointsGrid = 1 }) => {
    const [gradeState, setGradeState] = useState(grade);
    const [titleState, setTitleState] = useState(title);
    const debouncedTitleChange = useDebounce(title => onChange(questionIndex, { title }), 200);

    useEffect(() => {
        setGradeState(grade);
        setTitleState(title);
    }, [grade, title]);

    const handleTitleChange = (newTitle) => {
        setTitleState(newTitle);
        debouncedTitleChange(newTitle);
    }

    const handleExplanationChange = (newExplanation) => {
        onChange(questionIndex, { explanation: newExplanation })
    }

    const handleIndicatorChange = (newIndicator) => {
        const toChange = { indicator: newIndicator }
        if (Array.isArray(newIndicator)) {
            // check if total points is equal to the grade
            const totalPoints = newIndicator.reduce((acc, item) => acc + Number(item.points), 0);
            if (totalPoints !== gradeState) {
                toChange.grade = totalPoints;
                setGradeState(totalPoints);
            }
        }
        onChange(questionIndex, toChange)
    }

    const handleCurrentGradeChange = (event) => {
        // still relevant for explanation type question
        const newGrade = parseFloat(event.target.value) || 0
        setGradeState(newGrade);
        onChange(questionIndex, { grade: newGrade })
    }

    return (
        <div>
            {isEditing ? (
                <>
                    {!use_indicator && <TextField
                        label={languageData.grade_label}
                        onChange={handleCurrentGradeChange}
                        value={gradeState}
                        type='number'
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        error={gradeError}
                    />}
                    <TextField
                        label={languageData.question_title}
                        value={titleState ?? ""}
                        onChange={e => handleTitleChange(e.target.value)}
                        fullWidth
                        multiline
                        variant="outlined"
                        margin="normal"
                        error={!titleState}
                    />
                </>
            ) : (
                <>
                    <Typography variant={isMobile ? "body1" : "h6"} gutterBottom sx={{ fontWeight: "bolder", margin: '10px 0px' }}>
                        {renderTextWithBreakLines(titleState)}
                    </Typography>
                    {/* <Typography variant="body1" gutterBottom>
                        <span style={{ color: 'green', fontWeight: 'bold' }}>
                            {use_indicator ? languageData.indicator_label : languageData.correct_answer}:&nbsp;&nbsp;
                        </span>
                    </Typography> */}
                </>
            )}
            {!use_indicator ?
                <EditableExplanation
                    languageData={languageData}
                    explanation={explanation}
                    isEditing={isEditing}
                    onChange={handleExplanationChange}
                /> :
                <EditableRubric
                    languageData={languageData}
                    indicator={indicator}
                    isEditing={isEditing}
                    onChange={handleIndicatorChange}
                    pointsGrid={pointsGrid}
                />
            }
        </div>
    );
};

export default EditableOpenQuestion;

function EditableExplanation({ languageData, explanation, isEditing = false, onChange }) {
    const [explanationState, setExplanationState] = useState(explanation);
    const debouncedOnChange = useDebounce(onChange, 200);

    useEffect(() => {
        setExplanationState(explanation);
    }, [explanation]);

    const handleExplanationChange = (newExplanation) => {
        setExplanationState(newExplanation);
        debouncedOnChange(newExplanation);
    }

    return isEditing ? (
        <TextField
            label={languageData.explanation_label}
            value={explanationState}
            onChange={e => handleExplanationChange(e.target.value)}
            fullWidth
            multiline
            minRows={4}
            maxRows={10}
            variant="outlined"
            margin="normal"
            error={!explanation}
        />
    ) : renderTextWithBreakLines(explanation)
}

function EditableRubric({ languageData, indicator, isEditing = false, onChange, pointsGrid = 1 }) {
    const [indicatorState, setIndicatorState] = useState(indicator);
    const debouncedOnChange = useDebounce(onChange, 200);

    useEffect(() => {
        setIndicatorState(indicator);
    }, [indicator]);

    if (!indicatorState)
        return null;

    if (typeof indicatorState === 'string') {
        const handleIndicatorStringChange = (newIndicator) => {
            setIndicatorState(newIndicator);
            debouncedOnChange(newIndicator);
        }

        return isEditing ? (
            <TextField
                label={languageData.indicator_label}
                value={indicatorState}
                onChange={e => handleIndicatorStringChange(e.target.value)}
                fullWidth
                multiline
                minRows={4}
                maxRows={10}
                variant="outlined"
                margin="normal"
                error={!indicator}
            />
        ) : renderTextWithBreakLines(indicator)
    }

    const handleIndicatorChange = (e, i) => {
        const newIndicator = [...indicatorState];
        const { name, value } = e.target;
        newIndicator[i] = { ...newIndicator[i], [name]: value }; // create copy
        setIndicatorState(newIndicator);
        debouncedOnChange(newIndicator);
    }

    const handleDeleteConcept = (index) => {
        const newIndicator = [...indicatorState];
        newIndicator.splice(index, 1);
        setIndicatorState(newIndicator);
        onChange(newIndicator);
    }

    const handleAddConcept = () => {
        if (indicator.length >= 5)
            return;
        const newIndicator = [...indicatorState, { points: '', concept: '' }];
        setIndicatorState(newIndicator);
        onChange(newIndicator);
    }

    const showAddBtn = isEditing && indicator.length < 5;

    return (
        <Grid container spacing={2} width="100%" alignItems='center' pt={indicator.length >= 5 ? 2 : 0}>
            {/* Add new concept */}
            {showAddBtn && (
                <Grid item xs={12}>
                    <IconButton onClick={handleAddConcept} sx={{ borderRadius: '100%' }} >
                        <Typography variant="body1" gutterBottom>
                            {languageData?.editable_indicator?.add_concept ?? "+ Add new concept"}
                        </Typography>
                    </IconButton>
                </Grid>
            )}
            {indicatorState?.map((item, index) => (<>
                <Grid item xs={pointsGrid} style={{ ...(isEditing ? { paddingTop: 0 } : {}), minWidth: '80px' }}>
                    {isEditing ? (
                        <TextField
                            label={languageData?.editable_indicator?.points ?? "Points"}
                            name="points"
                            type="number"
                            value={item?.points}
                            onChange={(e) => handleIndicatorChange(e, index)}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                        />
                    ) : (
                        <Chip label={`${item.points} ${languageData.pts_label ?? 'pts'}`} />
                    )}
                </Grid>
                <Grid item xs={11 - pointsGrid} style={{ ...(isEditing ? { paddingTop: 0 } : {}) }}>
                    {/* concept section */}
                    {isEditing ? (
                        <TextField
                            label={languageData?.editable_indicator?.concept ?? "Criterion"}
                            name="concept"
                            value={item?.concept}
                            onChange={(e) => handleIndicatorChange(e, index)}
                            fullWidth
                            multiline
                            maxRows={10}
                            variant="outlined"
                            margin="normal"
                        />
                    ) : (
                        <Typography variant="body1" gutterBottom>
                            {item.concept}
                        </Typography>
                    )
                    }
                </Grid>
                <Grid item xs={0.5}>
                    {/* delete btn */}
                    {isEditing && indicator.length > 1 && (
                        < IconButton onClick={() => handleDeleteConcept(index)} >
                            <RemoveCircleOutlineIcon color="error" />
                        </IconButton>
                    )}
                </Grid >
            </>))
            }
        </Grid >
    )
}