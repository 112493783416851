import React from 'react';
import { Box, Button } from '@mui/material';
import { renderTextWithBreakLines } from '../../utils';

const DisplayMultipleChoice = ({ options, correctAnswers, studentAnswer }) => {
    return (
        <Box>
            {options.map((option, index) => {
                const isCorrect = correctAnswers.includes(option);
                const isStudentAnswer = studentAnswer === option;
                const bgColor = isCorrect ? '#ccffdd' : isStudentAnswer ? '#ffcccc' : 'primary';
                const hoverBgColor = isCorrect ? '#b3ffcc' : isStudentAnswer ? '#ffb3b3' : 'primary';

                return (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1, textAlignLast: 'left' }}>
                        <Button
                            variant="text"
                            sx={{
                                mr: 2,
                                backgroundColor: bgColor,
                                '&:hover': {
                                    backgroundColor: hoverBgColor,
                                },
                            }}
                        >
                            {`${index + 1}. `}&nbsp;&nbsp;&nbsp;
                            <span>{renderTextWithBreakLines(option)}</span>
                        </Button>
                    </Box>
                );
            })}
        </Box>
    );
};

export default DisplayMultipleChoice;
