import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ModifyQuestionPopover from './ModifyQuestionPopover';
import { DeleteImageButton } from './DeleteImageButton';
import { UploadImageButton } from './UploadImageButton';
import { useLanguage } from '../../contexts/languageContext';
import { isMobile } from '../../utils';

const QuestionActions = ({
  isEditing,
  examParams,
  index,
  question,
  handleModifyQuestion,
  handleAddTextToQuestion,
  handleLikeQuestion,
  handleDislikeQuestion,
  handleCancelLikeQuestion,
  handleCancelDislikeQuestion,
  likedQuestions,
  dislikedQuestions,
  handleClickDeleteImage,
  handleUploadImage,
  imageInputId,
}) => {
  const { languageData } = useLanguage();

  return (
    <Box
      sx={{
        position: 'relative',
        top: 0,
        right: isEditing ? 0 : 'auto',
        left: !isEditing ? 0 : 'auto',
        opacity: 0.9,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}>
      {!isEditing && examParams && (
        <ModifyQuestionPopover onSelect={(option) => handleModifyQuestion(index, option)} />
      )}
      { isEditing && (<Tooltip title={languageData?.preview?.tooltips?.add_question_text ?? "Add text to question"} arrow>
            <IconButton onClick={() => handleAddTextToQuestion(question._id, languageData?.preview?.question_text ?? "Type your text for the question here..")}>
              <TextFieldsIcon />
          </IconButton>
      </Tooltip>)}
      {!isEditing && !isMobile && (
        <>
          {question?.image?.url ? (
            <DeleteImageButton questionId={question._id} handleClickDeleteImage={handleClickDeleteImage} />
          ) : (
            <UploadImageButton questionId={question?._id} imageInputId={imageInputId} handleUploadImage={handleUploadImage} />
          )}
          <Tooltip title="Like" arrow>
            <IconButton onClick={() => {
              if (likedQuestions.has(question._id)) {
                handleCancelLikeQuestion(question._id);
                dislikedQuestions.delete(question._id);
              } else {
                handleLikeQuestion(question._id);
              }
            }}>
              <ThumbUpIcon color={likedQuestions.has(question._id) ? 'success' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Dislike" arrow>
            <IconButton onClick={() => {
              if (dislikedQuestions.has(question._id)) {
                handleCancelDislikeQuestion(question._id);
                likedQuestions.delete(question._id);
              } else {
                handleDislikeQuestion(question._id);
              }
            }}>
              <ThumbDownIcon color={dislikedQuestions.has(question._id) ? 'error' : 'inherit'} />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

export default QuestionActions;
