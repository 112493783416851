import { useEffect, useState } from 'react';
import uploadSvg from './upload.svg';
import fileSvg from './file.svg';
import { Box, Container, FormGroup, List, ListItem, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { isValidFileType } from '../../utils/fileHelpers';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ConvertPptDialog from '../ConvertPptDialog';
import { containerStyle, uploadFormStyle, fileStyle, fileDetailsStyle } from './styles';

const allowedFileExtensions = ['.pdf', '.ppt', '.pptx', '.txt', '.vtt', '.docx'];

function UploadFiles({ children, files, onUpload, onRemove, maxFiles: maxFilesProp, isPremiumUser = false, disabled = false, onClearFiles = null }) {
    const [isDisabled, setIsDisabled] = useState(disabled);
    const [isAmountError, setIsAmountError] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [isDragging, setIsDragging] = useState(false); // State to manage dragging
    const maxFiles = maxFilesProp ?? (isPremiumUser ? 3 : 1); // Use the given max files, otherwise based on user premium status

    useEffect(() => {
        setIsDisabled(files?.length >= maxFiles);
    }, [files?.length]);

    useEffect(() => { setIsDisabled(disabled) }, [disabled]);

    const handleChangeFiles = (newFiles) => {
        // Check if exceeding the limit
        if (newFiles.length > maxFiles - files?.length) {
            setIsAmountError(true);
            setTimeout(() => setIsAmountError(false), 3000);
            return; // Prevent further processing if exceeding limit
        }

        const formattedNewFiles = [];
        for (const file of newFiles) {
            if (file.name.toLowerCase().endsWith('.ppt'))
                setOpenDialog(true);

            formattedNewFiles.push({
                name: file.name,
                sizeText: formatFileSize(file.size),
                file: file,
                isValidType: isValidFileType(file),
                alertMsg: ''
            });
        }
        onUpload(formattedNewFiles);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);

        const droppedFiles = Array.from(event.dataTransfer.files);
        handleChangeFiles(droppedFiles);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
    };

    const handleRemoveFile = (index, fileName) => {
        onRemove(index);
        removeFileFromElement(fileName);
    };

    const removeFileFromElement = (fileName) => {
        const dataTransfer = new DataTransfer();
        const inputElement = document.getElementById('file-input');

        Array.from(inputElement.files).forEach((file) => {
            if (file.name !== fileName) {
                dataTransfer.items.add(file);
            }
        });

        inputElement.files = dataTransfer.files;
    };

    // Expose the clearFiles function via props
    useEffect(() => {
        const clearInput = () => {
            const inputElement = document.getElementById('file-input');
            inputElement.files = new DataTransfer().files; // Clear the files from
        };

        if (onClearFiles) {
            onClearFiles(clearInput);
        }
    }, [onClearFiles]);

    return (
        <Container sx={containerStyle}>
            <input
                id="file-input"
                type="file"
                name="file"
                hidden
                multiple
                accept={allowedFileExtensions.join(', ')}
                onChange={(event) => handleChangeFiles(event.target.files)}
                disabled={isDisabled}
            />
            <label htmlFor="file-input">
                <FormGroup
                    sx={uploadFormStyle(isDisabled)}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <img src={uploadSvg} alt="Upload" height={100} />
                    <Typography variant="body1" sx={{ color: '#555' }}>Browse files to upload</Typography>
                    <Typography variant="caption" sx={{ color: '#555', fontWeight: '700' }}>
                        Up to {maxFiles} file{maxFiles > 1 ? 's' : ''} allowed
                    </Typography>
                    {isDragging && (
                        <Box
                            sx={{
                                position: 'fixed',
                                width: '-webkit-fill-available',
                                height: '-webkit-fill-available',
                                top: '10px',
                                bottom: '10px',
                                backgroundColor: 'rgba(0, 123, 255, 0.1)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 1,
                                border: '2px dashed #007bff',
                                pointerEvents: 'none',
                            }}
                        >
                            <Typography variant="h6" color="primary">Drop your file here</Typography>
                        </Box>
                    )}
                </FormGroup>
                {children}
                {isAmountError && (
                    <Typography variant="body2" sx={{ color: '#e33', fontWeight: '600' }}>
                        You can upload up to {maxFiles} file{maxFiles > 1 ? 's' : ''}!
                    </Typography>
                )}
            </label>
            <List>
                {files?.map((file, index) => (
                    <ListItem key={index}>
                        <Box sx={fileStyle(file.isValidType)}>
                            <Box sx={fileDetailsStyle}>
                                <img src={fileSvg} alt="File" height={25} />
                                <Box>
                                    <Typography variant="body2">{file.name}</Typography>
                                    <Typography variant="caption">{file?.sizeText ?? ''}</Typography>
                                </Box>
                            </Box>
                            <ClearIcon
                                sx={{ cursor: 'pointer', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'rotate(90deg)' } }}
                                onClick={() => handleRemoveFile(index, file.name)}
                            />
                        </Box>
                    </ListItem>
                ))}
            </List>
            <ConvertPptDialog open={openDialog} handleClose={() => setOpenDialog(false)} />
        </Container>
    );
}

export default UploadFiles;

function formatFileSize(bytes) {
    if (bytes < 1024) return `${bytes} B`;
    if (bytes < 1048576) return `${(bytes / 1024).toFixed(1)} KB`;
    return `${(bytes / 1048576).toFixed(1)} MB`;
}
