// ReviewHeader.js

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import SettingsButton from '../../components/Settings/SettingsButton';
import SubmitAndShareButton from './SubmitAndShare';
import { useLanguage } from '../../contexts/languageContext';
import ChooseLanguageBtn from '../../components/ChooseLanguageBtn';
import VisibilityIcon from '@mui/icons-material/Visibility';


const ReviewHeader = ({ handleShareLink, handleGoToPreview, handleSaveSettingsOrParams, examJson }) => {
    const { languageData } = useLanguage();
    const saveParams = (newParams) => handleSaveSettingsOrParams(newParams, 'parameters')
    const saveSettings = (newSettings) => handleSaveSettingsOrParams(newSettings, 'settings')

    return (
        <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: 0 }}>
            <Toolbar sx={{ justifyContent: 'flex-end' }}>
                <Button
                    variant="outlined"
                    onClick={handleGoToPreview}
                    endIcon={<VisibilityIcon />}
                >
                    {languageData?.preview_btn_label ?? ''}
                </Button>
                <SettingsButton
                    button
                    onSubmit={saveSettings}
                    onParamsChange={saveParams}
                    examSettings={examJson?.settings}
                    examParams={examJson?.parameters}
                />
                <SubmitAndShareButton
                    handleSaveSettings={saveSettings}
                    examJson={examJson}
                />
                <ChooseLanguageBtn />
            </Toolbar>
        </AppBar>
    );
};

export default ReviewHeader;
