import { questionsType } from "./examOptions"

export function SwapIndicators(use_indicator, questions) {
    if (use_indicator) {
        // move explanation to indicator because use_indicator=true is the new value
        questions = questions.map(q => {
            if (q.type === questionsType.nested) {
                q.nested_questions = SwapIndicators(use_indicator, q.nested_questions)
            }
            if (q.explanation) {
                q.indicator = [{
                    points: q.grade,
                    concept: q.explanation
                }]
            }
            return q
        })
    } else {
        // move indicator to explanation because use_indicator=false is the new value
        questions = questions.map(q => {
            if (q.type === questionsType.nested) {
                q.nested_questions = SwapIndicators(use_indicator, q.nested_questions)
            }
            if (q.indicator) {
                if (Array.isArray(q.indicator) && q.indicator.length) {
                    let explanation = ""
                    let totalPercents = 0
                    q.indicator.forEach((ind, i) => {
                        let percent = 0;
                        if (q.grade !== 0) {
                            percent = Math.floor((ind.points / q.grade) * 100);
                        } else {
                            percent = 100 / q.indicator.length //fallback if grade id 0.
                        }

                        if (i === q.indicator.length - 1) {
                            percent = 100 - totalPercents;
                        }

                        totalPercents += percent;
                        explanation += `${percent}%: ${ind.concept}\n`;
                    });
                    q.explanation = explanation
                } else {
                    q.explanation = q.indicator + ''
                }
            }
            return q
        })
    }
    return questions;
}