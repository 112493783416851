import React, { useState } from 'react';
import { IconButton, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsDrawer from './SettingsDrawer';
import { useLanguage } from '../../contexts/languageContext';

const SettingsButton = ({ onSubmit, examSettings, examParams, onParamsChange, icon = false }) => {
  const [open, setOpen] = useState(false);
  const { languageData } = useLanguage()

  const handleOpenSettings = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {!icon ? (
        <Button endIcon={<SettingsIcon />} sx={{ mx: 1 }} variant="outlined" onClick={handleOpenSettings}>
          {languageData?.settings_dialog?.title}
        </Button>
      ) : (
        <IconButton onClick={handleOpenSettings}>
          <SettingsIcon />
        </IconButton>
      )}
      <SettingsDrawer
        open={open}
        onClose={handleClose}
        onSubmit={onSubmit}
        examSettings={examSettings}
        examParams={examParams}
        onParamsChange={onParamsChange}
      />
    </div>
  );
};

export default SettingsButton;
