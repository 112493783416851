import React, { useEffect, useState, useMemo } from 'react';
import { Typography, Card, CardContent, Button, TextField, Grid, LinearProgress, Checkbox } from '@mui/material';
import { getUserGoodQuestions, updateGoodQuestion, deleteGoodQuestion } from '../../requests/goodQuestions';
import { useNavigate } from 'react-router-dom';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import ApproveModal from '../../components/ApproveModal';
import { useUser } from '../../hooks/useUser';

const QUESTION_LIMIT = 20;

const PastQuestions = ({ openSnackBar, languageData }) => {
    const { isPremium } = useUser();
    const [questions, setQuestions] = useState([]);
    const [editingQuestionId, setEditingQuestionId] = useState(null);
    const [editedText, setEditedText] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [questionToDelete, setQuestionToDelete] = useState(null);
    const [selectedQuestions, setSelectedQuestions] = useState(new Set());
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const fetchedQuestions = await getUserGoodQuestions(QUESTION_LIMIT);
                setQuestions(fetchedQuestions);
            } catch (error) {
                openSnackBar(SnackbarTypes.FETCH_FAILED.field);
                console.error('Error fetching questions:', error);
            }
        };

        fetchQuestions();
    }, [openSnackBar]);

    const handleEdit = async (questionId, updatedQuestion) => {
        try {
            await updateGoodQuestion(questionId, updatedQuestion);
            setQuestions(questions.map(q => q.doc_id === questionId ? { ...q, text: updatedQuestion } : q));
            setEditingQuestionId(null);
            openSnackBar(SnackbarTypes.SAVED_FOR_LATER_SUCCESS.field);
        } catch (error) {
            console.error('Error updating question:', error);
            openSnackBar(SnackbarTypes.SAVED_FOR_LATER_SUCCESS.field);
        }
    };

    const handleCancelEdit = () => {
        setEditingQuestionId(null);
        setEditedText('');
    };

    const handleDelete = async (questionId) => {
        try {
            await deleteGoodQuestion(questionId);
            setQuestions(questions.filter(q => q.doc_id !== questionId));
            setOpenModal(false);
            openSnackBar(SnackbarTypes.EXAM_DELETED.field);
        } catch (error) {
            console.error('Error deleting question:', error);
            openSnackBar(SnackbarTypes.ERROR.field);
        }
    };

    const moveToPastExams = () => {
        navigate('upload');
    };

    const openDeleteModal = (questionId) => {
        setQuestionToDelete(questionId);
        setOpenModal(true);
    };

    const handleBulkDelete = async () => {
        try {
            // Delete all selected questions
            await Promise.all(Array.from(selectedQuestions).map(id => deleteGoodQuestion(id)));
            setQuestions(questions.filter(q => !selectedQuestions.has(q.doc_id)));
            setSelectedQuestions(new Set());
            setOpenModal(false);
            openSnackBar(SnackbarTypes.EXAM_DELETED.field);
        } catch (error) {
            console.error('Error deleting questions:', error);
            openSnackBar(SnackbarTypes.ERROR.field);
        }
    };

    const toggleQuestionSelection = (questionId) => {
        const newSelected = new Set(selectedQuestions);
        if (newSelected.has(questionId)) {
            newSelected.delete(questionId);
        } else {
            newSelected.add(questionId);
        }
        setSelectedQuestions(newSelected);
    };

    const progress = useMemo(() => (questions.length / QUESTION_LIMIT) * 100, [questions.length]);

    return (
        <>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center', margin: '16px 0' }}>
                <Typography variant="subtitle1" gutterBottom>
                    {languageData?.personalization?.subtitle || 'Questions the model will utilize during exam creation to deliver more personalized results'}
                </Typography>
                {questions.length < QUESTION_LIMIT && (
                    <Button variant="contained" color="primary" onClick={moveToPastExams} disabled={!isPremium()}>
                        {languageData?.personalization?.upload_past_questions || 'Upload questions from past exams'}
                    </Button>
                )}
                {selectedQuestions.size > 0 && (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setQuestionToDelete(Array.from(selectedQuestions));
                            setOpenModal(true);
                        }}
                    >
                        {`Delete Selected (${selectedQuestions.size})`}
                    </Button>
                )}
                <Typography
                    variant="body1"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    {questions.length} / {QUESTION_LIMIT}
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ height: 10, borderRadius: 5, width: '100%' }}
                />
            </div>

            <Grid container spacing={3}>
                {questions.slice(0, QUESTION_LIMIT).map((question) => (
                    <Grid item xs={12} sm={6} md={4} key={question.doc_id}>
                        <Card
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                border: selectedQuestions.has(question.doc_id) ? '2px solid #1976d2' : 'none'
                            }}
                        >
                            <CardContent style={{ flexGrow: 1 }}>
                                {editingQuestionId === question.doc_id ? (
                                    <div>
                                        <TextField
                                            fullWidth
                                            defaultValue={question.text}
                                            onChange={(e) => setEditedText(e.target.value)}
                                            multiline
                                            minRows={2}
                                            maxRows={6}
                                        />
                                        <div style={{ marginTop: '10px' }}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEdit(question.doc_id, editedText);
                                                }}
                                                sx={{ mr: 1 }}
                                                disabled={editedText.length < 1}
                                            >
                                                {languageData?.personalization?.save || 'Save'}
                                            </Button>
                                            <Button
                                                variant="text"
                                                color="error"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCancelEdit();
                                                }}
                                            >
                                                {languageData?.personalization?.cancel || 'Cancel'}
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ padding: 6 }}>
                                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                            <Checkbox
                                                checked={selectedQuestions.has(question.doc_id)}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    toggleQuestionSelection(question.doc_id);
                                                }}
                                                sx={{ padding: '0 8px 0 0' }}
                                            />
                                            <Typography variant="body1">{question.text}</Typography>
                                        </div>
                                        <div style={{ marginTop: '10px' }}>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setEditingQuestionId(question.doc_id);
                                                }}
                                                sx={{ mr: 1 }}
                                            >
                                                {languageData?.personalization?.edit || 'Edit'}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openDeleteModal(question.doc_id);
                                                }}
                                            >
                                                {languageData?.personalization?.delete || 'Delete'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <ApproveModal
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setQuestionToDelete(null);
                }}
                onConfirm={() => Array.isArray(questionToDelete) ? handleBulkDelete() : handleDelete(questionToDelete)}
                title="Confirm Deletion"
                subtitle={Array.isArray(questionToDelete)
                    ? `Are you sure you want to delete ${questionToDelete.length} questions? This action is irreversible.`
                    : "Are you sure you want to delete this question? This action is irreversible."}
                buttonLabel="Delete"
            />
        </>
    );
};

export default PastQuestions; 