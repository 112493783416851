import api from "./api"
import { logErrorToServer } from "./error"

async function readPPT(file) {
  const formData = new FormData()
  formData.append('pptFile', file)

  try {
    const response = await api.post('/exports/pptx/read', formData)
    return response
  } catch (error) {
    logErrorToServer(`error reading ppt: ${error}`)
    return null
  }
}

export default readPPT
