import { GridToolbarContainer, GridToolbar } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import { Button, Tooltip } from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { isMobile } from "../../utils";

function ToolbarWithExcelExport({ rows, columns, filename = "DataGrid Export" }) {
    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(
            rows.map(row => {
                const rowData = {};
                columns.forEach(col => {
                    rowData[col.headerName] = row[col.field];
                });
                return rowData;
            })
        );

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Grades");
        XLSX.writeFile(wb, `${filename.replace(" ", "_")}.xlsx`);
    };

    return (
        <GridToolbarContainer >
            <GridToolbar />
            {!isMobile && <Tooltip arrow placement="bottom" title="Download as Excel">
                <Button
                    variant="text"
                    onClick={exportToExcel}
                    sx={{
                        fontSize: "0.8125rem",
                        padding: '4px 5px',
                        color: 'green'
                    }}
                    startIcon={<FileDownloadOutlinedIcon />}
                >
                    Excel
                </Button>
            </Tooltip>}
        </GridToolbarContainer>
    );

}

export default ToolbarWithExcelExport;