import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import ROLES from '../utils/roles';
import { capitalizeFirstLetter } from '../utils';

const maxCharsLimit = 100000

const TextRangeDialog = ({ open, onClose, pages, onSubmit, length, userRoles }) => {
  const FILE_TEXT_LIMIT = Math.max(ROLES.getMaxLimit(userRoles), maxCharsLimit);

  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(pages.length);
  const [selectedLength, setSelectedLength] = useState(0);

  useEffect(() => {
    if (open) {
      setStartPage(1);
      setEndPage(pages.length);
    }
  }, [open, pages]);

  // Recalculate the number of characters for the selected page range on every change
  useEffect(() => {
    const numPagesSelected = endPage - startPage + 1;
    // Calculate average characters per page using the total length prop
    const averageCharsPerPage = length / pages.length;
    const computedLength = numPagesSelected * averageCharsPerPage;
    setSelectedLength(computedLength);
  }, [startPage, endPage, length, pages]);

  const handleSubmit = () => {
    if (startPage <= endPage && endPage <= pages.length && selectedLength < FILE_TEXT_LIMIT) {
      onSubmit(startPage, endPage);
    }
  };

  const getSnippet = (page) => {
    if (!page) return "";
    const words = page.trim().split(/\s+/).slice(0, 8).join(" ");
    return words.replace(/[.,!?]+$/, "") + "...";
  };


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Page Range</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="start-page-label">Start Page</InputLabel>
          <Select
            labelId="start-page-label"
            value={startPage}
            label="Start Page"
            onChange={(e) => setStartPage(e.target.value)}
          >
            {pages.map((page, index) => (
              <MenuItem key={index} value={index + 1}>
                {index + 1} - {capitalizeFirstLetter(getSnippet(page).toLowerCase())}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="end-page-label">End Page</InputLabel>
          <Select
            labelId="end-page-label"
            value={endPage}
            label="End Page"
            onChange={(e) => setEndPage(e.target.value)}
          >
            {pages.map((page, index) => (
              <MenuItem key={index} value={index + 1}>
                {index + 1} - {capitalizeFirstLetter(getSnippet(page).toLowerCase())}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography
          variant="body2"
          style={{
            marginTop: '8px',
            color: selectedLength >= FILE_TEXT_LIMIT ? 'red' : 'inherit'
          }}
        >
          {`Characters: ${selectedLength.toFixed(0)} / ${FILE_TEXT_LIMIT}`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={selectedLength >= FILE_TEXT_LIMIT}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TextRangeDialog;
