import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useSchoolRegistrationDialog } from "./useSchoolRegistrationDialog";
import { buttonsIds } from "../../../requests/analytics";


export default function SchoolRegistrationDialogHe() {
    const { open, setOpen, handleRegisterClick } = useSchoolRegistrationDialog({
        showKey: buttonsIds.showSchoolRegisterationDialogHebKey,
        clickKey: buttonsIds.clickSchoolRegisterationDialogHebKey,
    });

    return (
        <Dialog open={open} onClose={() => setOpen(false)} dir='rtl'>
            <DialogTitle>הצטרפו עם בית הספר שלכם!</DialogTitle>
            <DialogContent>
                <p>
                    היי 👋<br />
                    אנחנו רוצים להזמין אותך לרשום את בית הספר שלך ולקבל הצעה מיוחדת.<br />
                    הצטרפו לפלטפורמה המובילה ללמידה חכמה וחדשנית ✨
                </p>
                <p style={{ marginTop: '16px' }}>אל תפספסו – בואו נראה איך זה יכול לעבוד בשבילכם!</p>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <img src="https://res.cloudinary.com/studywise/image/upload/v1739632904/approved-gefen_ovv4m9.jpg" alt="Gefen" style={{ width: '75px', height: '75px', objectFit: 'contain' }} />
                <Button onClick={() => handleRegisterClick("https://forms.gle/QKvrddLTZSiomchX7")} variant="contained" color="primary">
                    לרישום בית הספר 🚀
                </Button>
            </DialogActions>
        </Dialog>
    );
}
