import React, { useCallback, useMemo } from 'react';
import { Tooltip, AppBar, Toolbar, IconButton, Button } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import ShareIcon from '@mui/icons-material/Share';
import SettingsButton from '../../components/Settings/SettingsButton';
import ShareButton from '../../components/Share/ShareButton';
import { useLanguage } from '../../contexts/languageContext';
import ChooseLanguageBtn from '../../components/ChooseLanguageBtn';
import HighlightButton from '../../components/HighlightButton/HighlightButton';
import { useLobby } from '../../contexts/LobbyContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useUser } from '../../hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { isMobile } from '../../utils';
import { inputTypes } from '../../utils/configs';
import { handleRemix } from '../../utils/remixFunctions';

const PreviewHeader = ({ examId, handleSaveSettings, handleSaveParams, examSettings, examParams }) => {
    const { isRTL, languageData } = useLanguage();
    const { lobbyStatus } = useLobby();
    const { isFromLMS } = useUser();
    const navigate = useNavigate();

    const handleViewExam = () => {
        window.open(`/quiz?id=${examId}`, '_blank');
    };

    const viewExamButton = useCallback(() => {
        const title = (lobbyStatus?.auto_grade == false
            ? languageData?.preview?.tooltips?.start_auto_grade
            : languageData?.preview?.tooltips?.view_exam) ?? "Preview your exam";

        const ViewExamButton = () => (
            <Tooltip title={title} placement="bottom">
                <Button
                    endIcon={<VisibilityIcon />}
                    sx={{ mr: 1, minWidth: 'auto' }}
                    variant={isMobile ? "text" : "contained"}
                    color="primary"
                    onClick={handleViewExam}
                >
                    {!isMobile && title}
                </Button>
            </Tooltip>
        )

        return lobbyStatus?.auto_grade == false ? (
            <HighlightButton>
                <ViewExamButton />
            </HighlightButton>
        ) : (
            <ViewExamButton />
        );
    }, [lobbyStatus, languageData]);

    const showRemixButton = useMemo(() => !isMobile && examParams && examParams?.source !== inputTypes.blank, [examParams])
    return (
        <AppBar elevation={1} sx={{ bgcolor: 'white' }} position='sticky' >
            <Toolbar sx={{ justifyContent: showRemixButton ? 'space-between' : 'flex-end' }}>
                {/* Left Side: New Button */}
                {showRemixButton && (
                    <Tooltip title={languageData?.preview?.reuse_btn?.tooltip} placement="bottom" arrow slotProps={{ popper: { sx: { textAlign: isRTL ? 'right' : 'left' } } }}>
                        <Button
                            sx={{ ml: 1 }}
                            variant="text"
                            color="primary"
                            onClick={() => handleRemix(examParams, navigate)}
                            endIcon={<SwitchAccessShortcutIcon sx={{ transform: 'scaleX(-1)' }} />}
                        >
                            {languageData?.preview?.reuse_btn?.label ?? "Reuse Exam"}
                        </Button>
                    </Tooltip>
                )}

                {/* Right Side: Existing Elements */}
                <div style={{ display: 'flex', alignItems: 'center', gap: isMobile ? '4px' : '0px' }}>
                    <SettingsButton
                        icon={isMobile}
                        onSubmit={handleSaveSettings}
                        examSettings={examSettings}
                        onParamsChange={handleSaveParams}
                        examParams={examParams}
                    />
                    {viewExamButton()}
                    <Tooltip title={languageData.preview.tooltips.share_exam} placement="bottom">
                        <span>
                            <ShareButton
                                icon={<ShareIcon />}
                                iconOnly={isMobile}
                                isRTL={isRTL}
                                languageData={languageData?.preview}
                                examId={examId}
                                fromLMS={isFromLMS}
                            />
                        </span>
                    </Tooltip>
                    <ChooseLanguageBtn />
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default PreviewHeader;
