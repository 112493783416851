import React, { createContext, useContext, useState, useEffect, useRef, useMemo } from 'react';
import { languages } from '../utils/examOptions';

const LanguageContext = createContext();

export function useLanguage() {
  return useContext(LanguageContext)
}

export const languageCodes = {
  english: 'en',
  hebrew: 'he',
  arabic: 'ar',
}
export const languageToCodesMap = Object.keys(languages).reduce((acc, key) => {
  acc[languages[key]] = languageCodes[key.toLowerCase()];
  return acc;
}, {});

const LanguageProvider = ({ children }) => {
  const languageCode = useRef(languageCodes.english);
  const [languageData, setLanguageData] = useState({});
  const semiticLanguages = [languageCodes.hebrew, languageCodes.arabic];
  const isRTL = languageCode && semiticLanguages.includes(languageCode.current);
  const sessionKey = 'languageData';
  const isHeb = useMemo(() => languageCode.current === languageCodes.hebrew, [languageCode.current]);

  useEffect(() => {
    const storedPreferredLanguage = getPreferredLanguage();
    const hasSessionData = !!sessionStorage.getItem(sessionKey);

    if (storedPreferredLanguage) {
      languageCode.current = storedPreferredLanguage;
    }

    if (storedPreferredLanguage || !hasSessionData) {
      preFetchLanguageData();
    } else {
      useExistingLanguageData();
    }
  }, []);

  function ToggleLanguage(newLangCode = null) {
    if (newLangCode && Object.values(languageCodes).includes(newLangCode) && newLangCode !== languageCode.current) {
      languageCode.current = newLangCode;
      preFetchLanguageData();
    }
  }
  function getPreferredLanguage() {
    return localStorage.getItem('preferredLanguage');
  }

  async function preFetchLanguageData() {
    const storedLanguageData = sessionStorage.getItem(sessionKey);
    if (storedLanguageData) {
      const parsedData = JSON.parse(storedLanguageData);
      const parsedLanguageData = parsedData[languageCode.current];
      if (parsedLanguageData) {
        setLanguageData(parsedLanguageData);
        return parsedLanguageData;
      }
    }
    const responseLanguageData = await fetchLanguageData();
    if (responseLanguageData) {
      // Store languageData in sessionStorage
      const newLanguageDataToStore = { [languageCode.current]: responseLanguageData };
      const languageToStore = storedLanguageData ? { ...JSON.parse(storedLanguageData), ...newLanguageDataToStore } : newLanguageDataToStore;
      sessionStorage.setItem(sessionKey, JSON.stringify(languageToStore));
      setLanguageData(responseLanguageData);
      return languageToStore
    }
  }

  async function fetchLanguageData() {
    try {
      const response = await fetch(`/languages/${languageCode.current}.json`);
      if (!response.ok) throw new Error('Failed to load language file');

      return await response.json();
    } catch (error) {
      console.error('Error loading language:', error);
      return null; // Return empty object if loading fails
    }
  };

  function useExistingLanguageData() {
    const storedLanguageData = sessionStorage.getItem(sessionKey);
    if (storedLanguageData) {
      const parsedData = JSON.parse(storedLanguageData);
      const firstLanguageKey = Object.keys(parsedData)[0];
      const firstLanguageData = parsedData[firstLanguageKey];
      if (firstLanguageData) {
        setLanguageData(firstLanguageData);
        return firstLanguageData;
      }
    }
    return null;
  }

  function changeToHebrew() {
    if (languageCode.current !== languageCodes.hebrew)
      ToggleLanguage(languageCodes.hebrew);
  }

  function changeToArabic() {
    if (languageCode.current !== languageCodes.arabic)
      ToggleLanguage(languageCodes.arabic);
  }

  function savePreferredLanguage(lang) {
    localStorage.setItem('preferredLanguage', lang);
  }

  return (
    <LanguageContext.Provider value={{
      language: languageCode.current,
      ToggleLanguage,
      languageData,
      isRTL,
      changeToHebrew,
      languageCodes,
      changeToArabic,
      savePreferredLanguage,
      getPreferredLanguage,
      preFetchLanguageData,
      isHeb
    }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
