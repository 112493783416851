import { useState, useMemo } from "react";
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { Toolbar, Typography, Button, CircularProgress, Paper } from "@mui/material";
import { capitalizeFirstLetter, renderTextWithBreakLines } from "../../utils";

function DashboardTable({ items, tableName, fieldsToDisplay, orderByField, sortableFields, CustomPopover, popoverProps, allowPopover, refetchData = null }) {
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [focusedItem, setFocusedItem] = useState(null);

    if (!fieldsToDisplay && items?.length) {
        fieldsToDisplay = Object.keys(items[0]);
    }

    const columns = useMemo(() => fieldsToDisplay.map(field => ({
        field,
        headerName: capitalizeFirstLetter(field),
        flex: ['message', 'trace'].includes(field) ? 2 : 1,
        sortable: sortableFields?.includes(field),
        minWidth: ['message', 'trace'].includes(field) ? 500 : field === "email" ? 300 : 120,
        renderCell: (params) => {
            if (field === 'roles') {
                return (params.value?.join(', ') || params.row.role)
            } else {
                renderTextWithBreakLines(params.value?.toString())
            }
        },
    })), [fieldsToDisplay, sortableFields]);

    const handleRefetch = () => {
        if (!refetchData) return;
        setLoading(true);
        refetchData().finally(() => setLoading(false));
    };

    const handleRowClick = (params, event) => {
        if (allowPopover) {
            setFocusedItem(params.row);
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClosePopOver = () => {
        setFocusedItem(null);
        setAnchorEl(null);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
            <Toolbar>
                <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div">
                    {capitalizeFirstLetter(tableName)} ({items.length})
                </Typography>
                <Button onClick={handleRefetch} variant="contained" color="primary" disabled={!refetchData || loading}>
                    {loading ? <CircularProgress size={24} /> : "Refetch"}
                </Button>
            </Toolbar>
            <DataGrid
                rows={items}
                columns={columns}
                pagination
                getRowHeight={() => 'auto'}
                pageSizeOptions={[10, 25, 50, 100]}
                initialState={{
                    pagination: { paginationModel: { pageSize: 100 } },
                    sorting: {
                        sortModel: [
                            {
                                field: orderByField,
                                sort: 'desc',
                            },
                        ],
                    },
                }}
                disableSelectionOnClick
                onRowClick={handleRowClick}
                getRowId={row => row._id}
                getRowClassName={(params) => {
                    if (params.row?.environment === 'development') {
                        return 'development-row'
                    }
                    return ''
                }}
                sx={{
                    maxHeight: 'none', // Fixed height
                    display: 'flex',
                    flexDirection: 'column',
                    '& .development-row': {
                        backgroundColor: 'rgba(255, 255, 0, 0.2)',
                    },
                    '& .MuiDataGrid-cell': {
                        lineHeight: 'normal', // Ensure multi-line text works properly
                    },
                    '& .MuiDataGrid-row ': {
                        marginBottom: '8px', // Adds spacing between rows
                        maxHeight: 'none !important'
                    },
                    '& .MuiDataGrid-cell': {
                        height: 'auto',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        scrollbarWidth: 'thin',
                        maxHeight: '70vh'
                    },
                    '& .MuiTablePagination-selectLabel, .MuiTablePagination-input': {
                        display: 'inline-flex !important',
                    },
                }}
            />
            {focusedItem && (
                <CustomPopover
                    anchorEl={anchorEl}
                    handleClosePopOver={handleClosePopOver}
                    item={focusedItem}
                    {...popoverProps}
                />
            )}
        </Paper>
    );
}

DashboardTable.propTypes = {
    items: PropTypes.array.isRequired,
    tableName: PropTypes.string.isRequired,
    fieldsToDisplay: PropTypes.array,
    orderByField: PropTypes.string,
    sortableFields: PropTypes.array,
    CustomPopover: PropTypes.elementType,
    popoverProps: PropTypes.object,
    allowPopover: PropTypes.bool,
    refetchData: PropTypes.func,
};

export default DashboardTable;
