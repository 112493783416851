import React from 'react';
import {
    Drawer, Typography, List, ListItem, ListItemText, Box, Divider, Button, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AssistantIcon from '@mui/icons-material/Assistant';
import html2pdf from 'html2pdf.js';
import { isMobile } from '../../utils';
import { loaderTypes, useLoader } from '../../hooks/useLoader';
import { defaultParams } from '../../utils/examOptions';
import { generateAdaptiveExam } from '../../requests/generateExam';
import { useUser } from '../../hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../utils/customTheme';
import { AutoGraph, PictureAsPdf } from '@mui/icons-material';
import { logErrorToServer } from '../../requests/error';
import { useLanguage } from '../../contexts/languageContext';

const PerformanceReport = ({ reportData, isRTL, isOpen, onClose, examParams }) => {
    const { openLoader, closeLoader } = useLoader();
    const { user, decrementExamsLeft } = useUser();
    const { languageData } = useLanguage();
    const reportLanguageData = languageData?.performance_report
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = React.useState(false);

    if (!reportData) return null; // Handle empty report data

    const displayAdaptiveLearning = examParams && examParams?.prompt && examParams?.language && examParams?.difficulty;
    const discListItemStyle = {
        listStyleType: 'disc',
        display: 'list-item',
        ...(isRTL ? { mr: 4, pr: 1, textAlign: 'right' } : { ml: 4, pl: 1, textAlign: 'left' }),
    };

    const iconListItemStyle = { gap: '10px', textAlign: isRTL ? 'right' : 'left' };
    const textPadding = { ...(isRTL ? { pr: 3 } : { pl: 3 }) };

    // Function to handle PDF download
    const handleDownloadPDF = () => {
        const element = document.getElementById('report-content');

        // Ensure the content is loaded properly before generating PDF
        if (element) {
            const clonedElement = element.cloneNode(true);

            // Remove any element that should not appear in PDF (like buttons)
            clonedElement.querySelector('.no-pdf')?.remove();

            const opt = {
                margin: 1,
                filename: 'performance_report.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            };

            html2pdf().set(opt).from(clonedElement).save();
        }
    };

    const handleAdaptiveLearning = () => {
        // todo: display only for orgs with permission
        const mistakesPayload = {
            weaknesses: reportData.responseBreakdown.weaknesses,
            commonErrors: reportData.responseBreakdown.commonErrors,
            recommendations: reportData.conclusion.recommendations,
        }
        openLoader(loaderTypes.GENERATE, defaultParams.questions);
        try {
            return generateAdaptiveExam(reportData.responseId, mistakesPayload).then(examId => {
                decrementExamsLeft();
                onClose();
                localStorage.setItem('studentData', JSON.stringify({ timestamp: new Date().getTime(), ...user }));
                closeLoader();
                navigate(`/quiz?id=${examId}`);
            })
        } catch (error) {
            logErrorToServer('Error generating exam:', error);
            openSnackBar(SnackbarTypes.ERROR.field);
        }
    }

    return (
        <>
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={onClose}
                PaperProps={{
                    sx: {
                        width: isMobile ? '90vw' : '60vw',
                        padding: 3,
                        bgcolor: 'background.paper',
                    },
                }}
            >
                <style>
                    {`
                    @media print {
                        .no-pdf {
                            display: none;
                        }
                        .page-break {
                            page-break-before: always;
                        }
                        #report-content {
                            overflow: visible !important;
                        }
                        .avoid-break {
                            break-inside: avoid;
                        }
                    }
                `}
                </style>
                <Box id="report-content" dir={isRTL ? 'rtl' : 'ltr'} sx={{ height: '100%', overflowX: 'hidden', width: '100%', px: 2 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h4" component="div" gutterBottom>
                            {reportLanguageData?.title ?? 'Performance Report'}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h6">
                            {reportLanguageData?.basic_info ?? 'Basic Information:'}
                        </Typography>
                        <List>
                            <ListItem sx={discListItemStyle}>
                                <ListItemText
                                    primary={`${reportLanguageData?.student_name ?? 'Student Name:'} ${reportData.basicInfo.studentName}`}
                                />
                            </ListItem>
                            <ListItem sx={discListItemStyle}>
                                <ListItemText
                                    primary={`${reportLanguageData?.exam_title ?? 'Exam Title:'} ${reportData.basicInfo.examTitle}`}
                                />
                            </ListItem>
                            <ListItem sx={discListItemStyle}>
                                <ListItemText
                                    primary={`${reportLanguageData?.date ?? 'Date:'} ${reportData.basicInfo.date}`}
                                />
                            </ListItem>
                        </List>
                    </Box>
                    {reportData.performance && (
                        <>
                            <Divider sx={{ marginY: 2 }} />
                            <Box>
                                <Typography variant="h6" gutterBottom>
                                    {reportLanguageData?.student_performance ?? 'Student Performance:'}
                                </Typography>
                                {reportData?.performance?.contentKnowledge && (
                                    <>
                                        <Typography variant="subtitle1" gutterBottom fontWeight={600}>
                                            {reportLanguageData?.content_knowledge ?? 'Content Knowledge:'}
                                        </Typography>
                                        <Typography sx={textPadding}>
                                            {reportData.performance.contentKnowledge}
                                        </Typography>
                                    </>
                                )}
                                {reportData?.performance?.accuracy && (
                                    <>
                                        <Typography variant="subtitle1" gutterBottom fontWeight={600}>
                                            {reportLanguageData?.accuracy ?? 'Accuracy:'}
                                        </Typography>
                                        <Typography sx={textPadding}>
                                            {reportData.performance.accuracy}
                                        </Typography>
                                    </>
                                )}
                                {reportData.performance.analysis && (
                                    <>
                                        <Typography variant="subtitle1" gutterBottom fontWeight={600}>
                                            {reportLanguageData?.analysis ?? 'Analysis:'}
                                        </Typography>
                                        <Typography sx={textPadding}>
                                            {reportData.performance.analysis}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </>
                    )}
                    {reportData?.responseBreakdown && (
                        <>
                            <Divider sx={{ marginY: 2 }} />
                            <Box>
                                <Typography variant="h6" gutterBottom>
                                    {reportLanguageData?.response_breakdown ?? 'Response Breakdown:'}
                                </Typography>
                                {reportData?.responseBreakdown?.strengths?.length > 0 && (
                                    <>
                                        <Typography variant="subtitle1" fontWeight={600}>
                                            {reportLanguageData?.strengths ?? 'Strengths:'}
                                        </Typography>
                                        <List>
                                            {reportData.responseBreakdown.strengths.map((strength, index) => (
                                                <ListItem key={index} sx={iconListItemStyle}>
                                                    <CheckIcon color="success" />
                                                    <ListItemText primary={strength} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                )}
                                {reportData?.responseBreakdown?.weaknesses?.length > 0 && (
                                    <>
                                        <Typography variant="subtitle1" fontWeight={600}>
                                            {reportLanguageData?.weaknesses ?? 'Weaknesses:'}
                                        </Typography>
                                        <List>
                                            {reportData.responseBreakdown.weaknesses.map((weakness, index) => (
                                                <ListItem key={index} sx={iconListItemStyle}>
                                                    <ClearIcon color="error" />
                                                    <ListItemText primary={weakness} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                )}
                                {reportData?.responseBreakdown?.commonErrors?.length > 0 && (
                                    <>
                                        <Typography variant="subtitle1" fontWeight={600}>
                                            {reportLanguageData?.common_errors ?? "Common Errors:"}
                                        </Typography>
                                        <List>
                                            {reportData.responseBreakdown.commonErrors.map((error, index) => (
                                                <ListItem key={index} sx={iconListItemStyle}>
                                                    <ClearIcon color='error' />
                                                    <ListItemText primary={error} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                )}
                            </Box>
                        </>
                    )}
                    {reportData?.feedback && (
                        <>
                            <Divider sx={{ marginY: 2 }} />
                            <Box dir={isRTL ? 'rtl' : 'ltr'}>
                                <Typography variant="h6" gutterBottom>
                                    {reportLanguageData?.feedback ?? "Feedback:"}
                                </Typography>
                                {reportData?.feedback?.positiveFeedback?.length > 0 && (
                                    <>
                                        <Typography variant="subtitle1" fontWeight={600}>
                                            {reportLanguageData?.positive_feedback ?? "Positive Feedback:"}
                                        </Typography>
                                        <List>
                                            {reportData.feedback.positiveFeedback.map((feedback, index) => (
                                                <ListItem key={index} sx={discListItemStyle}>
                                                    <ListItemText primary={feedback} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                )}
                                {reportData?.feedback?.areasForImprovement?.length > 0 && (
                                    <>
                                        <Typography variant="subtitle1" fontWeight={600}>
                                            {reportLanguageData?.areas_for_improvement ?? "Areas for Improvement:"}
                                        </Typography>
                                        <List>
                                            {reportData.feedback.areasForImprovement.map((improvement, index) => (
                                                <ListItem key={index} sx={discListItemStyle}>
                                                    <ListItemText primary={improvement} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                )}
                                {reportData?.feedback?.presentation && (
                                    <>
                                        <Typography variant="subtitle1" gutterBottom fontWeight={600}>
                                            {reportLanguageData?.presentation ?? "Presentation:"}
                                        </Typography>
                                        <Typography>{reportData.feedback.presentation}</Typography>
                                    </>
                                )}
                            </Box>
                        </>
                    )}
                    {reportData?.conclusion && (
                        <>
                            <Divider sx={{ marginY: 2 }} />
                            <Box dir={isRTL ? 'rtl' : 'ltr'}>
                                <Typography variant="h6" gutterBottom>
                                    {reportLanguageData?.conclusion ?? "Conclusion:"}
                                </Typography>
                                {reportData?.conclusion?.summary && (
                                    <>
                                        <Typography variant="subtitle1" gutterBottom fontWeight={600}>
                                            {reportLanguageData?.summary ?? "Summary:"}
                                        </Typography>
                                        <Typography sx={textPadding}>{reportData.conclusion.summary}</Typography>
                                    </>
                                )}
                                {reportData?.conclusion?.recommendations.length > 0 && (
                                    <>
                                        <Typography variant="subtitle1" fontWeight={600}>
                                            {reportLanguageData?.recommendations ?? "Recommendations:"}
                                        </Typography>
                                        <List>
                                            {reportData.conclusion.recommendations.map((recommendation, index) => (
                                                <ListItem key={index} sx={iconListItemStyle}>
                                                    <AssistantIcon color="secondary" />
                                                    <ListItemText primary={recommendation} dir={isRTL ? 'rtl' : 'ltr'} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                )}
                            </Box>
                        </>
                    )}
                </Box>
                <Box sx={{ paddingTop: '8px' }}>
                    <Divider />
                    <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-start', paddingTop: '16px', gap: '16px' }}>
                        {user?.org_id && displayAdaptiveLearning &&
                            <Button startIcon={<AutoGraph />} onClick={() => setOpenDialog(true)} variant='container' sx={{ background: colors.gradient, color: 'white', '&:hover': { backgroundColor: '#FC9E6F' } }}>
                                {languageData?.adaptive_learning?.label ?? "Adaptive Learning"}
                            </Button>
                        }
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleDownloadPDF}
                            className="no-pdf"
                            startIcon={<PictureAsPdf />}
                        >
                            {languageData?.exam_dialog?.pdf_label ?? "Download PDF"}
                        </Button>
                    </Box>
                </Box>
            </Drawer>
            <AdaptiveLearningDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                onStart={handleAdaptiveLearning}
                languageData={languageData}
            />
        </>
    );
};

export default PerformanceReport;



const AdaptiveLearningDialog = ({ open, onClose, onStart }) => {
    const { languageData, isRTL } = useLanguage();
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth PaperProps={{ sx: { direction: isRTL ? 'rtl' : 'ltr' } }}>
            <DialogTitle sx={{ fontWeight: "bold", textAlign: "center" }}>
                {languageData?.adaptive_learning?.label ?? "Adaptive Learning"} 🚀
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                    {languageData?.adaptive_learning?.dialog?.challenge ?? "Every challenge is a step forward! Based on your last exam and the mistakes you made, we're crafting a new one just for you."}
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
                    {languageData?.adaptive_learning?.dialog?.chance ?? "This is your chance to sharpen your skills, correct misunderstandings, and master the material."}
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                    {languageData?.adaptive_learning?.dialog?.ready ?? "Ready to take on this journey? Let’s level up your learning! ✨"}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between", pb: 2 }}>
                <Button variant="outlined" color="secondary" onClick={onClose}>
                    {languageData?.adaptive_learning?.dialog?.cancel ?? "Cancel"}
                </Button>
                <Button variant="contained" color="primary" onClick={onStart} fullWidth>
                    {languageData?.adaptive_learning?.dialog?.ready_label ?? "I'm Ready! 🚀"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
