import { useState } from "react";
import { useLanguage } from "../../contexts/languageContext";
import { Box, CardContent, IconButton, Typography, Collapse, Radio, RadioGroup, FormControlLabel, TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MergeTypes } from "./ExamList";

function SettingsPanel({ examId, selected, handleRadioChange, handleRandomCountChange, randomCount }) {
    const { languageData } = useLanguage();
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <CardContent sx={{ paddingTop: 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                <IconButton
                    onClick={handleExpandClick}
                    aria-expanded={isExpanded}
                    aria-label="show more"
                    sx={{ alignSelf: 'flex-end', transform: isExpanded ? 'rotate(180deg)' : 'rotate(0)' }}
                >
                    <ExpandMoreIcon />
                </IconButton>
                <Typography color="text.secondary">
                    {languageData?.preview?.merge_dialog?.settings ?? "Settings"}
                </Typography>
            </Box>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <Box sx={{ paddingLeft: 3 }}>
                    <RadioGroup
                        aria-label="merge-type"
                        name={`merge-type-${examId}`}
                        value={selected?.type ?? MergeTypes.none}
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel value={MergeTypes.whole} control={<Radio />} label={languageData?.preview?.merge_dialog?.whole_merge_label ?? "Merge the entire exam"} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                            <FormControlLabel value={MergeTypes.random} control={<Radio />} label={languageData?.preview?.merge_dialog?.prefix_random_merge_label ?? "Merge with "} />
                            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                <TextField
                                    type="number"
                                    placeholder="X"
                                    value={randomCount}
                                    onChange={(e) => handleRandomCountChange(parseInt(e.target.value, 10) || '')}
                                    size="small"
                                    sx={{ width: '80px', mx: 1 }}
                                    disabled={selected?.type !== MergeTypes.random}
                                />
                                <Typography>{languageData?.preview?.merge_dialog?.suffix_random_merge_label ?? "random questions"}</Typography>
                            </Box>
                        </Box>
                    </RadioGroup>
                </Box>
            </Collapse>
        </CardContent>
    );
};

export default SettingsPanel;