import React, { useMemo } from 'react';
import { Box, Button, CircularProgress, IconButton, Grid, Paper, Tooltip } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useLanguage } from '../../contexts/languageContext';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DeleteConfirmationPopver } from '../../components/DeleteConfirmationPopover';
import { colors } from '../../utils/customTheme';
import { localizeDate } from '../../utils/dateHelpers';
import ToolbarWithExcelExport from '../../components/DataGrid/ToolbarWithExcelExport';

const maxRowsPerPage = 10;

const StudentTable = ({ students: responses, onStudentClick, onRequestReport, reportState, onDeleteResponse, examTitle }) => {
    const { isRTL, languageData } = useLanguage();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const studentIdToDeleteRef = React.useRef(null);
    const hasSchoolName = useMemo(() => responses.some((response) => response.student_info?.identity_provider_payload?.idm_data?.schoolname || response.student_info?.idm_data?.schoolname), [responses]);
    // const studentsGradesList = useMemo(() => responses.map(student => ({
    //     [languageData?.review?.response?.full_name ?? 'Full Name']: reverseName(student.student_name),
    //     [languageData?.review?.response?.id ?? 'Id']: student.student_info.personal_id ?? '',
    //     [languageData?.review?.response?.grade ?? 'grade']: student.grade,
    //     [languageData?.review?.response?.date ?? 'date']: student.date,
    //     [languageData?.review?.response?.email ?? 'email']: student.email ?? '',
    // })), [responses, isRTL]);


    const handleClickDelete = (target, studentId) => {
        setAnchorEl(target);
        studentIdToDeleteRef.current = studentId;
    };

    // Define the columns for the DataGrid
    const columns = [
        { field: 'student_name', headerName: languageData?.review?.students_table?.name ?? 'Name', width: 150, },
        { field: 'personal_id', headerName: languageData?.review?.students_table?.id ?? 'ID', width: 100 },
        { field: 'grade', headerName: languageData?.review?.students_table?.grade ?? 'Grade', width: 100 },
        ...(hasSchoolName ? [{
            field: 'schoolname',
            headerName: languageData?.review?.students_table?.school_name ?? 'School Name',
            width: 150,
        }] : []),
        {
            field: 'date', headerName: languageData?.review?.students_table?.submission_time ?? 'Submission Time', type: 'dateTime',
            width: 160,
            valueFormatter: (val) => {
                if (!val) return '---';
                return localizeDate(val);
            },
        },
        {
            field: 'status',
            headerName: languageData?.review?.students_table?.status ?? 'Status',
            width: 120,
            renderCell: (params) => (
                <div style={{ height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center' }} >
                    <div>
                        {params.row.reviewSubmitted && <CheckCircleIcon style={{ fontSize: 20, marginRight: 4, color: 'lightgreen' }} />}
                        {params.row.saveForLater && <AccessTimeIcon style={{ color: 'orange', fontSize: 20, marginRight: 4 }} />}
                    </div>
                    {params.row.supervised.auto_submitted && params.row.supervised.supervisor_notes && (
                        <Tooltip title={languageData?.review?.students_table?.left_exam_msg ?? "The student left the exam"} arrow>
                            <ReportIcon color="error" style={{ fontSize: 24, }} />
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: languageData?.review?.students_table?.actions ?? 'Actions',
            minWidth: 300,
            flex: 1,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button variant="contained" sx={{ maxHeight: '40px' }} onClick={() => onStudentClick(responses?.find(response => response._id === params.row.id))}>
                        {languageData?.review?.review}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ maxHeight: '40px' }}
                        onClick={() => onRequestReport(params.row.id)}
                        disabled={reportState.loading && reportState.response_id === params.row.id}
                    >
                        {reportState.loading && reportState.response_id === params.row.id ? <CircularProgress size={22} color="secondary" /> : languageData?.review?.report}
                    </Button>
                    <IconButton onClick={(e) => handleClickDelete(e.currentTarget, params.row.id)}>
                        <DeleteOutlineIcon color="error" />
                    </IconButton>
                </div>
            ),
        }
    ];

    // Map the responses data to fit the DataGrid row format
    const rows = responses.map((response) => ({
        id: response._id,
        student_name: reverseName(response.student_name) ?? '---',
        personal_id: response.student_info?.personal_id ?? '---',
        grade: response.grade,
        date: response.date,
        reviewSubmitted: response.reviewSubmitted,
        saveForLater: response.saveForLater,
        supervised: response.supervised,
        schoolname: (response.student_info?.identity_provider_payload?.idm_data ?? response.student_info?.idm_data)?.schoolname ?? ''
    }));

    // Function to apply alternating row colors
    const getRowClassName = (params) => {
        return params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow';
    };

    return (
        <Box mb={2} sx={{ direction: isRTL ? 'rtl' : 'ltr', width: '100%' }}>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={10} md={12} lg={12}>
                    <Paper>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            slots={{ toolbar: () => <ToolbarWithExcelExport rows={rows} columns={columns} filename={examTitle} /> }}
                            slotProps={{ toolbar: { csvOptions: { utf8WithBom: true, fileName: examTitle } } }}
                            onRowClick={(params) => onStudentClick(responses?.find(response => response._id === params.row.id))}
                            hideFooterPagination={rows.length < maxRowsPerPage}
                            hideFooter={rows.length < maxRowsPerPage}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 25, page: 0 },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50, 100]}
                            autoHeight
                            disableSelectionOnClick
                            getRowClassName={getRowClassName}
                            sx={{
                                overflowX: 'auto',
                                '& .MuiDataGrid-columnHeader': {
                                    backgroundColor: 'white',
                                    color: '#2a2a2a',
                                    fontSize: '16px'
                                },
                                '& .MuiDataGrid-cell': {
                                    width: 'auto',
                                    direction: isRTL ? 'rtl' : 'ltr',
                                    textAlign: isRTL ? 'right' : 'left',
                                },
                                '& .MuiDataGrid-toolbarContainer': {
                                    direction: 'ltr', // Forces the toolbar to always be LTR
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    direction: 'ltr'
                                },
                                '& .evenRow': {
                                    backgroundColor: colors.bg,
                                },
                                '& .oddRow': {
                                    backgroundColor: '#ffffff',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    direction: 'ltr'
                                },
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <DeleteConfirmationPopver
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={() => setAnchorEl(null)}
                onCancel={() => setAnchorEl(null)}
                onDelete={() => { setAnchorEl(null); onDeleteResponse(studentIdToDeleteRef.current); }}
            />
        </Box>
    );
};

export default StudentTable;

function reverseName(name) {
    if (!name) return '';
    const nameParts = name.split(' ');

    if (nameParts.length < 2) return name;

    const lastName = nameParts.pop();
    nameParts.unshift(lastName);

    return nameParts.join(' ');
}
