import React from "react";
import MicrosoftLogin from "react-microsoft-login";
import { useUser } from '../hooks/useUser'
import api from '../requests/api'
import { useNavigate } from 'react-router-dom'
import { HttpStatusCode } from 'axios'
import { handleSelectedtierIfExist } from '../utils'
import useHistory from "../hooks/useHistory";
import { handleRedirectAfterAuth } from "../utils/auth";
import { Box } from "@mui/material";
import { allowFocusStyle } from "../utils/customTheme";
import { logErrorToServer } from "../requests/error";


export default (props) => {
    const navigate = useNavigate()
    const { login } = useUser()
    const historyContext = useHistory()

    const authHandler = async (err, data) => {
        if (!data?.account) {
            return
        }
        const payload = {
            user: {
                email: data.account.username,
                fullname: data.account.name,
            },
            microsoftIdToken: data.idToken
        }
        if (props.token) {
            payload.token = props.token
        }

        try {
            const res = await api.post(`/auth/microsoft`, payload);

            if (res.status === HttpStatusCode.Ok) {
                // Check if the 'Authorization' header is present in the responseW
                handleSelectedtierIfExist()
                login(res.data);
                historyContext.resetHistory()
                handleRedirectAfterAuth(navigate, res?.data)
            }
        } catch (error) {
            logErrorToServer('Error making POST request to /auth/microsoft:', error);
        }
    };
    return (
        <Box sx={{ ...allowFocusStyle, width: 'fit-content', height: 'fit-content' }} tabIndex="0">
            <MicrosoftLogin graphScopes={["openid", "profile", "email"]} clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID} authCallback={authHandler} />
        </Box>
    );
};