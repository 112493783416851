// FileUploadHandler.js
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLanguage } from '../contexts/languageContext';
import ROLES from '../utils/roles';
import UploadFiles from './Upload/UploadFiles';
import { Typography } from '@mui/material';
import readPPT from '../requests/ppt';
import readTXT from '../requests/txt';
import { readPDF } from '../requests/pdf';
import { readDocx } from '../requests/docx';
import useSnackBar from '../hooks/useSnackBar';
import { useLoader, loaderTypes } from '../hooks/useLoader';
import { SnackbarTypes } from '../utils/snackbarTypes';
import TextRangeDialog from './TextRangeDialog';
import { paginateText, cutTextByPages } from '../utils';

function FileUploadHandler({ onInput, maxFiles, userRoles = ['FREE'], maxCharsLimit = 10000, onClearFiles = null, disabled = false }) {
    /** @type {[{file: File, name: string, sizeText: string, text?: string, isValidType?: boolean}[], Function]} */
    const [files, setFiles] = useState([]);
    const [totalChars, setTotalChars] = useState(0);
    const { languageData } = useLanguage();
    const { openSnackBar } = useSnackBar();
    const { openLoader, closeLoader } = useLoader();

    // State to manage the text range dialog
    const [openDialog, setOpenDialog] = useState(false);
    const pendingText = useRef('');
    const pendingPages = useRef([]);

    const FILE_TEXT_LIMIT = Math.max(ROLES.getMaxLimit(userRoles), maxCharsLimit);
    const isPremiumUser = ROLES.isPremiumRole(userRoles);

    const validFilesFilter = useCallback(file => file.isValidType, []);
    const exceededChars = useMemo(() => totalChars > FILE_TEXT_LIMIT, [totalChars, FILE_TEXT_LIMIT]);
    const clearFilesFromInput = useRef(null);

    useEffect(() => {
        let text = convertFilesToText(files.filter(validFilesFilter));
        setTotalChars(text?.length ?? 0);
        if (files.length > 0 && files.every(validFilesFilter)) {
            if (!text || !text.length) return;

            if (text.length > FILE_TEXT_LIMIT) {
                // Instead of truncating automatically, open a dialog to let the user select a page range.
                pendingText.current = text;
                pendingPages.current = paginateText(text);
                setOpenDialog(true);
                return;
            }
            onInput(text);
        }
    }, [files]);

    useEffect(() => {
        if (exceededChars) {
            openSnackBar(SnackbarTypes.CONTENT_LIMIT.field);
        }
    }, [totalChars]);

    // Expose the clearFiles function via props
    useEffect(() => {
        // Function to clear files
        const clearFiles = () => {
            setFiles([]);
            clearFilesFromInput.current();
        };

        if (onClearFiles) {
            onClearFiles(clearFiles);
        }
    }, [onClearFiles]);

    const handleUpload = async (newFiles) => {
        if (newFiles.length && newFiles.some(file => !file.isValidType)) {
            openSnackBar(SnackbarTypes.UPLOADED_FILE_TYPE_NOT_SUPPORTED.field);
            return;
        }

        openSnackBar(SnackbarTypes.READING_FILE.field);
        openLoader(loaderTypes.READING_FILE);
        let result = newFiles;
        readFiles(newFiles)
            .then(filesAfterRead => {
                if (filesAfterRead && filesAfterRead.length) {
                    result = filesAfterRead;
                    if (filesAfterRead[0]?.text?.length > 1)
                        openSnackBar(SnackbarTypes.FILE_READY.field);
                }
                return filesAfterRead;
            })
            .finally(() => {
                setFiles(prevFiles => [...prevFiles, ...result]);
                closeLoader();
            });
    };

    const handleRemoveFile = (index) => {
        if (index >= 0 && index < files?.length) {
            setFiles(prevFiles => {
                const newFiles = [...prevFiles.slice(0, index), ...prevFiles.slice(index + 1)];
                return newFiles;
            });
        }
    };

    async function readFiles(files) {
        return Promise.all(
            files.map(file => {
                const type = file.file.type;
                if (
                    file.isValidType &&
                    (type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                        type === 'application/vnd.ms-powerpoint')
                ) {
                    return handleReadResponse(readPPT, file);
                } else if (type === 'application/pdf') {
                    return handleReadResponse(readPDF, file);
                } else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    return handleReadResponse(readDocx, file);
                } else if (type === 'text/plain' || file.file.name.toLowerCase().endsWith('.vtt')) {
                    return handleReadResponse(readTXT, file);
                } else {
                    file.text = '';
                    return new Promise(res => res(file));
                }
            })
        );
    }

    function convertFilesToText(files) {
        if (files.length <= 1) {
            return files[0]?.text ?? '';
        }
        return files.map((file, index) => `Text ${index + 1}:\n${file?.text ?? ''}`).join('\n\n');
    }

    async function handleReadResponse(readFunc, file) {
        return readFunc(file.file)
            .then(response => {
                if (!response || !response?.data?.text?.length) {
                    openSnackBar(SnackbarTypes.CHECK_FILE.field);
                    file.text = '';
                } else {
                    file.text = response.data.text;
                }
                return file;
            })
            .catch(() => {
                openSnackBar(SnackbarTypes.ERROR.field);
                file.text = '';
                return file;
            });
    }

    const handleDialogSubmit = (startPage, endPage) => {
        const trimmedText = cutTextByPages(pendingText.current, startPage, endPage);
        setTotalChars(trimmedText.length);
        onInput(trimmedText);
        setOpenDialog(false);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        // Optionally, you could provide a default truncation here.
        onInput(pendingText.current.substring(0, FILE_TEXT_LIMIT));
    };

    return (
        <>
            <UploadFiles
                files={files}
                onUpload={handleUpload}
                onRemove={handleRemoveFile}
                isPremiumUser={isPremiumUser}
                maxFiles={maxFiles}
                disabled={disabled || exceededChars}
                onClearFiles={(clearFiles) => clearFilesFromInput.current = clearFiles}
            >
                <Typography variant="body2">
                    {totalChars}/{FILE_TEXT_LIMIT}{' '}
                    {languageData?.file_uploader?.characters || 'characters'}
                </Typography>
            </UploadFiles>
            <TextRangeDialog
                open={openDialog}
                onClose={handleDialogClose}
                pages={pendingPages.current}
                onSubmit={handleDialogSubmit}
                length={totalChars}
                userRoles={userRoles}
            />
        </>
    );
}

export default FileUploadHandler;
