import api from "./api";
import { logErrorToServer } from "./error";

export async function readDocx(file) {
    const formData = new FormData();
    formData.append('docxFile', file);

    try {
        const response = await api.post('/exports/docx/read', formData);
        return response;
    } catch (error) {
        logErrorToServer(`Error reading docx: ${error}`);
        return null;
    }
}

export async function requestDownloadDocx(examId) {
    if (!examId) {
        logErrorToServer('Error: id is required to download exam docx');
        return;
    }

    let downloadUrl = null
    let filename = 'exam.docx'
    try {
        const url = `/exams/${examId}/docx/download`
        const response = await api({
            method: 'get',
            url: url,
            responseType: 'blob',
        });
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition.includes("UTF-8''")) {
            // decode filename for non-english chars
            const filenameToDecode = contentDisposition?.split("UTF-8''")[1];
            filename = decodeURIComponent(filenameToDecode);  // Decode filename from Content-Disposition
        } else {
            // get filename for only english names
            filename = contentDisposition?.split('filename="')[1];
            if (filename === undefined) {
                filename = contentDisposition?.split('filename=')[1];
            }
        }
        filename = filename.replace(/[\/\\]/g, '_').replace(/\.docx.*$/, '.docx');
        // TODO: handle error if respnse.data is null
        downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        logErrorToServer('Error Downloading docx: ', error);
    } finally {
        // Cleanup: Remove the link and revoke the download URL
        if (downloadUrl) {
            window.URL.revokeObjectURL(downloadUrl);
        }
        const linkToRemove = document.querySelector(`[download="${filename}"]`);
        if (linkToRemove) {
            document.body.removeChild(linkToRemove);
        }
    }
}