import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import html2pdf from 'html2pdf.js';
import { useLanguage } from '../../contexts/languageContext';
import { questionsType } from '../../utils/examOptions';

function DownloadPDF({ exam, label, openSnackBar }) {
  const { isRTL, languageData } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const use_indicator = exam?.parameters?.use_indicator;

  const generateOpenQuestionHTML = (q) => {
    let html = ``;
    if (use_indicator && q.indicator) {
      q.indicator.forEach(ind => {
        html += `<p><strong>${ind.points} ${languageData?.export_labels?.points ?? "points"}</strong> - ${ind.concept}</p>`;
      });
    } else {
      html += `<p>${q.explanation}</p>`;
    }
    return html;
  };

  const generateExamHTML = (exam) => {
    let html = `<div style='font-family: Arial, sans-serif; direction: ${isRTL ? 'rtl' : 'ltr'};'>`;
    html += `<p style='font-size: 22px; font-weight: bold;'>${exam.title}</p><br/>`;
    if (exam.instructions) html += `<p style='font-size: 18px; font-weight: bold;'>${languageData?.export_labels?.instructions ?? "Instructions"}:</p><p>${exam.instructions}</p><br/>`;
    if (exam.text) html += `<p style='font-size: 18px; font-weight: bold;'>${languageData?.export_labels?.text ?? "Text"}:</p><p>${exam.text}</p><br/>`;

    html += `<p style='font-size: 22px; font-weight: bold;'>${languageData?.export_labels?.questions ?? "Questions"}</p><br/>`;
    exam.questions.forEach((q, index) => {
      html += `<div>`;
      html += `<p style='font-size: 16px; text-decoration: underline;'>${languageData?.export_labels?.question ?? "Question"} ${index + 1}</p>`;
      if (q.image) html += `<div style="display: flex; justify-content: center;"><img src='${q.image?.url}' style='max-width: 500px; max-height: 500px; margin: auto;'/></div><br/>`;
      if (q.text) html += `<p>${q.text}</p><br/>`;
      html += `<p style='font-size: 16px; font-weight: bold;'>${q.question}</p>`;

      if ([questionsType.multiple, questionsType.fill_in].includes(q.type) && q.options) {
        html += `<ol>`;
        q.options.forEach((option, i) => {
          html += `<li>${i + 1}. ${option}</li>`;
        });
        html += `</ol>`;
      }
      if (q.type === questionsType.nested) {
        q.nested_questions.forEach(nq => {
          // if (nq.image) html += `<img src='${nq.image?.url}' style='max-width: 100%; height: auto;'/>`;
          if (nq.text) html += `<p>${nq.text}</p>`;
          html += `<p style='font-size: 16px; font-weight: bold;'>${nq.question}</p>`;
        });
      }
      html += `</div><br/><br/>`;
    });

    html += `<div style='page-break-after: always;'>.</div>`;
    html += `<div style='page-break-before: always; height: 0;'><p style='font-size: 20px; font-weight: bold;'>${languageData?.export_labels?.answers ?? "Answers"}</p></div><br/><br/>`;
    exam.questions.forEach((q, index) => {
      html += `<div>`;
      html += `<p style='font-size: 16px; font-weight: bold;'>${index + 1}. ${q.question}</p>`;
      if ([questionsType.multiple, questionsType.fill_in].includes(q.type)) {
        html += `<p><strong>${languageData?.export_labels?.answer ?? "Answer"}:</strong> ${q.correctAnswers?.[0]}</p>`;
        html += `<p><strong>${languageData?.export_labels?.explanation ?? "Explanation"}:</strong> ${q.explanation}</p>`;
      } else if ([questionsType.open, questionsType.graph].includes(q.type)) {
        html += generateOpenQuestionHTML(q);
      } else if (questionsType.nested == q.type && q.nested_questions) {
        q.nested_questions.forEach(nq => {
          html += generateOpenQuestionHTML(nq);
        });
      }
      html += `</div><br/><br/>`;
    });

    html += `</div>`;
    return html;
  };

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      new Promise((resolve, reject) => {
        const htmlContent = generateExamHTML(exam);
        const opt = {
          margin: 10,
          filename: `${exam?.title ?? 'exam'}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
        resolve(html2pdf().from(htmlContent).set(opt).save());
      }).then(() => {
        setIsLoading(false);
      });
    } catch (error) {
      console.error(error)
      openSnackBar('ERROR');
      setIsLoading(false);
    }
  };

  return (
    <Button
      sx={{ marginTop: '15px', textTransform: 'capitalize' }}
      variant='contained'
      color='error'
      onClick={handleDownload}
    >
      {isLoading ? <CircularProgress sx={{ color: 'white' }} /> : label}
    </Button>
  );
}

export default DownloadPDF;
