import { useState, useEffect } from 'react';
import { FormHelperText, FormControl, InputLabel, MenuItem, Select, Link } from '@mui/material';
import { useLanguage } from '../contexts/languageContext';

const CustomSelect = ({ onChangeHandler, value, options, id, label, helperLink, helperLinkHref, firstDisabledItem, selectProps, useFirstOptionIfNoValue = true, optional = false, helperText = null }) => {
  const { isRTL } = useLanguage();
  const [val, setVal] = useState((!value && useFirstOptionIfNoValue) ? options[0]?.value : value)
  const { selectSX, ...restSelectProps } = selectProps || {}
  useEffect(() => {
    setVal((!value && useFirstOptionIfNoValue) ? options[0]?.value : value);
  }, [value]);

  const handleChange = (e) => {
    setVal(e.target.value)
    onChangeHandler(e)
  }

  return (
    <FormControl fullWidth sx={{ bgcolor: 'white' }}>
      {options?.length > 0 && id && (
        <InputLabel id={`${id}-label`}>{id}</InputLabel>
      )}
      <Select
        fullWidth
        label={label}
        labelId={`${id}-label`}
        // id={`${id}-select`}
        value={val}
        onChange={handleChange}
        sx={{
          ...(isRTL ?
            { textAlign: 'right', '& .MuiSelect-icon': { left: '7px', right: 'auto' } } :
            { textAlign: 'left', '& .MuiSelect-icon': { right: '7px', left: 'auto' } }),
          ...selectSX
        }}
        {...restSelectProps}
        error={optional ? false : !val}
      >
        {firstDisabledItem &&
          <MenuItem disabled value="" sx={{ justifyContent: 'flex-start', direction: isRTL ? 'rtl' : 'ltr' }}>
            {firstDisabledItem}
          </MenuItem>}

        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ justifyContent: 'flex-start', direction: isRTL ? 'rtl' : 'ltr' }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {helperLink && (
        <FormHelperText sx={{ p: 1, textAlign: 'center' }}>
          <Link href={helperLinkHref} target="_blank" rel="noopener" sx={{ fontWeight: 'bold' }}>
            {helperLink}
          </Link>
        </FormHelperText>
      )}
      {helperText && (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      )}
    </FormControl >
  );
};

export default CustomSelect;
