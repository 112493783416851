import React, { useState } from 'react';
import { Button } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { duplicateExam } from '../requests/exam';
import useSnackBar from '../hooks/useSnackBar';
import useHistory from '../hooks/useHistory';
import { useLanguage } from '../contexts/languageContext';
import { SnackbarTypes } from '../utils/snackbarTypes';
import { logErrorToServer } from '../requests/error';

const DuplicateExamButton = ({ examId, userId, buttonProps = {}, successCallback= null }) => {
    const [disabled, setDisabled] = useState(false)
    const { openSnackBar } = useSnackBar();
    const { languageData } = useLanguage();
    const historyContext = useHistory()

    if (!examId || !userId) return null;

    const handleDuplicate = async () => {
        try {
            setDisabled(true)
            const newId = await duplicateExam(examId, userId);
            openSnackBar(SnackbarTypes.SUBMIT_SUCCESS.field);
            historyContext.addNewExamToHistory(newId)
            if (successCallback) successCallback(newId)
        } catch (error) {
            openSnackBar(SnackbarTypes.ERROR.field);
            logErrorToServer('Error duplicating exam:', error);
        }
        setDisabled(false)
    };

    return (
        <Button size="small" startIcon={<FileCopyIcon />} onClick={handleDuplicate} {...buttonProps} disabled={disabled}>
            {languageData?.duplicate_btn_label || "Duplicate"}
        </Button>
    );
};

export default DuplicateExamButton;
