import React, { useMemo } from 'react';
import { Switch, FormControlLabel, Tooltip } from '@mui/material';
import { useLanguage } from '../../contexts/languageContext';
import { useUser } from '../../hooks/useUser';
import ROLES from '../../utils/roles';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const IndicatorToggle = ({ isIndicatorOn, handleToggleChange, forceDisable = false }) => {
    const { user } = useUser();
    const { languageData } = useLanguage();
    const hasAccess = useMemo(() => !user?.roles.includes(ROLES.FREE.label), [user?.roles]);

    return (
        <Tooltip title={(languageData?.indicator_toggle?.tooltip ?? "If checked, open questions will use indicators")} arrow placement='bottom'>
            <FormControlLabel
                control={
                    <Switch
                        checked={isIndicatorOn}
                        onChange={handleToggleChange}
                        name="indicatorToggle"
                        color="primary"
                        disabled={!hasAccess || forceDisable}
                    />
                }
                label={<>
                    {languageData?.indicator_toggle?.label ?? "Use indicators for open questions"}
                    {!hasAccess && <> {languageData?.indicator_toggle?.premium} <AutoAwesomeIcon style={{ marginRight: 5, fontSize: '20px' }} /></>}
                </>}
            />
        </Tooltip>
    );
};

export default IndicatorToggle;
