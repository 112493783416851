import { useState, useEffect } from "react";
import { reportClickKey } from "../../../requests/analytics";

const SCHOOL_REGISTRATION_KEY = "school_registration_last_click";
const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;

export function useSchoolRegistrationDialog({ showKey, clickKey }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const lastClick = localStorage.getItem(SCHOOL_REGISTRATION_KEY);
        const now = Date.now();

        if (!lastClick || now - parseInt(lastClick) > ONE_WEEK) {
            setOpen(true);
            reportClickKey(showKey);
        }
    }, []);

    const handleRegisterClick = (url) => {
        localStorage.setItem(SCHOOL_REGISTRATION_KEY, Date.now().toString());
        setOpen(false);
        reportClickKey(clickKey);
        window.open(url, "_blank");
    };

    return { open, setOpen, handleRegisterClick };
}
