import React, { useMemo } from 'react';
import { Grid, Paper } from '@mui/material';
import CustomSelect from '../CustomSelect';
import { isMobile } from '../../utils';
import { defaultLanguageParam, defaultParams } from '../../utils/examOptions';
import { allOptions } from '../../utils/examOptions';
import { languageCodes, useLanguage } from '../../contexts/languageContext';
import { useSubjects } from '../../contexts/SubjectsContext';
import { useUser } from '../../hooks/useUser';
import ROLES from '../../utils/roles';

const ExamParameters = ({
    onLevelChange,
    onLanguageChange,
    onSubjectChange,
    languageData,
    remixParameters,
    userSubjects
}) => {
    const { language } = useLanguage();
    const { user } = useUser();
    const { subjects: allSubjects } = useSubjects();

    const languageDefaultParam = defaultLanguageParam(language, languageCodes.hebrew);

    // Helper function to extract the label based on the current language
    const getSubjectLabel = (subject) => {
        if (typeof subject !== 'object' || subject === null) {
            return subject;
        }
        return subject?.labels?.[language] || subject?.labels?.['en'] || 'Unknown Subject';
    };

    const subjectOptions = useMemo(() => {
        const noSubject = { labels: { en: 'No Specific Subject', he: 'ללא נושא' } };
        const subjects = allSubjects?.filter(subject => userSubjects.includes(subject._id))?.map((subject) => ({
            value: subject?._id,
            label: getSubjectLabel(subject),
        }))?.sort((a, b) => a.label?.localeCompare(b.label));

        if (subjects?.length)
            return [{ value: "None", label: getSubjectLabel(noSubject) }].concat(subjects)
        else return []
    }, [allSubjects, userSubjects, language]);

    // Sort languages by alphabetical order
    const sortedLanguages = useMemo(() => {
        return (languageData?.languageOptions ?? allOptions.languageOptions)?.sort((a, b) => a.label?.localeCompare(b.label)) || [];
    }, [languageData]);

    return (
        <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 2.7, backgroundColor: 'transparent' }}>
            <Grid
                container
                direction='row'
                spacing={2}
                justifyContent='flex-start'
                alignItems='center'
                padding={2}
                width="100%"
            >
                {!user?.roles.includes(ROLES.ENTERPRISE.label) && <Grid item xs={isMobile ? 12 : 4}>
                    <CustomSelect
                        id={languageData?.difficulty_label ?? 'Difficulty'}
                        label={languageData?.difficulty_label ?? 'Difficulty'}
                        value={remixParameters?.difficulty ?? defaultParams.examDifficulty}
                        onChangeHandler={onLevelChange}
                        options={languageData?.levelOptions ?? allOptions.levelOptions}
                    />
                </Grid>}
                <Grid item xs={isMobile ? 12 : 4}>
                    <CustomSelect
                        id={languageData?.language_label ?? 'Language'}
                        label={languageData?.language_label ?? 'Language'}
                        value={remixParameters?.language ?? languageDefaultParam}
                        onChangeHandler={onLanguageChange}
                        options={sortedLanguages}
                    />
                </Grid>
                {!user?.roles.includes(ROLES.ENTERPRISE.label) && subjectOptions.length > 1 && <Grid item xs={isMobile ? 12 : 4}>
                    <CustomSelect
                        id={languageData?.subject_label ?? 'Subject'}
                        label={languageData?.subject_label ?? 'Subject'}
                        value={remixParameters?.subject ?? 'None'}
                        onChangeHandler={onSubjectChange}
                        options={subjectOptions}
                        useFirstOptionIfNoValue={false}
                    />
                </Grid>}
            </Grid>
        </Paper>
    );
};

export default ExamParameters;
