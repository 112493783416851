import React, { useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, Box } from '@mui/material';
import { questionsTypesOptions } from '../../utils/examOptions';
import { languageCodes, useLanguage } from '../../contexts/languageContext';
import QuestionTypeCard from './QuestionTypeCard';
import { questionsType } from "../../utils/examOptions";


const grade = 5;
//Whitelist, if you want to add another question type, explicitly write it in the array below 
const allowedQuestionTypes = [questionsType.multiple, questionsType.open, questionsType.fill_in];
const imageMap = {
  [questionsType.multiple]: "https://res.cloudinary.com/studywise/image/upload/v1743066690/multiple_choice_rn5a8j.png",
  [questionsType.fill_in]: "https://res.cloudinary.com/studywise/image/upload/v1743066690/fill_in_the_blank_lkcma7.png",
  [questionsType.open]: "https://res.cloudinary.com/studywise/image/upload/v1743066690/open_ended_pzxlng.png",
}

const AddQuestionDialog = ({ open, onClose, onAddQuestion }) => {
  const { isRTL, languageData, language } = useLanguage()

  const questionTypes = useMemo(() =>
    questionsTypesOptions.filter(option => allowedQuestionTypes.includes(option.value)),
    [questionsTypesOptions]
  );

  const questionsOptions = useMemo(() =>
    questionTypes.map(({ value, label, hebrewLabel, arabicLabel }) => ({
      value,
      label:
        language === languageCodes.hebrew ? hebrewLabel :
          language === languageCodes.arabic ? arabicLabel :
            label,
    })),
    [questionTypes, language]
  );


  const handleSelectQuestionType = (type) => {
    // Call the provided handler with the selected type (and a default grade for example)
    onAddQuestion({ type, grade });
    onClose();
  };
  const handleClose = () => {
    handleSelectQuestionType(null);
  }
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => { handleClose(); }}
      dir={isRTL ? 'rtl' : 'ltr'}
      PaperProps={{ sx: { minWidth: '50%', p: 1 } }}
    >
      <DialogTitle sx={{ textAlign: "center", fontSize: "1.4rem" }}>
        {languageData?.add_dialog?.title || 'Select Question Type'}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: { xs: '8px', md: '16px' },
            flexWrap: 'wrap',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          {questionsOptions.map((option, index) => (
            <QuestionTypeCard
              key={option.value}
              questionType={option.value}
              title={option.label}
              image={imageMap[option.value]}
              onSelect={handleSelectQuestionType}
            />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default AddQuestionDialog;
