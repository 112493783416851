// React
import React, { useEffect, useState } from 'react';
// Components
import { CircularProgress, TextField } from '@mui/material';
import Header from '../components/Header';
// Hooks
import useSnackBar from '../hooks/useSnackBar';
import useStudentHistory from '../hooks/useStudentHistory'
import { useDebounce } from '../hooks/useDebounce';
import { colors, paddingPageStyle } from '../utils/customTheme';
import { Helmet } from 'react-helmet-async';
import StudentExamsCardList from '../components/StudentExamsCardList';
import { useLanguage } from '../contexts/languageContext';
import { logErrorToServer } from '../requests/error';

const StudentHistory = () => {
    const { studentHistory, fetchHistory } = useStudentHistory()
    const { languageData } = useLanguage();
    const { openSnackBar } = useSnackBar();
    const [historyData, setHistoryData] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(true);
    const debouncedSearchExam = useDebounce(searchExam, 300);

    useEffect(() => {
        if (!initialized) {
            setLoading(true);
            openSnackBar("FETCHING");
            fetchHistory().then((response) => {
                openSnackBar("FETCH_SUCCEEDED");
                if (response) {
                    setHistoryData(response);
                } else {
                    setHistoryData([]);  // Handle undefined response
                }
                setInitialized(true);
            }).catch(err => {
                openSnackBar("ERROR");
                logErrorToServer("Error fetching history:", err);
            }).finally(() => setLoading(false));
        }
    }, [initialized]);  // Remove studentHistory from dependencies

    /** @param {string} input */
    function searchExam(input) {
        if (input) {
            const loweredCaseInput = input.toLowerCase()
            const filteredHistory = studentHistory.filter(response => response?.exam_details?.title?.toLowerCase().includes(loweredCaseInput))
            setHistoryData(filteredHistory)
        } else {
            setHistoryData(studentHistory)
        }
    }

    return (
        <>
            <Helmet>
                <title>StudyWise | Student History</title>
                <meta name="description" content="Review your past exams and track your progress with our AI-powered exam generator. Easily revisit and analyze previous exams to enhance future learning outcomes." />
            </Helmet>
            <Header />
            <div style={{ ...paddingPageStyle, padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                <TextField label="Search Exam" placeholder="Search Exam" variant="outlined"
                    onInput={e => debouncedSearchExam(e.target.value)}
                    sx={{ marginBottom: '20px', minWidth: '-webkit-fill-available' }}
                    autoComplete='off'
                    inputProps={{ autoFocus: false }}
                />

                {historyData.length > 0 ? (
                    <StudentExamsCardList exams={historyData} />
                ) : (loading ?
                    <CircularProgress disableShrink size={'4rem'} sx={{ color: colors.main, marginTop: '3rem' }} />
                    :
                    <p>{languageData?.history?.no_exams || "No exams available"}</p>
                )}
            </div>
        </>
    );
};

export default StudentHistory;