import React, { useState } from 'react';
import { Typography, Tabs, Tab } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Header from '../../components/Header';
import { paddingPageStyle } from '../../utils/customTheme';
import useSnackBar from '../../hooks/useSnackBar';
import { useLanguage } from '../../contexts/languageContext';
import { useUser } from '../../hooks/useUser';
import ROLES from '../../utils/roles';
import PremiumDialog from '../../components/PremiumDialog';
import PastQuestions from './PastQuestions';
import Resources from './Resources';

const Personalization = () => {
    const { openSnackBar } = useSnackBar();
    const { languageData, isRTL } = useLanguage();
    const { user } = useUser();
    const [activeTab, setActiveTab] = useState(1);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <>
            <Helmet>
                <title>StudyWise | Personalization</title>
                <meta name="description" content="Review your past exams and track your progress with our AI-powered exam generator. Easily revisit and analyze previous exams to enhance future learning outcomes." />
            </Helmet>
            <Header />
            {!ROLES.isPremiumRole(user?.roles) && <PremiumDialog />}
            <div dir={isRTL ? "rtl" : "ltr"}>
                <div style={{ ...paddingPageStyle, padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h4" gutterBottom>
                        {languageData?.personalization?.title || 'Personalization'}
                    </Typography>

                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        sx={{
                            mb: 3,
                            width: '100%',
                            borderBottom: 1,
                            borderColor: 'divider'
                        }}
                        centered
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab
                            label={languageData?.personalization?.questions_tab || "Questions"}
                            sx={{ textTransform: 'none' }}
                        />
                        <Tab
                            label={languageData?.personalization?.resources?.tab || "Resources"}
                            sx={{ textTransform: 'none' }}
                        />
                    </Tabs>

                    <div style={{ width: '90%', margin: 'auto', paddingBottom: '40px' }}>
                        {activeTab === 0 ? (
                            <PastQuestions
                                openSnackBar={openSnackBar}
                                languageData={languageData}
                            />
                        ) : (
                            <Resources
                                openSnackBar={openSnackBar}
                                languageData={languageData}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Personalization;