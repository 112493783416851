import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>StudyWise | Privacy Policy</title>
                <meta name="description" content="Your privacy is important to us. Learn more about how we handle your data with our comprehensive privacy policy, ensuring your information is secure while using our AI-powered exam generator." />
            </Helmet>
            <Grid container spacing={2} sx={{ padding: '50px' }}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center" sx={{ fontWeight: "bolder", fontSize: "40px" }}>
                        Privacy Policy - StudyWise.io
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <h5 style={{ fontSize: "30px" }}>1. Introduction</h5>
                        <p>This Privacy Policy outlines how StudyWise.io collects, uses, and protects your information. By using our services, you consent to the data practices described in this policy.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <h5 style={{ fontSize: "30px" }}>2. Information We Collect</h5>
                        <p><strong>2.1. User-Provided Information:</strong> When you use StudyWise.io, you provide us with your name, email address, and other contact details to create and manage your account.</p>
                        <p><strong>2.2. Exam Data:</strong> We collect and store the exams you generate on our platform, including questions, answers, and other relevant data.</p>
                        <p><strong>2.3. Google User Data:</strong> To export exams to Google Forms, StudyWise.io requires access to your Google account.</p>
                        <p><strong>2.4. Data Retention Period:</strong> Exams and user data are retained permanently for personalization purposes, and are not subject to deletion. For further details, please refer to our <a href="/data-retention" target="_blank">Data Retention Policy.</a></p>
                        <p><strong>2.5. Third-Party Tools:</strong> We use third-party tools, such as AI models, to enhance our services and analyze exam data for improvements.</p>
                        <p><strong>2.6. Technical Data:</strong> We may collect technical data about your device and software, including browser type, operating system, IP address, and usage data to improve our services.</p>
                        <p><strong>2.7. Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to monitor how users interact with our services, which helps us enhance user experience.</p>
                        <p><strong>2.8. User Consent:</strong> By providing your information, you consent to the collection and use of your data as outlined in this Privacy Policy.</p>
                        <p><strong>2.9. Data Processing:</strong> We process your personal data based on your consent, contractual necessity, and legitimate interests.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <h5 style={{ fontSize: "30px" }}>3. How We Use Your Information</h5>
                        <p><strong>3.1. Exam Generation:</strong> We use the information you provide to create and customize exams based on your preferences.</p>
                        <p><strong>3.2. Google Integration:</strong> Your Google account data is used exclusively for exporting exams to Google Forms.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <h5 style={{ fontSize: "30px" }}>4. Data Security</h5>
                        <p><strong>4.1. Security Measures:</strong> We implement reasonable administrative, technical, and physical security measures to protect your personal information from unauthorized access and disclosure.</p>
                        <p><strong>4.2. Third-Party Integration:</strong> StudyWise.io integrates with third-party tools to provide our services. Please review our data usage policy <a href="https://developers.google.com/terms/api-services-user-data-policy">here</a> for more information on how your data is handled when integrating with Google services.</p>
                        <p><strong>4.2. Data Breach Procedures:</strong> In the event of a data breach, we will notify affected users and relevant authorities as required by law.</p>
                        <p><strong>4.3. Data Minimization:</strong> We only collect and retain personal data that is necessary for the purposes outlined in this Privacy Policy.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <h5 style={{ fontSize: "30px" }}>5. Sharing Your Information</h5>
                        <p><strong>5.1. We may share your information with service providers who assist us in delivering our services as needed to perform their functions.</strong></p>
                        <p><strong>5.2. Third-Party Sharing:</strong> We may share data with third-party tools, including AI models, to improve and optimize our exam generation services.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <h5 style={{ fontSize: "30px" }}>6. Your Choices</h5>
                        <p>You have the right to access, correct, or delete your personal information. You can also opt-out of receiving promotional communications from us.</p>
                        <p><strong>6.1. Data Retention:</strong> We retain your personal data only as long as necessary to provide our services and comply with legal obligations.</p>
                        <p><strong>6.2. Right to Withdraw Consent:</strong> You have the right to withdraw your consent at any time, which will not affect the lawfulness of processing based on consent before its withdrawal.</p>
                        <p><strong>6.3. Data Portability:</strong> You have the right to request a copy of your personal data in a structured, commonly used, and machine-readable format.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <h5 style={{ fontSize: "30px" }}>7. Changes to this Privacy Policy</h5>
                        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We reserve the right to modify this Privacy Policy at any time.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <h5 style={{ fontSize: "30px" }}>8. Contact Us</h5>
                        <p>If you have any questions regarding this Privacy Policy or how we handle your data, please contact us at <a href="mailto:support@studywise.io">support@studywise.io</a>.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <h5 style={{ fontSize: "30px" }}>9. Limitation of Liability</h5>
                        <p><strong>9.1. Disclaimer of Warranties:</strong> StudyWise.io provides its services on an "as is" and "as available" basis. We do not warrant that the services will be uninterrupted, secure, or error-free. Your use of the services is at your own risk.</p>
                        <p><strong>9.2. Limitation of Liability:</strong> In no event shall StudyWise.io, its affiliates, or its licensors be liable for any indirect, incidental, special, consequential, or punitive damages.</p>
                        <p><strong>9.3. User Responsibility:</strong> You are responsible for your use of the services and for any consequences thereof.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <h5 style={{ fontSize: "30px" }}>10. Ownership of Data</h5>
                        <p><strong>10.1. User Data Ownership:</strong> All data generated or provided by users while using StudyWise.io remains the property of StudyWise.io.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <h5 style={{ fontSize: "30px" }}>3. Information Sharing and Disclosure</h5>
                        <p><strong>3.1. Third-Party Services:</strong> We may share your information with trusted third-party service providers to assist us in delivering our services, in compliance with our privacy policy.</p>
                        <p><strong>3.2. Legal Compliance:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p>By using StudyWise.io, you agree to the terms outlined in this Privacy Policy.</p>
                        <p>StudyWise | <a href="https://studywise.io">www.studywise.io</a></p>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default PrivacyPolicy;
