import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { withMenuPages } from './MenuLinks';
import { Add, ExpandLess, ExpandMore, Menu as MenuIcon } from '@mui/icons-material';
import { useLanguage } from '../../contexts/languageContext';
import { getMenuLinks } from './MenuLinks';
import { useUser } from '../../hooks/useUser';
import { goToStore, isMobile } from '../../utils';
import { useDebounce } from '../../hooks/useDebounce';
import ROLES from '../../utils/roles';
import Logo from '../../assets/logo-horizonal.png'
import { colors } from '../../utils/customTheme';
import AvatarProfile from '../AvatarProfile';

const drawerWidth = 180;

const drawerStyle = {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
    }
};

const menuLinkStyle = {
    width: '100%',
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
};

const selectedMenuStyle = {
    backgroundColor: colors.lightblue,
    borderRight: `4px solid ${colors.main}`,
};

const createButtonStyle = {
    width: '90%',
    display: 'flex',
    fontSize: '1rem',
    backgroundColor: colors.main, // Your primary color
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '50px', // Rounded corners for a modern look
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow for elevation effect
    '&:hover': {
        backgroundColor: colors.secondary, // Darken the button on hover
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', // More prominent shadow on hover
    },
};

const createButtonStyleIcon = {
    fontSize: '1.2rem',
};

const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Horizontally centers the button
    alignItems: 'center', // Vertically centers the button
    width: '100%', // Full width of the parent container
    marginTop: '20px', // Optional: space from other elements above
};

const dashboards = [
    { label: 'Users', path: '/dashboard' },
    { label: 'Orgs', path: '/dashboard/org' },
    { label: 'Emails', path: '/dashboard/emails' },
    { label: 'Subjects', path: '/dashboard/subjects' },
    { label: 'Skills', path: '/dashboard/skills' },
    { label: 'Affiliates', path: '/dashboard/affiliates' },
]
function DesktopMenu() {
    const location = useLocation();
    const navigate = useNavigate();
    const userContext = useUser();
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [openMenu, setOpenMenu] = useState(false);
    const [dashboardsOpen, setDashboardsOpen] = useState(false);
    const [admin, setAdmin] = useState(false);
    const isAdminChecked = useRef(false);
    const debouncedIsAdmin = useDebounce(() => userContext.isAdmin().then(res => {
        setAdmin(res)
        isAdminChecked.current = true
    }), 2000);

    useEffect(() => {
        if (!userContext?.user) {
            setAdmin(false)
            isAdminChecked.current = false
            return;
        }
        if (!isAdminChecked.current && !admin && withMenuPages.includes(window.location.pathname)) {
            debouncedIsAdmin();
        }
    }, [userContext?.user, window.location.pathname]);

    useEffect(() => {
        setSelectedMenuItem(location.pathname);
    }, [location.pathname]);

    const { languageData } = useLanguage();
    const links = useMemo(() => getMenuLinks(languageData, {
        isTeacher: userContext?.user?.roles?.some(role => ROLES.teacherRoles.includes(role)),
        isStudent: userContext?.user?.idm_data?.isstudent == "Yes" || userContext?.user?.identity_provider_payload?.idm_data?.isstudent == "Yes",
        fromOrg: !!userContext?.user?.org_id,
    }), [languageData, userContext?.user])

    const handleMenuClick = useCallback((clickCallback, ...args) => () => {
        clickCallback(args);
        setOpenMenu(false); // Close the drawer
    }, []);

    return (
        withMenuPages.some(menuLink => window.location.pathname.startsWith(menuLink)) && (
            <>
                {(!openMenu && isMobile) && (
                    <div style={{ position: 'fixed', top: isMobile ? 6 : 10, left: isMobile ? 6 : 10, zIndex: 1300 }}>
                        <IconButton
                            color="primary"
                            onClick={() => setOpenMenu(!openMenu)}
                        >
                            <MenuIcon style={{ fontSize: 30 }} />
                        </IconButton>
                    </div>
                )}
                <Drawer
                    open={openMenu}
                    onClose={() => setOpenMenu(false)}
                    variant={isMobile ? "temporary" : "permanent"}
                    anchor='left'
                    sx={drawerStyle}
                >
                    {/* <div onClick={() => navigate('/history')} style={{ padding: 15, paddingBottom: 0 }}>
                        <img src={Logo} alt="Logo" style={{ cursor: 'pointer', width: '100%', height: 'auto' }} />
                    </div> */}

                    <AvatarProfile />

                    <Box style={buttonContainerStyle}>
                        <Button
                            style={createButtonStyle}
                            onClick={handleMenuClick(() => navigate('/exam'))}
                            startIcon={<Add style={createButtonStyleIcon} />}
                        >
                            {languageData?.menu?.exam}
                        </Button>
                    </Box>

                    <List>
                        {links.map((item, index) => (
                            <ListItemButton
                                key={index}
                                style={{
                                    ...(selectedMenuItem === item.path ? selectedMenuStyle : {}),
                                }}
                            >
                                <Link to={item.path} target={item.target} style={menuLinkStyle} onClick={handleMenuClick(setSelectedMenuItem, item.path)}>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        {React.cloneElement(item.icon, { style: { fontSize: '1.1rem' } })}
                                    </span>
                                    <ListItemText
                                        primaryTypographyProps={{ ...(selectedMenuItem === item.path ? { fontWeight: 'bold' } : ''), fontSize: '14px', lineHeight: 1.2 }}
                                        primary={item.title}
                                    />
                                </Link>
                            </ListItemButton>
                        ))}
                        {admin && (
                            <>
                                <ListItemButton onClick={() => setDashboardsOpen(!dashboardsOpen)}>
                                    <ListItemText
                                        primary='Dashboards'
                                        primaryTypographyProps={{ fontSize: '14px', lineHeight: 1.2 }}
                                    />
                                    {dashboardsOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={dashboardsOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding sx={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                        {dashboards.map((item, index) => (
                                            <ListItemButton key={index} onClick={handleMenuClick(() => navigate(item.path))}>
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: '14px', lineHeight: 1.2 }}
                                                    primary={item.label}
                                                />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            </>
                        )}
                    </List>

                    <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', width: '100%', textAlign: 'center' }}>
                        {!userContext?.user?.roles.includes(ROLES.UNLIMITED.label) && (
                            <Box
                                sx={{
                                    color: colors.main,
                                    backgroundColor: colors.lightblue,
                                    p: 1,
                                    borderRadius: '8px',
                                    width: '90%',
                                    textAlign: 'center',
                                    display: 'inline-block',
                                }}
                            >
                                {userContext?.user?.exams_left != undefined && (
                                    <Typography variant="body2" style={{ marginBottom: '10px' }}>
                                        {languageData?.menu?.exams_left}
                                        <strong>{userContext.user.exams_left}</strong>
                                    </Typography>
                                )}
                                <Button
                                    onClick={handleMenuClick(() => goToStore(navigate))}
                                    variant="contained"
                                    size="small"
                                >
                                    {languageData?.menu?.premium_btn_label}
                                </Button>

                            </Box>
                        )}
                    </div>

                </Drawer >
            </>
        )
    );
}

export default DesktopMenu;
