import React, { useMemo, useState } from 'react';
import { Typography, Button, TextField, Box, Grid, InputAdornment, Card, CardContent } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import { EMPTY_STRING, isMobile } from '../../utils';
import { useLanguage } from '../../contexts/languageContext';
import { IdmMoeILButton } from '../../components/IdmMoeILButton';
import SSO from '../../utils/ssoTypes';

const Identification = ({ title, settings, onStart, ssoType = null, authorAuthProvider = null }) => {
  const [formData, setFormData] = useState({
    fullname: EMPTY_STRING,
  });
  const [showNameInput, setShowNameInput] = useState(false);
  const { isRTL, languageData } = useLanguage();
  const quizLanguageData = languageData?.quiz;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStartClick = () => {
    if (formData.fullname.trim() === EMPTY_STRING) {
      alert('Full Name is required.');
    } else {
      onStart(formData);
    }
  };

  const toggleNameInput = () => {
    setShowNameInput((prev) => !prev);
  };

  const isStartButtonDisabled = formData.fullname.trim() === EMPTY_STRING;
  const ssoMoeIl = ssoType === SSO.types.MoeIL || authorAuthProvider === SSO.types.MoeIL;
  const styles = useMemo(() => {
    return {
      studentEntranceBtn: {
        ...(isMobile ? {} : { maxWidth: '200px' }),
        margin: isMobile ? '9vh auto 2vh auto' : '8vh 0 0 0',
      },
      card: {
        maxWidth: '40rem',
        margin: 'auto',
        padding: '20px',
        paddingTop: ssoMoeIl ? '2vh' : '10vh',
      },
    };
  }, [isMobile, ssoMoeIl]);
  const hideNameLogin = settings?.disable_name_login == true || (ssoMoeIl && settings?.disable_name_login === undefined)

  return (
    <div dir={isRTL ? 'rtl' : 'ltr'} >
      {ssoMoeIl && <IdmMoeILButton buttonLabel="היסטוריית מבחנים" buttonProps={{ sx: styles.studentEntranceBtn }} />}
      <Box sx={styles.card}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                <Typography variant={isMobile ? 'h5' : 'h4'} gutterBottom>
                  {title ?? ''}
                </Typography>
              </Grid>
              {ssoMoeIl && <Grid item xs={12}>
                <IdmMoeILButton buttonLabel="כניסת תלמידים למבחן באמצעות הזדהות אחידה" redirectUrl={window.location.href} />
              </Grid>}
              {!hideNameLogin && (
                <>
                  <Grid item xs={12}>
                    <Button
                      onClick={toggleNameInput}
                      variant="outlined"
                      fullWidth
                    >
                      {quizLanguageData?.identification?.name_entry ?? 'Enter with Name or Email'}
                    </Button>
                  </Grid>
                  {showNameInput && (
                    <Grid item xs={12}>
                      <TextField
                        label={quizLanguageData?.full_name ?? 'Full Name'}
                        name="fullname"
                        value={formData.fullname}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  {showNameInput && (
                    <Grid item xs={12}>
                      <Button
                        onClick={handleStartClick}
                        variant="contained"
                        color="primary"
                        disabled={isStartButtonDisabled}
                        fullWidth
                      >
                        {quizLanguageData?.identification?.start_label ?? 'Start Exam'}
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
        <SettingsDetailsCard settings={settings} quizLanguageData={quizLanguageData} />
      </Box>
    </div>
  );
};

export default Identification;


function SettingsDetailsCard({ settings, quizLanguageData }) {
  const settingsToDisplay = ['start_time', 'end_time', 'show_results', 'limit_to_one_response', 'duration'];

  return (
    <Box sx={{ marginTop: '20px' }}>
      {settingsToDisplay.some((key) => settings?.[key] !== undefined && settings?.[key] !== null && settings?.[key] !== false) && (
        <Card>
          <CardContent sx={{ p: 3 }}>
            <Grid container spacing={1} alignItems="center" sx={{ p: 1 }}>
              {settingsToDisplay.map((key) => {
                const value = settings?.[key];
                if (value !== undefined && value !== null && value !== false) {
                  const isDate = key === 'start_time' || key === 'end_time';
                  let displayValue = value;

                  if (isDate) {
                    displayValue = new Date(value).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false, });
                  } else if (key === 'duration' && typeof value === 'number') {
                    // Assuming duration is in minutes, formatting it as "X minutes"
                    quizLanguageData.identification.duration = quizLanguageData.identification.duration.replace("X", value)
                    displayValue = ""
                  } else {
                    displayValue = ""
                  }
                  const iconGridSize = isMobile ? 2 : 1;
                  return (
                    <Grid container key={key} spacing={1} alignItems="center" sx={{ p: 1 }}>
                      <Grid item xs={iconGridSize}>
                        <BeenhereIcon color="primary" />
                      </Grid>
                      <Grid item xs={12 - iconGridSize}>
                        <Typography variant="body1">
                          <strong>
                            {quizLanguageData?.identification?.[key] ?? key}
                          </strong>
                          {` ${displayValue}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }
                return null; // Skip rendering if the value is false, undefined, or null
              })}
            </Grid>
          </CardContent>
        </Card>
      )}
    </Box>
  );
}