import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid, Stack, Typography, Button, Card, Divider } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { websiteLinks } from '../utils/configs';
import { renderFeatures } from '../utils/configs';
import { useUser } from '../hooks/useUser';

const PremiumDialog = () => {
  const premiumContent = {
    discountedPrice: "13",
    link: websiteLinks.PRO,
    buttonText: "Upgrade Now",
  };

  const { user } = useUser()
  const [openDialog, setOpenDialog] = useState(false);
  const { premiumFeatures } = renderFeatures("TEACHER"); // default

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    handleOpen();
  }, []);

  return (
    <Dialog open={openDialog} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Grid container spacing={3}>
          {/* Left: List of Features in a Card */}
          <Grid item xs={12} sm={6}>
            <Card style={{ backgroundColor: '#f7f0ff', padding: '20px' }}>
              <Stack spacing={2}>
                <Typography variant="h6" gutterBottom>
                  Features You'll Unlock:
                </Typography>
                <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                  {premiumFeatures.map((feature, index) => (
                    <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                      <LockIcon sx={{ color: 'secondary.main', mr: 1 }} />
                      <Typography variant="subtitle1">{feature}</Typography>
                    </li>
                  ))}
                </ul>
              </Stack>
            </Card>
          </Grid>

          {/* Right: Title, Subtitle, Divider, Price, and Button */}
          <Grid item xs={12} sm={6}>
            <Stack spacing={2} p={2}>
              {/* Title */}
              <Typography variant="h5" color="primary">
                Unlock Premium Features
              </Typography>

              {/* Subtitle */}
              <Typography variant="subtitle1" color="textSecondary">
                Take your experience to the next level with exclusive tools.
              </Typography>

              {/* Divider */}
              <Divider style={{ width: '100%' }} />

              {/* Prices and the Cancel Anytime text */}
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h4" align="center" color="primary">
                  ${premiumContent.discountedPrice}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  / month
                </Typography>
              </Stack>

              {/* Full-width Upgrade button */}
              <div style={{ marginTop: '20px', textAlign: 'center', width: '100%' }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    window.open(premiumContent.link(user?.email));
                  }}
                >
                  {premiumContent.buttonText}
                </Button>
              </div>

              {/* "You can cancel anytime" with V Icon */}
              <Stack direction="row" alignItems="center" spacing={1} justifyContent="center">
                <CheckCircleIcon sx={{ fontSize: 16, color: 'grey.500' }} />
                <Typography variant="body2" color="textSecondary">
                  You can cancel anytime
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PremiumDialog;
