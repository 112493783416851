import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Grid } from '@mui/material';
import SubjectList from './SubjectList';
import ExamHubList from './ExamHubList';
import Header from '../../components/Header';
import PreviewDrawer from './PreviewDrawer';
import { useHub } from '../../hooks/useHub';
import { isMobile, links } from '../../utils';
import { useLanguage } from '../../contexts/languageContext';
import { useSubjects } from '../../contexts/SubjectsContext';
import { useNavigate } from 'react-router-dom';
import Filters, { FilterKeys } from '../../components/Filters/Filters';
import { applyHubFilters } from '../../components/Filters/filtersUtils';
import useGoogleAds from '../../components/GoogleAds';

const Hub = () => {
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedExamId, setSelectedExamId] = useState(null);
    const [filteredExams, setFilteredExams] = useState([]);
    const navigate = useNavigate();
    const { subjects } = useSubjects();
    const { hub, fetchHub } = useHub();
    const { isRTL, languageData, language } = useLanguage();
    const { VerticalBanner } = useGoogleAds();
    const examId = new URLSearchParams(window.location.search).get('id');

    useEffect(() => {
        if (subjects)
            fetchHub().then(res => setFilteredExams(res))
    }, [subjects]);

    useEffect(() => {
        if (examId)
            handleOpenPreviewDialog(examId);
    }, [examId])

    const handleSubjectSelect = (subject) => {
        setSelectedSubject(subject);
        setFilteredExams(hub?.filter((exam) => (exam.subject?.labels[language] === subject?.labels[language])))
    };

    const handleOpenPreviewDialog = (examId) => {
        setOpenDialog(true);
        setSelectedExamId(examId);
        const params = new URLSearchParams(window.location.search);
        params.set('id', examId);
        navigate(`?${params.toString()}`);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedExamId(null);
        const params = new URLSearchParams(window.location.search);
        params.delete('id');
        navigate(`?${params.toString()}`);
    };

    const handleUpvoteClick = (examId) => {
        const index = filteredExams.findIndex((exam) => exam.exam_id === examId);
        if (index !== -1) {
            const updatedExam = { ...filteredExams[index] };
            updatedExam.upvotes_count += 1;
            filteredExams[index] = updatedExam;
        }
    };

    const OtherSubject = useMemo(() => ({ labels: { en: "Other", he: "אחר", ar: "آخر" }, cover: links.LOGO }), []);
    const filteredSubjects = useMemo(() => {
        if (!hub) return [];
        const subjectsWithExams = [...new Set(hub?.flatMap((exam) => exam?.subject?.labels[language])?.filter(Boolean))];
        return subjects?.filter((subject) => subjectsWithExams?.includes(subject?.labels[language])).concat([OtherSubject]);;
    }, [hub, subjects]);


    const handleFiltersChange = (filters) => {
        const exams = applyHubFilters(hub, { ...filters, subject: selectedSubject }, language);
        setFilteredExams(exams);
    };

    return (
        <>
            <Header />
            <div dir={isRTL ? 'rtl' : 'ltr'} style={{ padding: '10px' }}>
                <Grid container spacing={3} justifyContent={'center'} gap={2}>
                    <Grid md={1}>
                        <VerticalBanner />
                    </Grid>
                    <Grid item xs={12} md={9} xl={8} style={{ padding: '16px 16px 0px 16px' }}>
                        {!selectedSubject && (
                            <>
                                <Alert
                                    severity="info"
                                    sx={{
                                        width: isMobile ? 'auto' : 'max-content',
                                        marginY: '10px',
                                        marginX: 'auto',
                                        direction: isRTL ? 'rtl' : 'ltr',
                                        textAlign: 'center'
                                    }}
                                >
                                    {languageData?.hub?.title}
                                </Alert>
                                <SubjectList subjects={filteredSubjects} exams={hub} onSubjectSelect={handleSubjectSelect} />
                            </>
                        )}
                    </Grid>
                    <Grid md={1}>
                        <VerticalBanner />
                    </Grid>
                    <Grid item xs={12} md={10}>
                        {selectedSubject && (
                            <>
                                <Filters onFilterChange={handleFiltersChange} filters={[FilterKeys.DIFFICULTY, FilterKeys.LANGUAGE]} />
                                <ExamHubList
                                    examHubItems={filteredExams}
                                    handleUpvote={handleUpvoteClick}
                                    onBack={() => handleSubjectSelect(null)}
                                    onOpen={handleOpenPreviewDialog}
                                    languageData={languageData?.hub}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            </div>
            {openDialog && selectedExamId && <PreviewDrawer open={openDialog} handleClose={handleCloseDialog} examId={selectedExamId} />}
        </>
    );
};

export default Hub;
