import React, { useEffect, useMemo } from 'react';
import { bloomsQuestions, questionsTypesOptions, defaultQuestion, questionsType, bloomTypes } from '../../utils/examOptions';
import { DeleteOutlineRounded, Add as AddIcon } from '@mui/icons-material';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Button, Typography, TextField } from '@mui/material';
import './QuestionsTable.css';
import { useDebounce } from '../../hooks/useDebounce';
import ROLES from '../../utils/roles';
import { useLanguage } from '../../contexts/languageContext';
import CustomSelect from '../CustomSelect';
import { isMobile } from '../../utils';
import { inputTypes } from '../../utils/configs';

const defaultMaxQuestions = 10
const tableHeaderCellStyle = isRTL => ({ paddingY: 1, textAlign: isRTL ? 'right' : 'left' })
const tableHeaderEdgeCellStyle = isRTL => ({ ...tableHeaderCellStyle(isRTL), width: '120px' })
const tableCellStyle = { padding: 1 }
const defaultQuestions = [
    { type: questionsType.multiple, bloomType: bloomTypes.knowledge, quantity: 3 },
    { type: questionsType.open, bloomType: bloomTypes.comprehension, quantity: 2 },
]
function QuestionsTable({ remixQuestions, onChange, userRoles, examType }) {
    /** @type {[{type: string, bloomType: string}[], Function]} */
    const [questions, setQuestions] = React.useState(remixQuestions ?? defaultQuestions);
    const debouncedOnChange = useDebounce(onChange, 500);
    const { languageData, isRTL } = useLanguage();
    const maxQuestions = useMemo(() => getMaxQuestionsByRole(userRoles, examType), [userRoles, examType]);
    const totalQuantity = useMemo(() => questions.reduce((sum, q) => sum + q.quantity, 0), [questions]);

    useEffect(() => {
        if (remixQuestions) {
            const groupedQuestions = remixQuestions.reduce((acc, q) => {
                const existing = acc.find(item => item.type === q.type && item.bloomsType === q.bloomsType);
                if (existing) {
                    existing.quantity += q.quantity || 1;
                } else {
                    acc.push({ ...q, quantity: q.quantity || 1 });
                }
                return acc;
            }, []);

            setQuestions(groupedQuestions);
        }
    }, [remixQuestions]);


    useEffect(() => {
        // when chaning exam types the questions are saved, we reset only for unseen
        if (examType === inputTypes.unseen && questions.length > maxQuestions) {
            setQuestions(questions.slice(0, maxQuestions));
        }
    }, [examType]);

    useEffect(() => {
        const expandedQuestions = questions.flatMap(q => Array(q.quantity).fill({ type: q.type, bloomType: q.bloomType }));
        debouncedOnChange(expandedQuestions);
    }, [questions]);

    const questionOptions = useMemo(() => {
        const options = languageData?.questionsTypesOptions ?? questionsTypesOptions
        if (examType !== inputTypes.math)
            return options.filter(option => option.value !== questionsType.graph)
        return options
    }, [languageData, examType]);

    const addQuestion = () => {
        if (totalQuantity >= maxQuestions) return;
        setQuestions(prevQuestions => [...prevQuestions, { ...defaultQuestion, quantity: 1 }]);
    };

    const updateQuestion = (questionToUpdateIndex, field, value) => {
        setQuestions(questions.map((question, index) =>
            index === questionToUpdateIndex ? { ...question, [field]: value } : question
        ));
    };

    const updateQuantity = (index, value) => {
        let newQuantity = Math.max(1, parseInt(value) || 1);
        const newTotalQuantity = totalQuantity - questions[index].quantity + newQuantity;
        if (newTotalQuantity > maxQuestions) return;
        updateQuestion(index, 'quantity', newQuantity);
    };

    const removeQuestion = (questionToRemoveIndex) => {
        setQuestions(prevQuestions => prevQuestions.filter((_, index) => index !== questionToRemoveIndex));
    };

    return (
        <Paper id="choices-manager" sx={{ padding: 2 }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" style={{ marginBottom: 2, marginLeft: 3 }}>
                        {languageData?.questions_table?.total_questions + totalQuantity}
                    </Typography>
                    {totalQuantity > maxQuestions && <Typography variant="caption" color="error" sx={{ marginBottom: 2, marginLeft: 3, fontWeight: 600 }}>
                        {languageData?.questions_table?.max_questions + ` (${maxQuestions})`}
                    </Typography>}
                </div>
                <Button variant="contained" onClick={addQuestion} sx={{ marginBottom: 2, borderRadius: '50%', minWidth: 0, height: isMobile ? '30px' : '40px', width: isMobile ? '30px' : '40px' }}>
                    <AddIcon />
                </Button>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={tableHeaderEdgeCellStyle(isRTL)}>{languageData?.questions_table?.quantity}</TableCell>
                            <TableCell sx={tableHeaderCellStyle(isRTL)}>{languageData?.questions_table?.question_type}</TableCell>
                            <TableCell sx={tableHeaderCellStyle(isRTL)}>{languageData?.questions_table?.question_level}</TableCell>
                            <TableCell sx={tableHeaderEdgeCellStyle(isRTL)}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questions.map((question, questionsIndex) => (
                            <TableRow key={questionsIndex}>
                                <TableCell sx={{ ...tableCellStyle, textAlign: 'center' }}>
                                    <TextField
                                        type="number"
                                        value={question.quantity}
                                        onChange={(e) => updateQuantity(questionsIndex, e.target.value)}
                                        inputProps={{ min: 1, max: maxQuestions }}
                                        size="small"
                                        sx={{ width: '60px' }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <CustomSelect
                                        options={questionOptions}
                                        value={question.type}
                                        onChangeHandler={(e) => updateQuestion(questionsIndex, 'type', e.target.value)}
                                        firstDisabledItem={languageData?.questions_table?.select_type}
                                        selectProps={{ inputProps: { sx: { padding: '8px 14px' } } }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <CustomSelect
                                        options={languageData?.bloomsQuestions ?? bloomsQuestions}
                                        value={question.bloomType}
                                        onChangeHandler={(e) => updateQuestion(questionsIndex, 'bloomType', e.target.value)}
                                        firstDisabledItem={languageData?.questions_table?.select_level}
                                        selectProps={{ inputProps: { sx: { padding: '8px 14px' } } }}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...tableCellStyle, textAlign: 'center' }}>
                                    <IconButton onClick={() => removeQuestion(questionsIndex)}>
                                        <DeleteOutlineRounded color="error" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </Paper >
    );
}

export default QuestionsTable;

function getMaxQuestionsByRole(userRoles, examType) {
    if (examType === inputTypes.unseen) {
        return 10
    }
    else if (userRoles && (userRoles.includes(ROLES.PREMIUM.label) || userRoles.includes(ROLES.PRO.label) || userRoles.includes(ROLES.UNLIMITED.label))) {
        return 15
    } else {
        return defaultMaxQuestions
    }
}