import api from "./api";
import { processResponsePromise } from "./utils";

export async function uploadPastExams(pastExams) {
    return processResponsePromise(api.post(`/personalization/upload`, { pastExams }));
}

export async function uploadResource(resourceData) {
    return processResponsePromise(api.post(`/personalization/resource`, resourceData));
}

export async function getAllResources() {
    return processResponsePromise(api.get(`/personalization/resources`));
}

export async function getResourceById(resourceId) {
    return processResponsePromise(api.get(`/personalization/resources/${resourceId}`));
}

export async function updateResource(resourceId, title) {
    return processResponsePromise(api.put(`/personalization/resources/${resourceId}`, { title }));
}

export async function deleteResource(resourceId) {
    return processResponsePromise(api.delete(`/personalization/resources/${resourceId}`));
}