import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
    Typography,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    TextField,
    Box,
    LinearProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import ApproveModal from '../../components/ApproveModal';
import FileUploadHandler from '../../components/FileUploadHandlerForExam';
import { uploadResource, getAllResources, deleteResource, updateResource } from '../../requests/personalization';
import { generateId } from '../../utils';
import { useLanguage } from '../../contexts/languageContext';
import { useUser } from '../../hooks/useUser';

const RESOURCE_LIMIT = 20; // Add constant for limit
const RESOURCE_TEXT_LIMIT = 20000
const Resources = ({ openSnackBar, languageData }) => {
    const { isPremium } = useUser();
    const [resources, setResources] = useState([]);
    const [openTitleDialog, setOpenTitleDialog] = useState(false);
    const [selectedFileData, setSelectedFileData] = useState(null);
    const [loading, setLoading] = useState(false);
    const clearFilesRef = useRef(null)
    const progress = useMemo(() => (resources.length / RESOURCE_LIMIT) * 100, [resources.length]);

    // Fetch resources on component mount
    useEffect(() => {
        fetchResources();
    }, []);

    const fetchResources = async () => {
        try {
            setLoading(true);
            const response = await getAllResources();
            const ResourceList = response.resources;

            setResources(ResourceList.map(resource => ({
                id: resource._id,
                name: resource.title,
                content: resource.text
            })));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching resources:', error);
            openSnackBar(SnackbarTypes.FETCH_FAILED.field);
        }
    };

    const handleFileSelection = (fileContent) => {
        if (!fileContent) {
            console.log('No file content received');
            return;
        }

        // Store the text content temporarily
        setSelectedFileData({ text: fileContent });
        setOpenTitleDialog(true);
    };

    const handleConfirmUpload = async (title) => {
        if (!selectedFileData?.text) {
            console.log('No text data available');
            return;
        }

        try {
            const resourceData = {
                resource: {
                    text: selectedFileData.text,
                    title: title || 'Untitled Resource'
                }
            };

            const uploadResponse = await uploadResource(resourceData);
            setResources(prevResources => [
                ...prevResources,
                { name: title, content: selectedFileData.text, id: uploadResponse?._id ?? generateId() }
            ]);

            // Reset states
            setSelectedFileData(null);
            openSnackBar('upload_success');
            handleClearFiles();
        } catch (error) {
            console.error('Error uploading resource:', error);
            openSnackBar('upload_failed');
        }
    };

    const handleEdit = async (resourceId, newName) => {
        try {
            setResources(prevResources => prevResources.map(s =>
                s.id === resourceId ? { ...s, name: newName } : s
            ));
            await updateResource(resourceId, newName)
            openSnackBar(SnackbarTypes.SUBMIT_SUCCESS.field);
        } catch (error) {
            console.error('Error updating resource:', error);
            openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
        }
    };

    const handleDelete = async (resourceId) => {
        try {
            await deleteResource(resourceId);
            setResources(resources.filter(s => s.id !== resourceId));
            openSnackBar(SnackbarTypes.SUBMIT_SUCCESS.field);
        } catch (error) {
            console.error('Error deleting resource:', error);
            openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
        }
    };

    const handleClearFiles = () => {
        if (clearFilesRef.current) {
            clearFilesRef.current();
        }
    }

    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                <Typography variant="subtitle1" gutterBottom>
                    {languageData?.personalization?.subtitle || 'Upload your resources to help the model understand your course content'}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        pb: 1,
                        fontWeight: 'bold',
                        direction: 'ltr'
                    }}
                >
                    {resources.length} / {RESOURCE_LIMIT}
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ height: 10, borderRadius: 5 }}
                />
            </div>

            {resources.length < RESOURCE_LIMIT && (
                <Box sx={{ width: '100%', mb: 4 }}>
                    <FileUploadHandler
                        onInput={(content) => {
                            handleFileSelection(content);
                        }}
                        disabled={!isPremium()}
                        maxFiles={1}
                        maxCharsLimit={RESOURCE_TEXT_LIMIT}
                        helperText={{
                            en: 'Upload your resource in PDF or PPT format',
                            he: 'העלה את הסילבוס בפורמט PDF או PPT'
                        }}
                        onClearFiles={(clearFiles) => clearFilesRef.current = clearFiles}
                    />
                </Box>
            )}

            {loading ? <CircularProgress /> :
                <ResourceList
                    resources={resources}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                />
            }
            <ResourceTitleDialog
                open={openTitleDialog}
                setOpen={setOpenTitleDialog}
                onSubmit={handleConfirmUpload}
                textLength={selectedFileData?.text?.length}
            />
        </>
    );
};

export default Resources;


function ResourceTitleDialog({ open, setOpen, onSubmit, textLength }) {
    const { languageData, isRTL } = useLanguage();
    const [titleName, setTitleName] = useState('');

    const handleConfirmUpload = () => {
        if (!titleName.trim())
            return;

        setOpen(false);
        setTitleName('');
        onSubmit(titleName);
    }

    const handleCancel = () => {
        setOpen(false);
        setTitleName('');
    }
    return (
        <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ sx: { padding: 1, direction: isRTL ? 'rtl' : 'ltr' } }}>
            <DialogTitle>
                {languageData?.personalization?.resources?.enter_title || 'Enter Resource Title'}
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label={languageData?.personalization?.resources?.placeholder || 'Resource Title'}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={titleName}
                    onChange={(e) => setTitleName(e.target.value)}
                    sx={{ mt: 2 }}
                />
                <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 1 }}>
                    {languageData?.personalization?.resources?.extracted_text || 'Extracted text length'}: {textLength || 0} {languageData?.file_uploader?.characters || "characters"}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>
                    {languageData?.personalization?.cancel || 'Cancel'}
                </Button>
                <Button
                    onClick={handleConfirmUpload}
                    variant="contained"
                    disabled={!titleName.trim()}
                >
                    {languageData?.personalization?.upload || 'Upload'}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

function ResourceList({ resources, onEdit, onDelete }) {
    const { languageData } = useLanguage()
    const [editingResource, setEditingResource] = useState(null);
    const [editedName, setEditedName] = useState('');
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const resourceIdToDelete = useRef(null);

    const handleCancel = () => {
        setEditingResource(null);
        setEditedName('');
    }

    const handleEdit = (resourceId) => {
        onEdit(resourceId, editedName);
        setEditingResource(null);
        setEditedName('');
    }

    const handleDelete = () => {
        onDelete(resourceIdToDelete.current);
        setOpenDeleteModal(false);
    }

    return (
        <>
            <Grid container spacing={3}>
                {resources.map((resource) => (
                    <Grid item xs={12} sm={6} md={4} key={resource.id}>
                        <Card>
                            <CardContent>
                                {editingResource === resource.id ? (
                                    <Box>
                                        <TextField
                                            fullWidth
                                            defaultValue={resource.name}
                                            onChange={(e) => setEditedName(e.target.value)}
                                            size="small"
                                            sx={{ mb: 1 }}
                                        />
                                        <Button
                                            variant="contained"
                                            onClick={() => handleEdit(resource.id)}
                                            disabled={!editedName}
                                            size="small"
                                            sx={{ mr: 1 }}
                                        >
                                            {languageData?.personalization?.save || 'Save'}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={handleCancel}
                                            size="small"
                                        >
                                            {languageData?.personalization?.cancel || 'Cancel'}
                                        </Button>
                                    </Box>
                                ) : (
                                    <>
                                        <Typography variant="h6" gutterBottom>
                                            {resource.name}
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <IconButton
                                                onClick={() => setEditingResource(resource.id)}
                                                size="small"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    resourceIdToDelete.current = resource.id;
                                                    setOpenDeleteModal(true);
                                                }}
                                                size="small"
                                                color="error"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <ApproveModal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onConfirm={handleDelete}
                title={languageData?.personalization?.resources?.delete_resource_title || "Confirm Deletion"}
                subtitle={languageData?.personalization?.resources?.delete_resource_message || "Are you sure you want to delete this resource? This action cannot be undone."}
                buttonLabel={languageData?.personalization?.delete || "Delete"}
            />
        </>
    )
}