import { logErrorToServer } from "../requests/error";

const intervalCallback = async (apiFunction, maxIterations, delay = 10000) => {
    return new Promise((resolve, reject) => {
        let currentIteration = 0;
        const intervalId = setInterval(async () => {
            try {
                const result = await apiFunction(); // Call the API function
 
                if (result.status !== 202)
                {
                    clearInterval(intervalId); // Clear the interval immediately upon success ot failure
                    if (result.status === 200)
                    {
                        resolve(result);
                    }
                    else
                    {
                        reject(new Error("Error during generation"));
                    }
                }
                else
                {
                    currentIteration++;
                    if (currentIteration >= maxIterations) {
                        clearInterval(intervalId); // Clear the interval if max iterations reached
                        reject(new Error('Max iterations reached'));
                    }
                    // Retry after the interval if max iterations not reached
                }
            } catch (error) {
                logErrorToServer('Error during generation', error);
                clearInterval(intervalId); // Clear the interval immediately upon error
                reject(error);
            }
        }, delay);
    });
    
};

export { intervalCallback };

