import React, { useEffect, useState } from 'react'
import { Button, TextField, Divider, Link, Box, Typography, Container, IconButton, } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import ContinueWithGoogleButton from '../components/GoogleAuth'
import ContinueWithMicrosoftButton from '../components/MicrosoftAuth'
import { HttpStatusCode } from 'axios'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { Stack } from '@mui/system'
import { useUser } from '../hooks/useUser'
import { Helmet } from 'react-helmet-async';
import api from '../requests/api'
import { handleSelectedtierIfExist, isMobile } from '../utils'
import { validateEmail, validatePassword } from '../utils/inputValidations'
import useHistory from '../hooks/useHistory'
import PrivacyAndTermsOfService from '../components/PrivacyAndTerms0fService'
import { handleRedirectAfterAuth } from '../utils/auth'
import useSnackBar from '../hooks/useSnackBar'
import { SnackbarTypes } from '../utils/snackbarTypes'
import Header from '../components/Header'
import { useLanguage } from '../contexts/languageContext'
import HebrewPrivacyAndTermsOfService from '../components/HebrewPrivacyAndTermsOfService'
import { IdmMoeILButton } from '../components/IdmMoeILButton'
import Logo from '../assets/logo-horizonal.png'
import { colors } from '../utils/customTheme'
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function Login() {
  const navigate = useNavigate()
  const userContext = useUser()
  const historyContext = useHistory()
  const { openSnackBar } = useSnackBar()
  const { languageData, preFetchLanguageData, isRTL } = useLanguage();

  useEffect(() => {
    userContext.isLoggedIn().then(res => {
      if (res) {
        handleSelectedtierIfExist()
        handleRedirectAfterAuth(navigate)
      } else {
        preFetchLanguageData()
      }
    }).catch(e => { })
  }, [])

  const [isLoading, setIsLoading] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [isFormValid, setisFormValid] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
  })
  const [showForm, setShowForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  // ################# Form Functions #################
  const handleTextFieldChange = (event) => {
    const { name, value } = event.target
    let newIsFormValid = { ...isFormValid }

    switch (name) {
      case 'email':
        newIsFormValid[name] = validateEmail(value)
        break
      case 'password':
        newIsFormValid[name] = value.length >= 6
        break
      default:
        break
    }

    setisFormValid(newIsFormValid)
  }

  const checkValidForm = (obj) => {
    const { email, password } = obj
    return email && password
  }

  // ################# Email functions #################  
  const handleEmailChange = (event) => {
    const input = event.target.value
    setEmailError(!validateEmail(input))
  }

  // ################# Password functions #################  
  const handlePasswordChange = (event) => {
    const input = event.target.value
    setPasswordError(!validatePassword(input))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const loginFailed = (type = SnackbarTypes.ERROR.field) => {
      setIsLoading(false)
      openSnackBar(type)
    }
    if (!checkValidForm(isFormValid)) {
      loginFailed(SnackbarTypes.INVALID_CREDENTIALS.field)
      return;
    }

    setIsLoading(true)
    const data = new FormData(event.currentTarget)
    const user = {
      email: data.get('email'),
      password: data.get('password'),
    }

    await api.post(`/auth/login`, { user }).then(async (response) => {
      if (response.status === HttpStatusCode.Ok) {
        setIsLoading(false)
        userContext.login(response.data)
        historyContext.resetHistory()
        // handleSelectedtierIfExist()
        handleRedirectAfterAuth(navigate, response?.data)
      }
      else {
        if (response.status === HttpStatusCode.Unauthorized)
          loginFailed(SnackbarTypes.VERIFY_EMAIL_BEFORE_LOGIN.field)
        else if (response.status === HttpStatusCode.TooManyRequests)
          loginFailed(SnackbarTypes.RATE_LIMIT_EXCEEDED.field)
        else
          loginFailed()
      }
    }).catch(err => {
      if (err.response.status === HttpStatusCode.Unauthorized) {
        const encoded_email = btoa(data.get('email'));
        navigate(`/validation/stop?e=${encoded_email}`);
      }
      else if (err.response.status === HttpStatusCode.TooManyRequests)
        loginFailed(SnackbarTypes.RATE_LIMIT_EXCEEDED.field)
      else {
        loginFailed()
      }
    })
    // headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
  }

  const handleEmailSignInToggle = () => {
    setShowForm(prevShowForm => !prevShowForm);
  };



  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Helmet>
        <title>StudyWise | Login</title>
        <meta name="description" content="Login to the AI-powered exam generator, to create high-quality, personalized exams in seconds. Effortlessly generate various question types, customize difficulty and length, and save time while boosting learning outcomes. New users? Sign up!" />
        <meta name="keywords" content="exam generator, AI-powered exams, personalized exams, question types, customize difficulty, learning outcomes" />
        <meta name="author" content="StudyWise" />
      </Helmet>
      <Header />
      <Container component='main' maxWidth='s'>
        <Box sx={{ display: 'flex', marginTop: 2, flexDirection: 'column', alignItems: 'center', direction: isRTL ? 'rtl' : 'ltr' }} >
          <img src={Logo} alt="Logo" style={{ marginBottom: '20px', width: isMobile ? '50vw' : '20vw', height: 'auto' }} />
          <Typography component='h1' variant='h5'> {languageData?.login?.title ?? 'Login'} </Typography>
          {showForm && (
            <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} >
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                type='email'
                label={languageData?.login?.email_label ?? 'Email Address'}
                name='email'
                onChange={handleEmailChange}
                error={emailError}
                onInput={handleTextFieldChange}
                helperText={emailError ? (languageData?.login?.email_helper_Text ?? 'Please enter a valid email') : ''}
                autoComplete='email'
                size='small'
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label={languageData?.login?.password_label ?? 'Password'}
                onChange={handlePasswordChange}
                type={showPassword ? 'text' : 'password'}
                onInput={handleTextFieldChange}
                error={passwordError}
                helperText={
                  passwordError ? languageData?.login?.password_helper_Text ?? 'Password must be at least 6 characters long' : ''
                }
                id='password'
                autoComplete='current-password'
                size='small'
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      sx={{ backgroundColor: 'transparent' }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 2, mb: 1 }}
                disabled={isLoading || emailError || passwordError}
              >
                {isLoading ? (
                  <CircularProgress color='inherit' size={24} />
                ) : (
                  languageData?.login?.login_btn ?? 'Login'
                )}
              </Button>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Link href='/forgot-password' variant='body2' rel="noopener noreferrer" sx={{ display: 'block', width: 'fit-content' }}>{languageData?.login?.forgot_password ?? 'Forgot Password?'}</Link>
              </Box>
              <Divider sx={{ my: 2 }}>or</Divider>
            </Box>)}
          <Stack spacing={2} sx={{ alignItems: 'center', my: 3 }}>
            <ContinueWithGoogleButton />
            <IdmMoeILButton />
            {!showForm && (
              <Button variant="outlined" sx={{ backgroundColor: 'white', color: colors.main, width: '-webkit-fill-available', }} startIcon={!isRTL ? <EmailIcon /> : null} onClick={handleEmailSignInToggle}>
                {languageData?.sign_in?.email ?? 'Sign In With Email'}
              </Button>
            )}
            <ContinueWithMicrosoftButton />
            <Link href='signup' variant='body2' sx={{ width: 'fit-content' }}> {languageData?.login?.no_account ?? "Don't have an account? Sign Up"} </Link>
          </Stack>
          {isRTL ? <HebrewPrivacyAndTermsOfService buttonText={"התחברות"} operationText={"לחיצה על"} /> : <PrivacyAndTermsOfService buttonText={"Logging In"} />}
        </Box>
      </Container>
    </>
  )
}
