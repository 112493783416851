import React, { useState, useEffect } from 'react';
import { Chip, TextField, Typography } from '@mui/material';
import EditableOpenQuestion from './EditableOpenQuestion';
import { renderTextWithBreakLines } from '../../utils';
import { useDebounce } from '../../hooks/useDebounce';

const EditableNestedQuestion = ({ languageData, questionIndex, parentIndex, nested, title, isEditing = false, onChange, gradeError, use_indicator, pointsGrid }) => {
    const [titleState, setTitleState] = useState(title);
    const debouncedTitleChange = useDebounce((title) => onChange(questionIndex, title, nested), 200);
    useEffect(() => {
        setTitleState(title);
    }, [title]);

    const handleTitleChange = (newTitle) => {
        setTitleState(newTitle);
        debouncedTitleChange(newTitle);
    }

    const handleNestedChange = (nestedIndex, nestedObject) => {
        const updatedNested = nested.map((item, index) =>
            index === nestedIndex ? { ...item, ...nestedObject } : item
        );

        if (nestedObject?.grade !== undefined) {
            const newTotalGrade = parseFloat(updatedNested.reduce((acc, question) => acc + (question.grade || 0), 0))
            onChange(questionIndex, title, updatedNested, newTotalGrade);
        } else {
            onChange(questionIndex, title, updatedNested);
        }
    }

    return (
        <div>
            {isEditing ? (
                <>
                    {/* <TextField
                        label={languageData.grade_label}
                        value={parseFloat(gradeState, 0).toFixed(2)}
                        type='number'
                        disabled
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        error={gradeError}
                    /> */}
                    <TextField
                        label={languageData.question_title}
                        value={titleState ?? ""}
                        onChange={e => handleTitleChange(e.target.value)}
                        fullWidth
                        multiline
                        variant="outlined"
                        margin="normal"
                        error={!titleState}
                    />
                    {nested.map((nestedQuestion, index) => (
                        <>
                            <Typography variant="h6" >
                                {parentIndex + "." + (index + 1) + ' - ' + nestedQuestion.grade + ' ' + languageData?.points_label}
                            </Typography>
                            <EditableOpenQuestion
                                key={index}
                                title={nestedQuestion.question}
                                languageData={languageData}
                                questionIndex={index}
                                grade={nestedQuestion.grade}
                                explanation={nestedQuestion.explanation}
                                isEditing={isEditing}
                                onChange={handleNestedChange}
                                gradeError={gradeError}
                                indicator={nestedQuestion?.indicator}
                                pointsGrid={pointsGrid}
                                use_indicator={use_indicator}
                            />
                        </>
                    ))}
                </>
            ) : (
                <>
                    <Typography variant="h6" gutterBottom sx={{ margin: '15px 0px' }}>
                        {renderTextWithBreakLines(titleState)}
                    </Typography>
                    {nested.map((nestedQuestion, index) => (
                        <div key={index} style={{ marginLeft: '20px', marginTop: '10px' }}>
                            {!use_indicator && <Chip variant="outlined"
                                label={nestedQuestion.grade + ' ' + languageData?.points_label} icon severity="info" sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}
                            />}
                            <EditableOpenQuestion
                                languageData={languageData}
                                questionIndex={index}
                                title={parentIndex + "." + (index + 1) + '. ' + nestedQuestion.question}
                                grade={nestedQuestion.grade}
                                explanation={nestedQuestion.explanation}
                                isEditing={false}
                                onChange={() => { }}
                                indicator={nestedQuestion?.indicator}
                                handleGradeChange={() => { }}
                                gradeError={false}
                                use_indicator={use_indicator}
                                pointsGrid={pointsGrid}
                            />
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default EditableNestedQuestion;
