import { reportClick, buttonsIds } from './requests/analytics'
import { LockClock, Assignment, Description, CloudUpload, Link, YouTube, Calculate, VisibilityOff, NoteAlt } from '@mui/icons-material';
import { alpha } from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { inputTypes, websiteLinks } from './utils/configs';
import { Fragment } from 'react';
import CustomLatex from './components/CustomLatex';

export const responseErrors = {
  errorText: 'something went wrong, please refresh and try again',
}

export function capitalizeFirstLetter(str) {
  return str.substring(0, 1).toUpperCase() + str.substring(1)
}
export function handleSelectedtierIfExist() {
  const selectedTier = localStorage.getItem('selectedTier');
  if (selectedTier) {
    const tier = JSON.parse(selectedTier);
    localStorage.removeItem('selectedTier');
    nevigateToSelectedTier(tier);
  }
}

export const isMobile = window.innerWidth <= 760;

export const goToStore = async (navigate) => {
  navigate('/pricing')
}

export const bookDemo = async () => {
  window.open(websiteLinks.SCHOOL(), '_blank')
}

export const orders = {
  ascending: 'asc',
  descending: 'desc'
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === orders.descending
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function nevigateToSelectedTier(link) {
  window.open(link, '_blank')
}

export function contactUs() {
  reportClick(buttonsIds.contactUs)
  let mailtoURL = isMobile
    ? 'mailto: ' + encodeURIComponent('info@studywise.io')
    : 'https://mail.google.com/mail/?view=cm&fs=1&to=info@studywise.io'

  window.open(mailtoURL, '_blank');
}
// Use this for strings that you need to send to the server
export const toSnakeCase = (inputString) => inputString.toLowerCase().replace(/\s+/g, '_');
export const fromSnakeCase = (inputString) => inputString.replace(/_/g, ' ');
export const capitalizeWords = (inputString) => {
  return inputString.replace(/_/g, ' ')
    .replace(/\b\w/g, match => match.toUpperCase());
};

export const getIcon = (field) => {
  switch (field) {
    case inputTypes.text:
      return <Description sx={{ color: alpha('#4285f4', 0.6) }} />;
    case inputTypes.topic:
      return <TipsAndUpdatesIcon sx={{ color: alpha('#0f9d58', 0.6) }} />;
    case inputTypes.uploads:
      return <CloudUpload sx={{ color: alpha('#f4b400', 0.6) }} />;
    case inputTypes.url:
      return <Link sx={{ color: alpha('#800080', 0.6) }} />;
    case inputTypes.youtube:
      return <YouTube sx={{ color: alpha('#ff0000', 0.6) }} />;
    case inputTypes.based:
      return <Assignment sx={{ color: alpha('#1a1a1a', 0.6) }} />;
    case inputTypes.uploadYourExam:
      return <Assignment sx={{ color: alpha('#000', 0.6) }} />;
    case inputTypes.math:
      return <Calculate sx={{ color: alpha('#FF0000', 0.6) }} />;
    case inputTypes.unseen:
      return <VisibilityOff sx={{ color: alpha('#8a2be2', 0.6) }} />;
    case inputTypes.blank:
      return <NoteAlt sx={{ color: alpha('#ff69b4', 0.6) }} />;
    default:
      return <LockClock sx={{ color: alpha('#aaa', 0.6) }} />;
  }
};

export const getIconColor = (field, opacity = 0.1) => {
  switch (field) {
    case inputTypes.text:
      return alpha('#4285f4', opacity);
    case inputTypes.topic:
      return alpha('#0f9d58', opacity);
    case inputTypes.uploads:
      return alpha('#f4b400', opacity);
    case inputTypes.url:
      return alpha('#800080', opacity);
    case inputTypes.youtube:
      return alpha('#ff0000', opacity);
    case inputTypes.based:
      return alpha('#1a1a1a', opacity);
    case inputTypes.uploadYourExam:
      return alpha('#000', opacity);
    case inputTypes.math:
      return alpha('#FF0000', opacity);
    case inputTypes.unseen:
      return alpha('#8a2be2', opacity);
    case inputTypes.blank:
      return alpha('#ff69b4', opacity);
    default:
      return alpha('#aaa', opacity);
  }
};

export function generateId(length = 24) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters[randomIndex];
  }

  return id;
}


export const getFontSize = (isTitleLarge = false, isSubtitleSmall = false) => {
  const baseFontSize = 16;
  const screenWidth = window.innerWidth;
  const baseScreenWidth = 1280;
  const scaleFactor = screenWidth / baseScreenWidth;

  let titleSize = 1.2;
  let subtitleSize = 1;

  // Adjust font sizes for very large screens
  if (screenWidth > 1920) {
    titleSize *= 1.3;
    subtitleSize *= 1.3;
  }

  if (isTitleLarge) {
    titleSize *= 1.5;
  }

  if (isSubtitleSmall) {
    subtitleSize *= 0.6;
  }

  const titleFontSize = baseFontSize * scaleFactor * titleSize;
  const subtitleFontSize = baseFontSize * scaleFactor * subtitleSize;

  return {
    title: `${titleFontSize / 16}rem`, // Convert pixels to rem
    subtitle: `${subtitleFontSize / 16}rem` // Convert pixels to rem
  };
};

export const EMPTY_STRING = ''

/**
 * Shuffles an array in place using the Fisher-Yates algorithm.
 * Best for large arrays
 * @param {Array} arr - The array to be shuffled.
 * @returns {Array} - The shuffled array.
 */
export const shuffleArrayInplace = (arr) => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }

  return arr
}
/**
 * Shuffles the elements of an array in a random order.
 * Returns a new array and does not modify the original array.
 * @param {Array} array - The array to be shuffled.
 * @returns {Array} - The shuffled array.
 */
export const shuffleArray = (array) => {
  return [...array].sort(() => Math.random() - 0.5);
};

export function roundToTwoDecimalPlaces(number) {
  return Math.round(number * 100) / 100;
}

export function renderTextWithBreakLines(text, withLatex = true) {
  if (text === null || text === undefined) return
  if (typeof text !== 'string')
    return text

  let splittedText = text.split('\n')

  if (splittedText[0] === '') {
    splittedText = splittedText.slice(1);
  }
  if (splittedText.length === 1)
    return withLatex ? <CustomLatex>{text}</CustomLatex> : text

  return splittedText?.map((line, index) => (
    <Fragment key={index}>
      {withLatex ? <CustomLatex>{line}</CustomLatex> : line}
      <br />
    </Fragment>
  ))
}


export const getAccuracyColor = (accuracy) => {
  if (accuracy > 70) {
    return "success";
  } else if (accuracy >= 19 && accuracy <= 69) {
    return "warning";
  } else {
    return "error";
  }
};

/**
 * Paginates text by trying to detect natural page breaks.
 * 1. First, it looks for form feed characters ("\f").
 * 2. If not found, it attempts to split by a pattern like a newline followed by "Page" and digits.
 * 3. As a final fallback, it splits the text into fixed-length chunks.
 *
 * @param {string} text - The full text to paginate.
 * @param {number} defaultCharsPerPage - The default number of characters per page if no natural breaks are found.
 * @returns {string[]} An array of page texts.
 */
export const paginateText = (text, defaultCharsPerPage = 1000) => {
  // Attempt to use form feed characters as page delimiters
  if (text.includes('\f')) {
    const pages = text.split('\f').map(page => page.trim()).filter(page => page.length > 0);
    if (pages.length > 1) {
      return pages;
    }
  }

  // Attempt to detect page breaks based on a "Page" header pattern (common in some PDFs)
  const pageBreakRegex = /\nPage\s+\d+/;
  if (pageBreakRegex.test(text)) {
    const pages = text.split(pageBreakRegex).map(page => page.trim()).filter(page => page.length > 0);
    if (pages.length > 1) {
      return pages;
    }
  }

  // Fallback: split text into chunks of defaultCharsPerPage characters
  const pages = [];
  for (let i = 0; i < text.length; i += defaultCharsPerPage) {
    pages.push(text.substring(i, i + defaultCharsPerPage));
  }
  return pages;
};

/**
 * Cuts text based on a user-selected start and end page.
 *
 * @param {string} text - The full text to cut.
 * @param {number} startPage - The 1-indexed start page.
 * @param {number} endPage - The 1-indexed end page.
 * @param {number} [defaultCharsPerPage=1000] - The default number of characters per page if needed.
 * @returns {string} The combined text from the selected pages.
 */
export const cutTextByPages = (text, startPage, endPage, defaultCharsPerPage = 1000) => {
  const pages = paginateText(text, defaultCharsPerPage);
  // Convert to zero-indexed; slice end is non-inclusive so it works correctly.
  const selectedPages = pages.slice(startPage - 1, endPage);
  return selectedPages.join('\n\n');
};



export const links = {
  LOGIN: "https://exam-generator.com/login",
  SIGNUP: "https://exam-generator.com/signup",
  STORE: "https://exam-generator.lemonsqueezy.com/",
  FREE: "https://exam-generator.com/signup",
  BASIC:
    "https://exam-generator.lemonsqueezy.com/checkout/buy/67a6317d-7eb2-4a57-a840-89bc23aa7e66",
  PRO: "https://exam-generator.lemonsqueezy.com/checkout/buy/7e806f95-ec75-4477-977c-c527ea3ae71b",
  PREMIUM:
    "https://exam-generator.lemonsqueezy.com/checkout/buy/a10980e6-607c-4121-94d7-3a5935733139",
  INSTAGRAM: "https://www.instagram.com/studywise.io/",
  LINKEDIN: "https://www.linkedin.com/company/101561249/",
  PRIVACY: "https://exam-generator.com/privacy-policy",
  DATA: "https://exam-generator.com/data-retention",
  ACCESSIBILITY: "https://exam-generator.com/accessibility",
  TOS: "https://exam-generator.com/tos",
  YOUTUBE: "https://www.youtube.com/@ExamGenerator",
  // BUG_FORM: (isHeb = false) => isHeb ? 'https://studywise.atlassian.net/jira/software/c/projects/BQ/form/2' : 'https://studywise.atlassian.net/jira/software/c/projects/BQ/form/1'
  BUG_FORM: 'https://docs.google.com/forms/d/e/1FAIpQLSdca6Ys1jNiBZ8qzocsBE6XQYGV8F_orRg6aEXeBzN-nwKmBQ/viewform',
  LOGO: "https://res.cloudinary.com/studywise/image/upload/v1726490796/qixkice52p29vezdyjjg.jpg"
};

export const gradeRanges = [
  '0-49', '50-55', '56-60', '61-65', '66-70', '71-75', '76-80', '81-85', '86-90', '91-95', '96-100'
];

export function customToFixed(num) {
  if (num === null || num === undefined || isNaN(num)) return '';
  const number = parseFloat(num);
  const numParts = number.toString().split('.');
  if (numParts.length === 1) {
    return number.toString();
  }
  const decimalPlaces = numParts[1].length;
  return decimalPlaces >= 2 ? number.toFixed(2) : (decimalPlaces === 1 ? number.toFixed(1) : number.toString());
}

import { Chip } from '@mui/material';

export const getExamStatusChip = ({ startsAt, endsAt }) => {
  const now = new Date().getTime();

  // Switch to handle different conditions
  switch (true) {
    case startsAt && new Date(startsAt).getTime() < now && new Date(endsAt).getTime() > now:
      // Exam is "Active"
      return (
        <Chip
          label="Active"
          color="success"
          sx={{ marginTop: 1 }}
        />
      );
    case endsAt && new Date(endsAt).getTime() <= now:
      // Exam has "Ended"
      return (
        <Chip
          label="Ended"
          color="error"
          sx={{ marginTop: 1 }}
        />
      );
    case startsAt && new Date(startsAt).getTime() > now:
      // Exam has "Not Started Yet"
      return (
        <Chip
          label="Not Started Yet"
          color="info"
          sx={{ marginTop: 1 }}
        />
      );
    case !startsAt || !endsAt:
      // Missing start or end time
      return (
        <Chip
          label="No Time Set"
          color="default"
          sx={{ marginTop: 1 }}
        />
      );
    default:
      return null;
  }
};


export function getDeviceType() {
  const userAgent = navigator.userAgent.toLowerCase();

  if (/android/.test(userAgent) && !/mobile/.test(userAgent)) {
    return "Tablet";
  }
  if (/android/.test(userAgent)) {
    return "Android";
  }
  if (/ipad/.test(userAgent)) {
    return "ipad";
  }
  if (/iphone|ipod/.test(userAgent)) {
    return "IOS";
  }
  if (/mobile/.test(userAgent)) {
    return "Mobile";
  }
  return "Desktop";
}