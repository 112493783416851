import React, { useState, useEffect, useRef, useMemo } from 'react'
// MUI
import {
  IconButton,
  Grid,
  Typography,
  Paper,
  Divider,
  TextField,
  Toolbar,
  Alert,
  // CircularProgress,
  Box,
  Tooltip,
  Chip,
  // Button,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PostAddIcon from '@mui/icons-material/PostAdd';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DeleteIcon from '@mui/icons-material/Delete'
// import SaveAltIcon from '@mui/icons-material/SaveAlt';
// Components
import ExamSkeleton from './ExamSkeleton'
import EditableQuestionTypeManager from './EditableQuestionTypeManager'
// import ParamsToChips from './ParamsToChips'
import Header from '../../components/Header'
import ExamDialog from '../../components/ExamPage/ExamDialog'
import ErrorMessage from '../../components/ErrorMessage'
import AddQuestionDialog from './AddQuestionDialog'
import PreviewHeader from './PreviewHeader';
import { DeleteConfirmationPopver } from '../../components/DeleteConfirmationPopover';
import PreviewButtons from './PreviewButtons'
// import { GradeModal } from './GradeModal'
// import Feedback from '../../components/Feedback'
// Utils
import { requestExamJson, saveExam } from '../../requests/exam'
import { requestModifyingQuestion, requestAddQuestion } from '../../requests/question'
import { questionsType, getQuestionTemplateByType } from '../../utils/examOptions'
import { generateId, isMobile, shuffleArray } from '../../utils'
import { SnackbarTypes } from '../../utils/snackbarTypes';
// Hooks
import { useLocation } from 'react-router-dom'
import { HttpStatusCode } from 'axios'
import { languageToCodesMap, useLanguage } from '../../contexts/languageContext'
import { useUser } from '../../hooks/useUser'
import { useDebounce } from '../../hooks/useDebounce'
import useSnackBar from '../../hooks/useSnackBar';
import useHistory from '../../hooks/useHistory'
import PreviewMenu from './PreviewMenu'
import { paddingPageStyle } from '../../utils/customTheme';
import { useLobby } from '../../contexts/LobbyContext';
import Unseen from '../../components/Unseen'
import { deleteImage, uploadImage } from '../../requests/images'
import { Helmet } from 'react-helmet-async';
import QuestionComparisonDialog from './QuestionComparisonDialog';
import { inputTypes } from '../../utils/configs';
import { getBloomLevelColor, getBloomsLabel, getBloomsLevel } from '../../utils/bloom';
import FlickeringDot from './FlickeringDot';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SchoolRegistrationWrapper from './SchoolRegisterationDialog/SchoolRegistrationWrapper';
import { logErrorToServer } from '../../requests/error';
import { SwapIndicators } from '../../utils/indicator';
import { getGoodQuestionsByExamId, getBadQuestionsByExamId, addGoodQuestion, addBadQuestion, removeBadQuestion, removeGoodQuestion } from '../../requests/integrity';
import QuestionActions from './QuestionActions';
import { MergeTypes } from '../../components/Merge/ExamList';

const PreviewPage = () => {
  const location = useLocation()
  const { isRTL, languageData, ToggleLanguage } = useLanguage()
  const { user } = useUser()
  const { history, updateExamInHistory } = useHistory()
  const lobbyContext = useLobby();
  const { openSnackBar, } = useSnackBar();
  const debouncedSaveChanges = useDebounce(saveChanges, 5000);

  const [examJson, setExamJson] = useState(null)
  const [editableExamJson, setEditableExamJson] = useState(null)
  const [isEditing, setIsEditing] = useState(false)

  const [openExamDialog, setOpenExamDialog] = useState(false)

  const [openAddQuestionDialog, setOpenAddQuestionDialog] = useState(false)
  const [newQuestionIndex, setNewQuestionIndex] = useState(null)
  const [modifyingQuestionIndex, setModifyingQuestionIndex] = useState(null);
  const [loadingUploadId, setLoadingUploadId] = useState(null)
  const [deletePopoverAnchorEl, setDeletePopoverAnchorEl] = useState(null);
  const deletePopoverPayload = useRef(null);

  const [isCompareDialogOpen, setIsCompareDialogOpen] = useState(false);
  const oldQuestionRef = useRef(null);
  const newQuestionRef = useRef(null);

  const [error, setError] = useState({ display: false, msg: '' })
  const [likedQuestions, setLikedQuestions] = useState(new Set());
  const [dislikedQuestions, setDislikedQuestions] = useState(new Set());

  useEffect(() => {
    const fetchExamJson = async () => {
      const searchParams = new URLSearchParams(location.search)
      const examId = searchParams.get('id')
      const blank = searchParams.get('blank')
      const edit = searchParams.get('edit')

      if (!examId) {
        setError({
          display: true,
          msg: languageData.preview?.errors.no_preview,
        })
        return
      }

      try {
        const data = await requestExamJson(examId)
        const fixedExam = fixExamStructure(data)
        setExamJson(fixedExam)
        const clonedExam = JSON.parse(JSON.stringify(fixedExam))
        setEditableExamJson(clonedExam)
        lobbyContext?.createdExam()

        if (fixedExam?.parameters?.language)
          ToggleLanguage(languageToCodesMap[fixedExam?.parameters?.language])
        if (blank && edit)
          setIsEditing(true)
        if (fixedExam) {
          fetchUserQuestionPreferences(fixedExam._id);
        }
      } catch (error) {
        setExamJson(null)
        setEditableExamJson(null)
        setError({
          display: true,
          msg: languageData.preview?.errors?.no_preview ?? "No preview available",
        })
      }
    }

    fetchExamJson()
    // Clean up the error message after the component unmounts
    return () => {
      setError({ display: false, msg: '' })
    }
  }, [])

  const fetchUserQuestionPreferences = async (examId) => {
    try {
      const [liked, disliked] = await Promise.all([
        getGoodQuestionsByExamId(examId),
        getBadQuestionsByExamId(examId)
      ]);
      setLikedQuestions(new Set(liked?.map(q => q.question_id)));
      setDislikedQuestions(new Set(disliked?.map(q => q.question_id)));
    } catch (error) {
      logErrorToServer(`Error fetching user question preferences: ${error}`);
    }
  };

  const insertNewQuestion = (newQuestion, grade) => {
    if (!newQuestion?._id) newQuestion._id = generateId()
    newQuestion.grade = grade ?? 0
    if (newQuestion.type === questionsType.nested) {
      newQuestion.nested_questions = handleAddNestedQuestionGrade(newQuestion, grade)
    } else if (newQuestion.type === questionsType.open && Array.isArray(newQuestion.indicator)) {
      const criteriaGrade = Math.floor(grade / newQuestion.indicator.length)
      newQuestion.indicator = newQuestion.indicator.map((ind, i) => ({
        ...ind,
        points: i < newQuestion.indicator.length - 1 ? criteriaGrade : grade - (criteriaGrade * (newQuestion.indicator.length - 1))
      }))
    }

    // Insert the new question at the specified index
    setEditableExamJson(prevExam => {
      let updatedQuestions = [...prevExam.questions];
      updatedQuestions.splice(newQuestionIndex, 0, newQuestion);
      return { ...prevExam, questions: updatedQuestions };
    });
    setNewQuestionIndex(null)
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    setEditableExamJson((prevExam) => {
      const updatedQuestions = [...prevExam.questions];
      const [removed] = updatedQuestions.splice(source.index, 1);
      updatedQuestions.splice(destination.index, 0, removed);

      const newExam = { ...prevExam, questions: updatedQuestions };
      saveChanges(newExam);
      return newExam;
    });

  };

  const addNewQuestionWithAI = (newQuestion, grade) => {
    return requestAddQuestion(newQuestion, editableExamJson, examJson?.parameters?.language).then(newQuestion => {
      if (!newQuestion) {
        openSnackBar(SnackbarTypes.ERROR.field);
        return
      }
      insertNewQuestion(newQuestion, grade);
    }).catch(e => {
      logErrorToServer(`Error adding new question: ${e}`);
    }).finally(() => {
      setNewQuestionIndex(null)
    })
  }

  /**
   * @param {{type: string, grade: number}} question 
   */
  const handleAddQuestion = async (question) => {
    const { type: questionType, grade } = question
    if (!questionType) {
      setNewQuestionIndex(null)
      return
    }
    // get template of a new question
    const newQuestion = getQuestionTemplateByType(questionType, languageData?.preview?.blank_question, examJson?.parameters?.use_indicator)
    insertNewQuestion(newQuestion, grade);
    // addNewQuestionWithAI(newQuestion);
  };

  const handleAddNestedQuestionGrade = (question, grade) => {
    const { nested_questions } = question
    const newNestedGrade = Math.round(grade / nested_questions.length)
    return nested_questions.map((nq, index) => {
      if (index === nested_questions.length - 1) {
        // the last nested question rounds the grade to the nearest integer
        return { ...nq, grade: grade - (newNestedGrade * (nested_questions.length - 1)) }
      }
      return { ...nq, grade: newNestedGrade }
    })
  }

  const handleDeleteQuestion = (questionId) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.filter(question => question._id !== questionId);
      return { ...prevExam, questions: updatedQuestions };
    });
  }
  const handleClickDeleteQuestion = (currentTarget, questionId) => {
    setDeletePopoverAnchorEl(currentTarget);
    deletePopoverPayload.current = { questionId, onDelete: () => handleDeleteQuestion(questionId) };
  }

  // const handleSubmitModalWithGrade = async () => {
  //   await saveChanges(editableExamJson)
  //   setOpenModal(false)
  // }
  function getTotalGrade(questions) {
    let totalGrade = 0
    questions?.forEach(question => {
      totalGrade += parseFloat(question.grade)
    })
    return parseFloat(totalGrade.toFixed(2))
  }
  async function handleSaveChangesFromEditPage() {
    let totalGrade = 0
    totalGrade = getTotalGrade(editableExamJson.questions)

    // if (totalGrade !== 100) {
    //   handleOpenModal()
    // }
    // else {
    await saveChanges(editableExamJson)
    // }
  }

  async function saveChanges(exam = editableExamJson) {
    if (!exam.title) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setError({
        display: true,
        msg: languageData.preview?.errors.missing_title,
      })
      return Promise.reject(languageData.preview?.errors.missing_title)
    }
    if (hasEmptyFields(exam.questions)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setError({
        display: true,
        msg: languageData.preview?.errors.missing_field,
      })
      return Promise.reject(languageData.preview?.errors.missing_field)
    }

    try {
      // We only save questions & title. (_id is mandatory for the db operation)
      const examToSave = { questions: exam.questions, title: exam.title, settings: exam?.settings || {}, parameters: exam?.parameters || {} }
      // Validate and set text field
      if (exam.text && exam.text.trim() !== "") {
        examToSave.text = exam.text;
      } else {
        examToSave.text = null;
      }

      // Validate and set instructions field
      if (exam.instructions && exam.instructions.trim() !== "") {
        examToSave.instructions = exam.instructions;
      } else {
        examToSave.instructions = null;
      }

      const response = await saveExam(exam._id, examToSave)
      if (response.status === HttpStatusCode.Ok) {
        // todo: we might want to delete the setExamJson here, since its only saving in db
        setExamJson({ ...exam })
        setEditableExamJson({ ...exam })
        updateExamInHistory(exam)
        openSnackBar(SnackbarTypes.SAVED_FOR_LATER_SUCCESS.field);
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setError({
          display: true,
          msg: languageData.preview?.errors.try_again,
        })
      }
    } catch (e) {
      setError({
        display: true,
        msg: languageData.preview?.errors.no_save,
      })
      openSnackBar(SnackbarTypes.SUBMIT_FAILED.field);
      logErrorToServer(`Error saving exam: ${e}`);
    }
  }

  const toggleEditingMode = async () => {
    if (isEditing) {
      return saveChanges(editableExamJson).then(() => {
        setIsEditing((prevIsEditing) => !prevIsEditing)
      })
    } else {
      setIsEditing((prevIsEditing) => !prevIsEditing)
    }
  }

  const handleExamTitleChange = (e) => {
    setEditableExamJson((exam) => ({ ...exam, title: e.target.value }))
  }

  const handleClosedQuestionChange = (index, newQuestion, newOptions, newCorrectAnswers, newExplanation) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.map((questionItem, i) => {
        if (i === index) {
          return {
            ...questionItem,
            question: newQuestion,
            options: newOptions,
            correctAnswers: newCorrectAnswers,
            explanation: newExplanation,
          }
        }
        return questionItem
      })
      return {
        ...prevExam,
        questions: updatedQuestions,
      }
    })
  }
  const handleNestedQuestionChange = (index, title, updateNestedQuestions, newGrade = -1) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.map((question, i) => {
        if (i === index) {
          return {
            ...question,
            question: title,
            nested_questions: updateNestedQuestions,
            ...(newGrade >= 0 ? { grade: newGrade } : {})
          }
        }
        return question
      })

      return {
        ...prevExam,
        questions: updatedQuestions,
      }
    })
  }
  const handleOpenQuestionChange = (index, newQuestion) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.map((question, i) => {
        if (i === index) {
          return {
            ...question,
            ...newQuestion,
            ...(newQuestion?.question || newQuestion?.title ? { question: newQuestion?.question ?? newQuestion?.title } : {}),
          }
        }
        return question
      })
      return {
        ...prevExam,
        questions: updatedQuestions,
      }
    })
  }

  const handleCancelEdit = (e) => {
    setEditableExamJson(examJson)
    setIsEditing(false)
  }

  const handleExportClick = (e) => {
    setOpenExamDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenExamDialog(false)
  }

  const handleSaveSettings = (newSettings) => {
    try {
      if (editableExamJson) {
        setEditableExamJson((prevExam) => {
          const updatedExam = {
            ...prevExam,
            settings: {
              ...prevExam.settings,
              ...newSettings,
            },
          };

          saveChanges(updatedExam);
          return updatedExam;
        });

      }
    } catch (error) {
      logErrorToServer('Error saving settings:', error);
      throw error;
    }
  };

  const handleSaveParams = (newParams) => {
    try {
      if (!editableExamJson) return;
      const { use_indicator } = newParams
      if (use_indicator == undefined) return;

      setEditableExamJson((prevExam) => {
        const updatedExam = {
          ...prevExam,
          parameters: {
            ...prevExam.parameters,
            use_indicator: use_indicator,
          },
        };

        if (!!use_indicator !== !!prevExam.parameters?.use_indicator) {
          updatedExam.questions = SwapIndicators(use_indicator, updatedExam.questions)
        }

        saveChanges(updatedExam);
        return updatedExam;
      });
    } catch (error) {
      console.error('Error saving params:', error);
      throw error;
    }
  };

  const handleModifyQuestion = (index, instruction) => {
    setModifyingQuestionIndex(index);
    const question = editableExamJson.questions[index];
    const language = examJson?.parameters?.language;

    oldQuestionRef.current = question;

    requestModifyingQuestion(question, instruction, language).then((newQuestionResponse) => {
      if (!newQuestionResponse) {
        openSnackBar(SnackbarTypes.ERROR.field);
        handleCompareDialogClose()
        return;
      }
      const updatedNewQuestion = {
        ...newQuestionResponse,
        _id: question?._id || generateId(),
        grade: question?.grade,
      };

      newQuestionRef.current = updatedNewQuestion;
      setIsCompareDialogOpen(true); // Open the dialog

    }).catch(e => {
      openSnackBar(SnackbarTypes.ERROR.field);
      handleCompareDialogClose()
    })
  };

  const handleApproveDialog = (approved) => {
    if (!approved) return;

    setEditableExamJson((prevExam) => {
      const newQuestions = [...prevExam.questions];
      const questionIndex = newQuestions.findIndex(q => q._id === oldQuestionRef.current._id);

      if (questionIndex === -1) {
        openSnackBar(SnackbarTypes.ERROR.field);
        return prevExam;
      }

      // Update the question and handle state/save operations
      newQuestions[questionIndex] = newQuestionRef.current;

      const updatedExam = {
        ...prevExam,
        questions: newQuestions,
      };

      // Open success snackbar and save changes
      openSnackBar(SnackbarTypes.SUCCESS.field);
      debouncedSaveChanges(updatedExam);
      setIsCompareDialogOpen(false);

      return updatedExam;
    });

    setModifyingQuestionIndex(null);
  };


  const handleCompareDialogClose = () => {
    setIsCompareDialogOpen(false);
    oldQuestionRef.current = null;
    newQuestionRef.current = null;
    setModifyingQuestionIndex(null);
  };

  const handleGradeChanged = (index, newGrade) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.map((questionItem, i) => {
        if (i === index) {
          return {
            ...questionItem,
            grade: newGrade,
          }
        }
        return questionItem
      })
      return {
        ...prevExam,
        questions: updatedQuestions,
      }
    })
  }

  const getBloomLevel = (label, languageData) => {
    const matchingEntry = languageData.bloomsQuestions.find(entry => entry.value === label);
    return matchingEntry ? matchingEntry : undefined;
  };
  const handleUnseenTextChange = (text) => {
    setEditableExamJson((editableExamJson) => ({
      ...editableExamJson,
      text: text
    }))
  }

  const handleInstructionsChange = (text) => {
    setEditableExamJson((editableExamJson) => ({
      ...editableExamJson,
      instructions: text
    }))
  }

  /**
   * @param {Array<{ examId: string, type: string, count: number }>} selectedExamsData - Array of objects containing examId, merge type, and count
   * @param {boolean} divideGrade - Flag to indicate if grades should be divided equally
  */
  const handleMergeExams = async (selectedExamsData, divideGrade = false) => {
    try {
      let mergedQuestions = [];
      let swapIndicatorsNeeded = false;

      for (const examData of selectedExamsData) {
        const exam = history.find(e => e._id === examData.examId);
        if (!exam) continue; // Skip if exam not found

        let questionsToMerge = exam.questions;

        if (examData.type === MergeTypes.random && examData.count > 0) {
          // Select random questions
          if (examData.count >= exam.questions.length) {
            questionsToMerge = exam.questions; // Take all questions if count is greater than or equal to total questions
          } else {
            let shuffledQuestions = shuffleArray(exam.questions);
            questionsToMerge = shuffledQuestions.slice(0, examData.count);
          }
        }

        // Generate new IDs for all questions to create duplicates and avoid using the same ID
        const newQuestions = questionsToMerge.map(question => ({ ...question, _id: generateId() }));
        mergedQuestions = mergedQuestions.concat(newQuestions);
        if (exam.parameters?.use_indicator !== editableExamJson.parameters?.use_indicator) {
          swapIndicatorsNeeded = true;
        }
      }

      // Update the editable exam JSON with the merged questions
      setEditableExamJson(prevExam => {
        let questions = [...prevExam.questions, ...mergedQuestions];
        if (divideGrade) {
          questions = distributeGradesEqually(questions);
        }
        if (swapIndicatorsNeeded) {
          questions = SwapIndicators(editableExamJson.parameters?.use_indicator, questions);
        }

        const updatedExam = { ...prevExam, questions: questions };
        saveChanges(updatedExam);
        return updatedExam;
      });
    } catch (error) {
      logErrorToServer('Error merging exams:', error);
      throw error;
    }
  };

  const handleUploadImage = (id, event) => {
    let image = event.target.files[0]
    setLoadingUploadId(id)
    uploadImage(image).then((response) => {
      const imageData = response.image_data
      if (imageData.secure_url && imageData.public_id) {
        setEditableExamJson((prevExam) => {
          const updatedQuestions = prevExam.questions.map((questionItem, i) => {
            if (id === questionItem._id) {
              return {
                ...questionItem,
                image: { url: imageData.secure_url, meta: imageData },
              }
            }
            return questionItem
          })
          const newExam = { ...prevExam, questions: updatedQuestions }
          saveChanges(newExam)
          return newExam
        })
      }
    }).catch(err => {
      openSnackBar(SnackbarTypes.ERROR.field);
    }).finally(() => setLoadingUploadId(null))
  }
  const handleDeleteImage = () => {
    setDeletePopoverAnchorEl(null);
    const { questionId } = deletePopoverPayload.current;
    const imageId = editableExamJson.questions.find(q => q._id === questionId)?.image?.meta?.public_id;

    if (!imageId) return;

    deleteImage(imageId).then(() => {
      setEditableExamJson((prevExam) => {
        const updatedQuestions = prevExam.questions.map((questionItem, i) => {
          if (questionId === questionItem._id) {
            return {
              ...questionItem,
              image: null,
            }
          }
          return questionItem
        })
        const newExam = { ...prevExam, questions: updatedQuestions }
        saveChanges(newExam)
        return newExam
      })
    }).catch(err => {
      openSnackBar(SnackbarTypes.ERROR.field);
    })
  }
  const handleClickDeleteImage = (currentTarget, questionId) => {
    setDeletePopoverAnchorEl(currentTarget);
    deletePopoverPayload.current = { questionId, onDelete: handleDeleteImage };
  }
  const handleAddInstructions = (newInstructions) => {
    try {
      if (editableExamJson) {
        setEditableExamJson((prevExam) => {
          const updatedExam = {
            ...prevExam,
            instructions: newInstructions,
          };

          return updatedExam;
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } catch (error) {
      logErrorToServer('Error saving instructions:', error);
      throw error;
    }
  };

  const handleAddText = (newText) => {
    try {
      if (editableExamJson) {
        setEditableExamJson((prevExam) => {
          const updatedExam = {
            ...prevExam,
            text: newText,
          };

          return updatedExam;
        });

        window.scrollTo({ top: 0, behavior: 'smooth' });

      }
    } catch (error) {
      logErrorToServer('Error saving new text:', error);
      throw error;
    }
  };

  const handleAddTextToQuestion = useDebounce((questionId, text) => {
    setEditableExamJson((prevExam) => {
      const updatedQuestions = prevExam.questions.map((questionItem, i) => {
        if (questionId === questionItem._id) {
          return {
            ...questionItem,
            text: text,
          }
        }
        return questionItem
      })
      const newExam = { ...prevExam, questions: updatedQuestions }
      return newExam
    })
  }, 500)


  const handleLikeQuestion = (questionId) => {
    const question = editableExamJson.questions.find(q => q._id === questionId);
    if (!question || likedQuestions.has(questionId)) return;

    setLikedQuestions(prev => {
      const newLikes = new Set(prev);
      const newDislikes = new Set(dislikedQuestions); // Create a copy of disliked questions

      if (newLikes.has(questionId)) {
        newLikes.delete(questionId); // Toggle off if already liked
      } else {
        newLikes.add(questionId); // Add to liked
        newDislikes.delete(questionId); // Remove from disliked if switching

        // Send data to server
        addGoodQuestion({
          exam_id: examJson._id,
          question_id: questionId,
          question: question,
          author: user._id
        }).catch(error => {
          logErrorToServer(`Error adding good question: ${error}`);
          openSnackBar(SnackbarTypes.ERROR.field);
        });
      }
      setDislikedQuestions(newDislikes); // Update disliked questions
      return newLikes;
    });
  };

  const handleDislikeQuestion = (questionId) => {
    const question = editableExamJson.questions.find(q => q._id === questionId);
    if (!question || dislikedQuestions.has(questionId)) return;

    setDislikedQuestions(prev => {
      const newDislikes = new Set(prev);
      const newLikes = new Set(likedQuestions); // Create a copy of liked questions

      if (newDislikes.has(questionId)) {
        newDislikes.delete(questionId); // Toggle off if already disliked
      } else {
        newDislikes.add(questionId); // Add to disliked
        newLikes.delete(questionId); // Remove from liked if switching

        // Send data to server only if not already disliked
        addBadQuestion({
          exam_id: examJson._id,
          question_id: questionId,
          question: question,
          author: user._id
        }).catch(error => {
          logErrorToServer(`Error adding bad question: ${error}`);
          openSnackBar(SnackbarTypes.ERROR.field);
        });
      }
      setLikedQuestions(newLikes); // Update liked questions
      return newDislikes;
    });
  };

  const handleCancelLikeQuestion = (questionId) => {
    removeGoodQuestion(questionId).then(() => {
      setLikedQuestions(prev => {
        const newLikes = new Set(prev);
        newLikes.delete(questionId); // Remove from liked
        return newLikes;
      });
    }).catch(error => {
      logErrorToServer(`Error removing good question: ${error}`);
      openSnackBar(SnackbarTypes.ERROR.field);
    });
  }

  const handleCancelDislikeQuestion = (questionId) => {
    removeBadQuestion(questionId).then(() => {
      setDislikedQuestions(prev => {
        const newDislikes = new Set(prev);
        newDislikes.delete(questionId); // Remove from disliked
        return newDislikes;
      });
    }).catch(error => {
      logErrorToServer(`Error removing bad question: ${error}`);
      openSnackBar(SnackbarTypes.ERROR.field);
    });
  }

  if (!examJson || !languageData?.preview) {
    return (
      <>
        <Header />
        {error.display
          ? (<Grid item xs={12}>
            <ErrorMessage onHide={() => { }} isRTL={isRTL} message={error.msg} link='/history' buttonLabel={languageData?.preview?.view_exams ?? 'View My Exams'} />
          </Grid>)
          : <ExamSkeleton numberOfQuestions={5} />
        }
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{`StudyWise | ${examJson?.title}`}</title>
        <meta name="description" content="Preview your upcoming exam with our AI-powered exam generator. Get an overview of the questions to prepare effectively and confidently." />
      </Helmet>
      <PreviewHeader examId={examJson?._id} handleSaveSettings={handleSaveSettings} examSettings={examJson?.settings} examParams={examJson?.parameters} handleSaveParams={handleSaveParams} />
      <div dir={isRTL ? 'rtl' : 'ltr'} >
        <Grid container gap={'20px'} style={{ position: 'relative', padding: isMobile ? 0 : 20, ...paddingPageStyle }} >
          <Grid item xs={12} >
            {isMobile ?
              <>
                <Toolbar sx={{ gap: '10px' }}>
                  <PreviewButtons
                    isEditing={isEditing}
                    isRTL={isRTL}
                    examId={examJson?._id}
                    toggleEditingMode={toggleEditingMode}
                    handleCancelEdit={handleCancelEdit}
                    languageData={languageData}
                    onMerge={handleMergeExams}
                    roles={user?.roles}
                    subject={examJson?.parameters?.subject?.length ? examJson?.parameters?.subject[0] : null}
                  />
                </Toolbar>
                <Divider />
              </>
              :
              <PreviewMenu
                isEditing={isEditing}
                isRTL={isRTL}
                examId={examJson?._id}
                toggleEditingMode={toggleEditingMode}
                handleCancelEdit={handleCancelEdit}
                handleSaveSettings={handleSaveSettings}
                languageData={languageData?.preview}
                onMerge={handleMergeExams}
                roles={user?.roles}
                examSettings={examJson?.settings}
                subject={examJson?.parameters?.subject?.length ? examJson?.parameters?.subject[0] : null}
              />
            }
          </Grid>
          <Grid item xs={12} style={{ padding: '0px 20px', marginBottom: '30px' }}>
            <Paper elevation={0} style={{ padding: isMobile ? 0 : `0 50px 0 50px`, backgroundColor: 'transparent' }}>
              {isEditing ? (
                <TextField
                  label={languageData?.preview?.exam_title}
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  value={editableExamJson.title}
                  onChange={handleExamTitleChange}
                  error={!editableExamJson.title}
                />
              ) : (
                <div>
                  <Typography variant="h5" gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
                    {editableExamJson.title}
                    <FlickeringDot isSuccess={examJson?.settings?.accept_responses} />
                  </Typography>
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '98%', marginBottom: '8px' }}>
                <div>
                  <Typography variant='body1' gutterBottom>
                    {languageData.preview?.total_grade + '  ' + getTotalGrade(editableExamJson.questions)}
                  </Typography>
                  <Tooltip title={languageData?.preview?.distribute_grades_btn?.tooltip} placement="bottom" slotProps={{ popper: { sx: { textAlign: 'center' } } }}>
                    <Chip variant="outlined" onClick={() => handleMergeExams([], true)} label={languageData?.preview?.distribute_grades_btn?.label ?? "Distribute to 100"} />
                  </Tooltip>
                </div>
                {!isEditing && (
                  <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'flex-end' }}>
                    <Tooltip title={languageData.preview.tooltips.export_exam} placement="bottom">
                      <Chip variant="outlined" onClick={handleExportClick} label={languageData.preview?.tooltips?.export_exam} />
                    </Tooltip>
                  </div>
                )}
              </div>

              <Divider style={{ marginBottom: '20px' }} />
              {editableExamJson?.instructions && (
                isEditing ? (
                  <TextField
                    placeholder="Enter instructions here"
                    value={editableExamJson.instructions}
                    onChange={e => handleInstructionsChange(e.target.value)}
                    fullWidth
                    error={!editableExamJson.instructions}
                    multiline
                    variant="outlined"
                    margin="normal"
                  />
                ) : (
                  <Grid mb={"20px"}>
                    <Unseen text={editableExamJson.instructions} />
                  </Grid>
                )
              )}
              {/* Url source - link */}
              {examJson?.parameters?.source === inputTypes.url && examJson?.parameters?.prompt &&
                <Grid mb={"20px"}>
                  <Unseen link={examJson.parameters.prompt} />
                </Grid>
              }
              {editableExamJson?.text && (
                isEditing ? <TextField
                  label={languageData.question_title}
                  value={editableExamJson.text}
                  onChange={e => handleUnseenTextChange(e.target.value)}
                  fullWidth
                  error={!editableExamJson?.text}
                  multiline
                  variant="outlined"
                  margin="normal"
                /> :
                  <Grid mb={"20px"}>
                    <Unseen text={editableExamJson?.text} />
                  </Grid>
              )}
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="questions">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {editableExamJson.questions.length === 0 && (
                        <IconButton
                          onClick={() => {
                            setOpenAddQuestionDialog(true)
                            setNewQuestionIndex(0)
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      )}

                      {editableExamJson.questions.map((question, index) => {
                        const imageInputId = `question-image-input-${index}`;
                        return (
                          <Draggable
                            key={question._id}
                            draggableId={question._id}
                            index={index}
                            isDragDisabled={isEditing}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <div style={{ display: 'flex', flexDirection: 'column', opacity: snapshot.isDragging ? 0.7 : 1 }}>
                                  {/* Render the image if it exists */}
                                  {isEditing && <InlineActionsBar
                                    languageData={languageData}
                                    setOpenAddQuestionDialog={setOpenAddQuestionDialog}
                                    setNewQuestionIndex={setNewQuestionIndex}
                                    index={index}
                                    editableExamJson={editableExamJson}
                                    handleAddInstructions={handleAddInstructions}
                                    handleAddText={handleAddText}
                                  />}
                                  <Paper style={{
                                    padding: isMobile ? '20px' : '20px 30px',
                                    marginBottom: '20px',
                                    background: snapshot.isDragging ? '#f5f5f5' : 'white'
                                  }}>
                                    {(index === modifyingQuestionIndex || index === newQuestionIndex) ? (
                                      <ExamSkeleton />
                                    ) : (
                                      <>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                            {!isMobile && <div {...provided.dragHandleProps} style={{ cursor: 'grab', display: 'flex', alignItems: 'center' }}>
                                              <IconButton>
                                                <DragIndicatorIcon />
                                              </IconButton>
                                            </div>}
                                            <Typography variant="h5" component="div" style={{ margin: isRTL ? '0 0 0 8px' : '0 8px 0 0' }}>
                                              {languageData.preview?.question + ' ' + (index + 1)}
                                            </Typography>
                                            <Chip variant="outlined"
                                              label={question.grade + ' ' + languageData.preview?.points_label} icon severity="info" sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}
                                            />
                                            {/* {question.grade + ' ' + languageData.preview?.points_label} */}
                                            {(!isMobile && question?.bloom_level && languageData?.bloom_level_map[question?.bloom_level]) && <Tooltip arrow title="Bloom's Taxonomy Level">
                                              <Chip
                                                label={languageData?.bloom_level_map[question?.bloom_level]}
                                                variant="outlined"
                                                color={getBloomLevelColor(getBloomsLevel(question?.bloom_level ?? null))}
                                                sx={{ fontWeight: 'bold', mx: isMobile ? 0 : 2, ...(isRTL ? { ml: 2 } : { mr: 2 }) }}
                                              />
                                            </Tooltip>}
                                            {isEditing &&
                                              <IconButton onClick={e => handleClickDeleteQuestion(e.currentTarget, question._id)}>
                                                <DeleteIcon color="error" />
                                              </IconButton>}
                                          </div>
                                          {(
                                            <QuestionActions
                                              isEditing={isEditing}
                                              examParams={examJson?.parameters}
                                              index={index}
                                              question={question}
                                              handleModifyQuestion={handleModifyQuestion}
                                              handleAddTextToQuestion={handleAddTextToQuestion}
                                              handleLikeQuestion={handleLikeQuestion}
                                              handleDislikeQuestion={handleDislikeQuestion}
                                              handleCancelLikeQuestion={handleCancelLikeQuestion}
                                              handleCancelDislikeQuestion={handleCancelDislikeQuestion}
                                              likedQuestions={likedQuestions}
                                              dislikedQuestions={dislikedQuestions}
                                              handleClickDeleteImage={handleClickDeleteImage}
                                              handleUploadImage={handleUploadImage}
                                              imageInputId={imageInputId}
                                            />
                                          )}
                                        </div>
                                        <Grid item xs={12} md={12} lg={12} py={1}>
                                          <EditableQuestionText isEditing={isEditing} text={question.text} onChange={val => handleAddTextToQuestion(question?._id, val)} />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} py={1}>
                                          {question.image && question.image.url && (
                                            <img src={question.image.url}
                                              alt={`Question ${index + 1}`}
                                              style={{ objectFit: 'contain', maxHeight: '300px', width: '100%' }} />
                                          )}
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                          <EditableQuestionTypeManager
                                            type={question.type}
                                            allQuestionsProps={{
                                              languageData: languageData.preview,
                                              questionIndex: index,
                                              title: question.question,
                                              grade: question.grade,
                                              isEditing: isEditing,
                                              onGradeChange: handleGradeChanged,
                                              use_indicator: examJson?.parameters?.use_indicator,
                                            }}
                                            nestedProps={{
                                              explanation: question.explanation,
                                              nested: question?.nested_questions,
                                              parentIndex: index + 1,
                                              onChange: handleNestedQuestionChange,
                                              pointsGrid: isMobile ? 3 : 1
                                            }}
                                            openQuestionProps={{
                                              explanation: question?.explanation,
                                              indicator: question?.indicator,
                                              source: question?.source_link,
                                              onChange: handleOpenQuestionChange,
                                              pointsGrid: isMobile ? 3 : 1
                                            }}
                                            closeQuestionProps={{
                                              options: question.options,
                                              correctAnswer: question.correctAnswers?.[0],
                                              onChange: handleClosedQuestionChange,
                                              explanation: question.explanation
                                            }}
                                            GraphQuestionProps={{
                                              explanation: question.explanation,
                                              functions: question.functions,
                                              onChange: handleOpenQuestionChange,
                                            }}
                                          />
                                        </Grid>
                                      </>
                                    )}
                                  </Paper>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {isEditing && editableExamJson.questions.length > 0 && <InlineActionsBar
                        languageData={languageData}
                        setOpenAddQuestionDialog={setOpenAddQuestionDialog}
                        setNewQuestionIndex={setNewQuestionIndex}
                        index={editableExamJson.questions.length}
                        editableExamJson={editableExamJson}
                        handleAddInstructions={handleAddInstructions}
                        handleAddText={handleAddText}
                      />}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Paper>
          </Grid>

          <AddQuestionDialog
            open={openAddQuestionDialog}
            onClose={() => setOpenAddQuestionDialog(false)}
            onAddQuestion={handleAddQuestion}
          />
          <ExamDialog
            open={openExamDialog}
            examJson={examJson}
            onClose={handleCloseDialog}
            id={examJson._id}
          />

        </Grid >
        <Alert severity="warning" >
          {languageData.preview?.ai_attention_alert}
        </Alert>
      </div >
      {/* < GradeModal
        open={openModal}
        onClose={handleCancelChangesInModal}
        onSubmit={handleSubmitModalWithGrade}
        totalGrade={getTotalGrade(editableExamJson.questions)}
        languageData={languageData.preview}
      /> */}
      {/* Delete confirmation for images */}
      <DeleteConfirmationPopver
        anchorEl={deletePopoverAnchorEl}
        onClose={() => setDeletePopoverAnchorEl(null)}
        onCancel={() => setDeletePopoverAnchorEl(null)}
        onDelete={() => { deletePopoverPayload.current?.onDelete(); setDeletePopoverAnchorEl(null) }}
      />
      {/* Compare questions after modify */}
      <QuestionComparisonDialog
        open={isCompareDialogOpen}
        onClose={handleCompareDialogClose}
        onApprove={handleApproveDialog}
        oldQuestion={oldQuestionRef.current}
        newQuestion={newQuestionRef.current}
        use_indicator={editableExamJson?.parameters?.use_indicator}
      />
      {/* School Registeration dialog */}
      <SchoolRegistrationWrapper />
    </>
  )
}

export default PreviewPage

/**
 * Fixes the id and grade per question (if necessary)
 * @param {{ _id: string, title: string, questions: { _id: string, grade: float }[] }} examDocument
      */
function fixExamStructure(examDocument) {
  let saveIsNeeded = false
  const updatedExam = examDocument.questions.map((question) => {
    const newQuestion = { ...question }
    if (!question._id) {
      newQuestion._id = generateId()
      saveIsNeeded = true
    }
    if (!question.grade) {
      newQuestion.grade = Math.round(100 / examDocument.questions.length)
      saveIsNeeded = true
    }
    return newQuestion
  })
  examDocument.questions = updatedExam

  if (saveIsNeeded) {
    const examToSave = { questions: updatedExam }
    saveExam(examDocument._id, examToSave)
  }

  return examDocument
}

function hasEmptyFields(questions) {
  function containsInvalidValues(array) {
    return array.some(
      (value) => value === '' || value === undefined || value === null
    )
  }

  for (const item of questions) {
    if (item.type === questionsType.open || item.type === questionsType.graph) {
      if (!item.question || (!item.explanation && !item.indicator)) {
        return true // Found an item with empty fields
      }
    } else if (item.type === questionsType.nested) {
      //TODO add empty validation for nested question
      if (!item.question || !item.nested_questions) {
        return true // Found an item with empty fields
      }
    } else {
      if (
        !item.question ||
        !item.correctAnswers ||
        item.correctAnswers.length === 0 ||
        containsInvalidValues(item.correctAnswers) ||
        !item.options ||
        item.options.length === 0 ||
        containsInvalidValues(item.options)
      )
        return true // Found an item with empty fields
    }
  }
  return false // No items with empty fields found
}

function InlineActionsBar({ languageData, setOpenAddQuestionDialog, setNewQuestionIndex, index, editableExamJson, handleAddInstructions, handleAddText }) {
  const openQuestionsCount = useMemo(() =>
    editableExamJson?.questions?.filter(q => q.type === questionsType.open || q.type === questionsType.nested).length || 0,
    [editableExamJson?.questions]
  );

  const canAddQuestion = editableExamJson?.parameters && openQuestionsCount <= 20;

  return (
    <Box p={2} display="flex" justifyContent="center" alignItems="center" gap={1}>
      <Tooltip title={languageData?.preview?.tooltips?.add_question ?? "Add Question"} arrow>
        <IconButton onClick={() => {
          setOpenAddQuestionDialog(true);
          setNewQuestionIndex(index);
        }}>
          {canAddQuestion && <AddIcon />}
        </IconButton>
      </Tooltip>
      {!editableExamJson?.instructions && (
        <Tooltip title={languageData?.preview?.tooltips?.add_instructions ?? "Add Instructions"} arrow>
          <IconButton onClick={() => handleAddInstructions(languageData?.preview?.instructions ?? "New instruction text")}>
            <PostAddIcon />
          </IconButton>
        </Tooltip>
      )}
      {!editableExamJson?.text && (
        <Tooltip title={languageData?.preview?.tooltips?.add_text ?? "Add Text"} arrow>
          <IconButton onClick={() => handleAddText(languageData?.preview?.text ?? "Type your text here..")}>
            <TextFieldsIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

function distributeGradesEqually(questions, total_grade = 100) {
  if (!questions || questions.length === 0) return questions

  const newGrade = Math.floor(total_grade / questions.length);
  return questions.map((q, index) => {
    let newQ = { ...q, grade: index === questions.length - 1 ? total_grade - newGrade * (questions.length - 1) : newGrade }

    if (q.type === questionsType.open && Array.isArray(q.indicator)) {
      const pointsPerCriteria = Math.floor(newQ.grade / q.indicator.length)
      newQ.indicator = q.indicator.map((criteria, j) => ({ ...criteria, points: j == q.indicator.length - 1 ? newQ.grade - pointsPerCriteria * (q.indicator.length - 1) : pointsPerCriteria }))
    }

    if (q.type === questionsType.nested)
      newQ.nested_questions = distributeGradesEqually(q?.nested_questions, newQ.grade)
    return newQ
  });
}

function EditableQuestionText({ isEditing, text, onChange }) {
  const [textState, setTextState] = useState(text)

  useEffect(() => {
    setTextState(text)
  }, [text])

  const handleChangeText = (e) => {
    setTextState(e.target.value)
    onChange(e.target.value)
  }
  if (!textState)
    return null;

  if (isEditing) {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <TextField
            value={textState}
            onChange={handleChangeText}
            fullWidth
            multiline
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item>
          <Tooltip title="Delete Text" arrow>
            <IconButton onClick={() => handleChangeText({ target: { value: '' } })} sx={{ margin: 0 }}>
              <RemoveCircleOutlineIcon color="error" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid item xs={12}>
      <Typography variant="body1" gutterBottom>
        {textState}
      </Typography>
    </Grid>
  )
}