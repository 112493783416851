import React from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import MergeButton from '../../components/Merge/MergeButton';
import GradingIcon from '@mui/icons-material/Grading';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { isMobile } from '../../utils';
import { iconButtonStyle } from '../../utils/customTheme';
import { useNavigate } from 'react-router-dom';
import ROLES from '../../utils/roles';
import SaveToHubButton from '../../components/SaveToHubButton/SaveToHubButton';

const PreviewButtons = ({ isEditing, isRTL, examId, toggleEditingMode, handleCancelEdit, languageData, onMerge, roles, subject }) => {
    const iconBtnStyle = isMobile ? iconButtonStyle.mobile : iconButtonStyle.desktop(isRTL)

    const navigate = useNavigate();

    const handleGoToReview = () => {
        navigate(`/review?id=${examId}`);
    }

    return (
        <>
            <Button
                component='label'
                variant='outlined'
                color={isEditing ? 'success' : 'primary'}
                startIcon={isEditing ? (<DoneIcon />) : (<EditIcon />)}
                sx={iconBtnStyle}
                onClick={toggleEditingMode}
            >
                {!isMobile && (isEditing ? languageData?.preview?.save_label : languageData?.preview?.edit_exam)}
            </Button >
            {isEditing ?
                (
                    <Button component='label' variant='outlined' color='error' startIcon={<CancelOutlinedIcon />}
                        sx={{ textTransform: 'capitalize', ...(iconBtnStyle) }}
                        onClick={handleCancelEdit}
                    >
                        {!isMobile && languageData.preview.cancel_label}
                    </Button>
                ) : (
                    <>
                        <MergeButton onMerge={onMerge} examId={examId} />
                        <Button
                            variant='outlined'
                            color='primary'
                            startIcon={<GradingIcon />}
                            sx={iconBtnStyle}
                            onClick={handleGoToReview}
                        >
                            {!isMobile && languageData.review_btn_label}
                        </Button>
                        {ROLES.isTeacherRole(roles) &&
                            <SaveToHubButton
                                subject={subject}
                                examId={examId}
                            />
                        }
                    </>
                )
            }
        </>
    );
}

export default PreviewButtons;
