import api from './api';

export const buttonsIds = {
    buyExams: '64465599d8bd6a39addec70c',
    generateExam: '64465599d8bd6a39addec70d',
    downloadPDF: '64465599d8bd6a39addec70e',
    createAdvanceExams: '64465599d8bd6a39addec70f',
    features: '64465599d8bd6a39addec710',
    contactUs: '64465599d8bd6a39addec711',
    feedbackFloating: '64465599d8bd6a39addec712',
    exportGoogleForms: '646b8b5e03f24a530de970b2',
    workedExportGoogleForms: '646b8dbf03f24a530de970b5',
    linkToGoogleForms: '646b8bd603f24a530de970b4',
    tryTemplate: '646b8e7b03f24a530de970b6',
    downloadWord: '65ae9497b9365cc3b1f5972b',
    downloadGIFT: '664b5c4dea516a0dd6c5b1f4',
    showSchoolRegisterationDialogHebKey: 'showSchoolRegisterationDialog',
    clickSchoolRegisterationDialogHebKey: 'clickSchoolRegisterationDialog',
    showSchoolRegisterationDialogEnKey: 'showSchoolRegisterationDialogEn',
    clickSchoolRegisterationDialogEnKey: 'clickSchoolRegisterationDialogEn',
}

export async function reportClick(clickId) {
    return api.post('/analytics/click', { id: clickId });
}

export async function reportClickKey(clickKey) {
    return api.post('/analytics/click', { key: clickKey });
}

export async function specialOfferTeacher(email) {
    return api.post('/analytics/special-offer', { email: email });
}

export async function logShare(email, title, id) {
    return api.post('/analytics/share-exam', { email: email, title: title, examId: id });
}