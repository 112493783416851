import React, { useEffect, useMemo, useState } from 'react';
import { Container, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { requestResendVlaidationEmail } from '../../requests/validation';
import { HttpStatusCode } from 'axios';
import validationGif from '../../assets/validation.gif';
import { isVerified } from '../../requests/auth';
import { homepage } from '../../components/menu/MenuLinks';
import { logErrorToServer } from '../../requests/error';

const styles = {
  root: {
    marginTop: '64px',
    textAlign: 'center',
  },
};

const ValidationStopPage = () => {
  const [openVerificationPopup, setOpenVerificationPopup] = useState(false);
  const location = useLocation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const email = useMemo(() => {
    const e = new URLSearchParams(location.search).get('e')
    return e ? atob(e) : null
  }, [location.search])

  useEffect(() => {
    if (email)
      isVerified(email).then(data => {
        if (data?.verified)
          window.open(homepage, '_self')
      }).catch(e => { });
  }, [email]);

  const handleResendVerificationEmail = async (event) => {
    startTimer();
    try {
      event.preventDefault();
      setIsButtonDisabled(true);
      const response = await requestResendVlaidationEmail(email);
      if (response?.status === HttpStatusCode.Ok) {
        setOpenVerificationPopup(true);
      }
    } catch (err) {
      logErrorToServer(`Error resending verification email: ${err}`);
    }
  };

  const startTimer = () => {
    let timeLeft = 10;
    setRemainingTime(timeLeft);
    const timer = setInterval(() => {
      timeLeft -= 1;
      setRemainingTime(timeLeft);
      if (timeLeft === 0) {
        clearInterval(timer);
        setIsButtonDisabled(false);
      }
    }, 1000);
  };

  return (
    <Container maxWidth="sm" style={styles.root}>
      <Typography variant="h4" gutterBottom>Email Validation</Typography>
      <Typography variant="body1">
        A verification email <b> has been sent to your email</b> address. <br /> Please <b>check your inbox or spam</b> and follow the instructions to complete the sign-up process.
      </Typography>
      <img src={validationGif} alt='validation' width={80} height={80} />

      <Grid item xs={12}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={handleResendVerificationEmail}
          disabled={isButtonDisabled}
        >
          {isButtonDisabled ? `Resend Verification Email (${remainingTime}s)` : 'Resend Verification Email'}
        </Button>
      </Grid>
      <Dialog open={openVerificationPopup} onClose={() => setOpenVerificationPopup(false)}>
        <DialogTitle>Verification Email Sent</DialogTitle>
        <DialogContent>
          <Typography>
            A verification email has been sent again to your email address. Please check your inbox and follow the instructions to complete the sign-up process.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenVerificationPopup(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ValidationStopPage;
