import { useMemo } from "react";
import { useUser } from "../../../hooks/useUser";
import ROLES from "../../../utils/roles";
import SchoolRegistrationDialogEn from "./SchoolRegistrationDialogEn";
import SchoolRegistrationDialogHe from "./SchoolRegisterationDialogHe";

export default function SchoolRegistrationWrapper() {
    const { user } = useUser();
    if (!user) return null;

    const shouldShowHebrew = useMemo(() => !user?.org_id && user?.roles.includes(ROLES.IL_TEACHER.label), [user]);
    const shouldShowEnglish = useMemo(() => !user?.org_id && !user?.roles.includes(ROLES.IL_TEACHER.label) && ROLES.isTeacherRole(user?.roles), [user]);

    return (
        <>
            {shouldShowHebrew && <SchoolRegistrationDialogHe />}
            {shouldShowEnglish && <SchoolRegistrationDialogEn />}
        </>
    );
}
