import { createAdaptiveExam, requestGenerateExam } from './exam';
import { requestExamStatus } from './exam';
import { intervalCallback } from '../utils/callbacks';
import { HttpStatusCode } from 'axios';

const generationErrors = {
    noText: -1,
    transcriptTooLong: -2,
    noSubtitles: -3,
    noTaskId: -4,
    error: -5,
    tooManyRequests: -6
}

function validateText(text, isFile) {
    if (isFile && (!text || text.length === 0)) {
        throw Error(generationErrors.noText)
    }
    if (!text || text?.length === 0) {
        throw Error(generationErrors.noText)
    }
}

/**
 * The functino receives the generateRequest function and the examParams object and returns the exam_id
 * @param {Function} generateRequest
 * @param {object} examParams 
 * @param {string} examParams.text 
 * @param {string} examParams.field 
 * @param {Array} examParams.questions 
 * @param {string} examParams.difficulty 
 * @param {string} examParams.language 
 * @param {string} examParams.focus 
 * @param {Array} examParams.skills 
 * @param {string} examParams.subject 
 * @param {string} examParams.exampleQuestion 
 * @param {boolean} examParams.isFile 
 * @param {object} examParams.payload 
 * @param {boolean} examParams.isClosedContent 
 * @param {boolean} examParams.useIndicator 
 * @returns {Promise<string>} exam_id
 */
async function generateExam(generateRequest, examParams) {
    validateText(examParams.text, examParams.isFile)
    try {
        const response = await generateRequest(examParams);
        const taskId = response.data.task_id;
        if (!taskId) {
            throw generationErrors.noTaskId;
        }
        return await handlePolling(taskId);
    } catch (error) {
        console.error('Error generating exam:', error);
        if (error?.response?.data?.status === 'youtube_error') {
            console.error(error.response?.data.msg);
            throw error.response?.data.msg;
        }
        else if (error.status === HttpStatusCode.TooManyRequests) {
            throw generationErrors.tooManyRequests;
        }
        else {
            throw generationErrors.error;
        }
    }
};

/**
 * Generate an adaptive exam based on the mistakes made by the user
 * @param {string} resposneId 
 * @param {{weaknesses: Array, commonErrors: Array, recommendations: Array}} mistakes 
 * @returns {string} exam_id
 */
async function generateAdaptiveExam(resposneId, mistakes) {
    try {
        const response = await createAdaptiveExam(resposneId, mistakes);
        const taskId = response.task_id
        if (!taskId) {
            throw generationErrors.noTaskId;
        }
        return await handlePolling(taskId);
    } catch (error) {
        console.error('Error generating exam:', error);
        if (error.status === HttpStatusCode.TooManyRequests) {
            throw generationErrors.tooManyRequests;
        }
        throw generationErrors.error;
    }
}

async function handlePolling(taskId) {
    const maxIterations = 60;  // Set the maximum number of iterations (5 minutes max)
    const delay = 5000; // Set the delay in milliseconds
    return intervalCallback(() => requestExamStatus(taskId), maxIterations, delay)
        .then((response) => {
            if (response.status >= 400) {
                throw response;
            }
            return response.data.result.exam_id;
        })
        .catch((error) => {
            throw error;
        });
}


export { generateExam, generationErrors, generateAdaptiveExam };