import api from './api';
import { processResponsePromise } from './utils';

export const addGoodQuestion = async (data) => {
    return processResponsePromise(api.post('integrity/good-question', data));
};

export const addBadQuestion = async (data) => {
    return processResponsePromise(api.post('integrity/bad-question', data));
};

export const getAllGoodQuestions = async () => {
    return processResponsePromise(api.get('integrity/good-questions'));
};

export const getAllBadQuestions = async () => {
    return processResponsePromise(api.get('integrity/bad-questions'));
};

export const getGoodQuestionsForAuthor = async (author) => {
    return processResponsePromise(api.get(`integrity/good-questions/${author}`));
};

export const getBadQuestionsForAuthor = async (author) => {
    return processResponsePromise(api.get(`integrity/bad-questions/${author}`));
};

export const getBadQuestionsByExamId = async (examId) => {
    return processResponsePromise(api.get(`integrity/bad-questions/exam/${examId}`));
};

export const getGoodQuestionsByExamId = async (examId) => {
    return processResponsePromise(api.get(`integrity/good-questions/exam/${examId}`));
};

export const removeGoodQuestion = async (questionId) => {
    return processResponsePromise(api.delete(`integrity/good-questions/${questionId}`));
};

export const removeBadQuestion = async (questionId) => {
    return processResponsePromise(api.delete(`integrity/bad-questions/${questionId}`));
};


