import React, { useState } from 'react';
import { Box, Alert, Button, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ctas } from '../utils/configs';

const useCTA = () => {
  const [openCTA, setOpenCTA] = useState(false);
  const [ctaData, setCtaData] = useState({});

  const openCTAWithProps = ({ type }) => {
    setOpenCTA(true);

    if (ctas[type]) {
      setCtaData({
        link: ctas[type].link || '/exam',
        content: ctas[type].content || '',
        buttonLabel: ctas[type].buttonLabel || 'Go to Link',
        newTab: ctas[type].newTab || false,
        isRTL: ctas[type].isRTL || false,
      })
    }
  };

  const CTA = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
      if (ctaData.newTab)
        window.open(ctaData.link, '_blank');
      else
        navigate(ctaData.link)
    };

    return (
      <div dir={ctaData.isRTL ? 'rtl' : 'ltr'}>
        {openCTA && <Box sx={{ backgroundColor: '#FFF4E5', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Alert sx={{ width: '70%' }} severity="warning" >
            <Grid item xs={6}>
              <Typography variant="subtitle2" component="div">
                {ctaData.content}
              </Typography>
            </Grid>
          </Alert>
          <Button onClick={handleButtonClick} variant='outlined' sx={{ mx: 2, fontWeight: '700' }}>
            {ctaData.buttonLabel}
          </Button>
        </Box>}
      </div>
    );
  };

  return { CTA, openCTAWithProps };
};

export default useCTA;
