import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Chip, Grid, Divider, Box, Typography } from '@mui/material';
import EditableQuestionTypeManager from './EditableQuestionTypeManager';
import { useLanguage } from '../../contexts/languageContext';

const QuestionComparisonDialog = ({ open, onClose, onApprove, oldQuestion, newQuestion, use_indicator }) => {
    const { isRTL, languageData } = useLanguage();

    if (!oldQuestion || !newQuestion) {
        return null
    }

    const renderEditableQuestionTypeManager = (question, index) => {
        const commonProps = {
            languageData: languageData.preview,
            questionIndex: index,
            title: question.question,
            explanation: question.explanation,
            nested: question?.nested_questions,
            parentIndex: index + 1,
            use_indicator: use_indicator
        };

        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <EditableQuestionTypeManager
                    key={index}
                    type={question.type}
                    allQuestionsProps={commonProps}
                    openQuestionProps={{
                        explanation: question?.explanation,
                        indicator: question?.indicator,
                        pointsGrid: 2
                    }}
                    closeQuestionProps={{
                        options: question?.options,
                        correctAnswer: question?.correctAnswers?.[0],
                    }}
                    nestedProps={{
                        explanation: question?.explanation,
                        nested: question?.nested_questions,
                        parentIndex: index + 1,
                        pointsGrid: 2
                    }}
                    GraphQuestionProps={{
                        explanation: question?.explanation,
                        functions: question?.functions,
                    }}
                />
            </Box>
        );
    };

    const handleApprove = () => {
        onApprove(true); // Return true on approve
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={null} // Disable closing on backdrop 
            maxWidth="lg"
            fullWidth
            dir={isRTL ? 'rtl' : 'ltr'}
        >
            <DialogTitle align="center">{languageData?.preview?.compare_dialog.title}</DialogTitle>
            <DialogContent>
                <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
                    {/* Old Question on the left */}
                    <Grid item xs={12} md={5} display="flex" flexDirection="column" gap={2}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Chip label={languageData?.preview?.compare_dialog.original} />
                        </Box>
                        {oldQuestion.text &&
                            <Typography variant="body1" gutterBottom>
                                {oldQuestion.text}
                            </Typography>
                        }
                        {renderEditableQuestionTypeManager(oldQuestion, 0)}
                    </Grid>

                    {/* Divider with text */}
                    <Divider orientation="vertical" flexItem>
                        <Box sx={{ px: 6, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {languageData?.preview?.compare_dialog.or}
                        </Box>
                    </Divider>

                    {/* New Question on the right */}
                    <Grid item xs={12} md={5} display="flex" flexDirection="column" gap={2}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Chip label={languageData?.preview?.compare_dialog.new} color="primary" />
                        </Box>
                        {newQuestion.text && <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                                {newQuestion.text}
                            </Typography>
                        </Grid>}
                        {renderEditableQuestionTypeManager(newQuestion, 1)}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={handleClose} color="primary" sx={{ mr: 1 }}>
                    {languageData?.preview?.compare_dialog.choose_original}
                </Button>
                <Button onClick={handleApprove} color="primary" variant="contained">
                    {languageData?.preview?.compare_dialog.choose_new}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default QuestionComparisonDialog;
