import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
    Button,
    IconButton,
    Chip,
    Tooltip,
    Menu,
    MenuItem,
    Divider,
    Box,
    Grid,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Visibility as VisibilityIcon, RateReview as RateReviewIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import ShareIcon from '@mui/icons-material/Share';
import { useNavigate } from 'react-router-dom';
import useSnackBar from '../hooks/useSnackBar';
import { SnackbarTypes } from '../utils/snackbarTypes';
import { useLanguage } from '../contexts/languageContext';
import { useUser } from '../hooks/useUser';
import ShareButton from './Share/ShareButton';
import DuplicateExamButton from './DuplicateExamButton';
import { getExamStatusChip } from '../utils';

const MAX_TITLE_CHARS = 60;

const ExamDataGrid = ({ exams, deleteExam }) => {
    const { isRTL, languageData } = useLanguage();
    const navigate = useNavigate();
    const { openSnackBar } = useSnackBar();
    const { user, isFromLMS } = useUser(); // todo: show a linked icon if its assigned to lms activity + is from lms

    // Define the columns for the DataGrid
    const columns = [
        {
            field: 'title',
            headerName: languageData?.history?.exams_table?.title ?? 'Title',
            width: 250,
            renderCell: (params) => {
                const title = params.value || '';
                const shortenedTitle =
                    title.length > MAX_TITLE_CHARS
                        ? `${title.slice(0, MAX_TITLE_CHARS)}...`
                        : title;
                return (
                    <Tooltip title={title} arrow>
                        <span>{shortenedTitle}</span>
                    </Tooltip>
                );
            },
        },
        { field: 'date', headerName: languageData?.history?.exams_table?.date ?? 'Date', width: 150 },
        {
            field: 'responses',
            headerName: languageData?.history?.exams_table?.responses ?? 'Responses',
            width: 120,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <PeopleIcon fontSize="small" />
                    {params.value || 0}
                </Box>
            ),
        },
        // { field: 'questions', headerName: 'Questions', width: 120 },
        // {
        //     field: 'difficulty',
        //     headerName: 'Difficulty',
        //     width: 120,
        //     renderCell: (params) =>
        //         params.value ? capitalizeFirstLetter(params.value) : '',
        // },
        // {
        //     field: 'source',
        //     headerName: 'Source',
        //     width: 120,
        //     renderCell: (params) =>
        //         params.value ? capitalizeFirstLetter(params.value) : '',
        // },
        // {
        //     field: 'language',
        //     headerName: 'Language',
        //     width: 120,
        //     renderCell: (params) =>
        //         params.value ? capitalizeFirstLetter(params.value) : '',
        // },
        {
            field: 'status',
            headerName: languageData?.history?.exams_table?.status ?? 'Status',
            width: 150,
            renderCell: (params) => {
                // Use the provided utility to render a status chip based on exam times.
                return getExamStatusChip({
                    startsAt: params.row.startsAt,
                    endsAt: params.row.endsAt,
                });
            },
        },
        {
            field: 'actions',
            headerName: languageData?.history?.exams_table?.actions ?? 'Actions',
            minWidth: 300,
            sortable: false,
            filterable: false,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <ActionsCell
                    exam={params.row}
                    deleteExam={deleteExam}
                    navigate={navigate}
                    user={user}
                    isFromLMS={isFromLMS}
                    openSnackBar={openSnackBar}
                />
            ),
        },
    ];

    // Map exams to rows (ensure each row has a unique id)
    const rows = exams.map((exam, index) => ({
        id: exam._id || index,
        title: exam.title,
        date: exam.date ?? '',
        responses: exam.responses_count ?? 0,
        questions: exam?.questions?.length ?? 0,
        difficulty: exam?.parameters?.difficulty ?? '',
        source: exam?.parameters?.source ?? '',
        language: exam?.parameters?.language ?? '',
        startsAt: exam?.settings?.start_time ?? '',
        endsAt: exam?.settings?.end_time ?? '',
    }));

    return (
        <Box sx={{ height: 'auto', width: '100%', px: { xs: 2, sm: 4, md: 6 }, py: 2, direction: isRTL ? 'rtl' : 'ltr' }}>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={10} md={12} lg={12}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={20}
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 25, page: 0 },
                            },
                        }}
                        rowsPerPageOptions={[10, 20, 50]}
                        disableSelectionOnClick
                        sx={{
                            '& .MuiDataGrid-cell': {
                                width: 'auto',
                                direction: isRTL ? 'rtl' : 'ltr',
                                textAlign: isRTL ? 'right' : 'left',
                            },
                            '& .MuiDataGrid-toolbarContainer': {
                                direction: 'ltr', // Forces the toolbar to always be LTR
                            },
                            '& .MuiDataGrid-footerContainer': {
                                direction: 'ltr'
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

const ActionsCell = ({
    exam,
    deleteExam,
    navigate,
    user,
    isFromLMS,
    openSnackBar,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { languageData } = useLanguage()

    const moveToPreview = () => {
        if (!exam.id) {
            openSnackBar(SnackbarTypes.ERROR.field);
            return;
        }
        navigate(`/preview?id=${exam.id}`);
    };

    const moveToReview = () => {
        if (!exam.id) {
            navigate('/exam');
            return;
        }
        navigate(`/review?id=${exam.id}`);
    };

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleDeleteExam = () => {
        deleteExam(exam.id);
        handleCloseMenu();
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
            <Button onClick={moveToReview} variant="contained" size="small" startIcon={<RateReviewIcon />} sx={{ gap: 1 }}>
                {languageData?.review_btn_label || 'Review'}
            </Button>
            <Button onClick={moveToPreview} variant="contained" color="secondary" size="small" startIcon={<VisibilityIcon />} sx={{ gap: 1 }}>
                {languageData?.preview_btn_label || 'Preview'}
            </Button>
            <IconButton onClick={handleOpenMenu} size="small">
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                <MenuItem>
                    <ShareButton
                        languageData={languageData?.preview}
                        examId={exam.id}
                        variant="text"
                        icon={<ShareIcon />}
                        color="primary"
                        fromLMS={isFromLMS}
                    />
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleCloseMenu}>
                    <DuplicateExamButton examId={exam.id} userId={user?._id} />
                </MenuItem>
                <Divider />
                <MenuItem sx={{ color: 'red' }} onClick={handleDeleteExam}>
                    <Button startIcon={<DeleteIcon />} size="small" sx={{ color: 'red' }}>
                        {languageData?.delete_btn_label || 'Delete'}
                    </Button>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default ExamDataGrid;
