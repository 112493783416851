import { HttpStatusCode } from 'axios';
import React, { useEffect, useState } from 'react';
import { Avatar, Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container, Divider, Stack, IconButton } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailIcon from '@mui/icons-material/Email';
// import ContinueWithGoogleButton from '../components/GoogleAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { validateEmail, validatePassword } from '../utils/inputValidations';
//hooks:
import useSnackBar from '../hooks/useSnackBar';
import { useUser } from '../hooks/useUser';
import { getOrgProfile, postOrgSignup } from '../requests/organizations';
import { imgSrcPrefix } from '../components/B2B/UploadImage';
import { getTempToken } from '../requests/token';
import { homepage, onboarding } from '../components/menu/MenuLinks';
import { SnackbarTypes } from '../utils/snackbarTypes';
import Header from '../components/Header';
import { useLanguage } from '../contexts/languageContext';
import { IdmMoeILButton } from '../components/IdmMoeILButton';
import { colors } from '../utils/customTheme';
import SSO from '../utils/ssoTypes';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function OrgSignUp() {
    // Hooks:
    const navigate = useNavigate();
    const userContext = useUser()
    const location = useLocation();
    const { openSnackBar } = useSnackBar();
    const { languageData, isRTL, preFetchLanguageData } = useLanguage();
    const queryParams = new URLSearchParams(location.search);
    const orgId = queryParams.get('id')
    const [org, setOrg] = useState(null);

    async function init() {
        if (!orgId)
            navigate('/signup')
        else {
            const res = await userContext.isLoggedIn()
            if (res)
                navigate(homepage)
            else {
                const token = await getTempToken(orgId)
                if (token) {
                    getOrgProfile(orgId, token).then(org => {
                        if (org) {
                            setOrg(org)
                            return
                        }
                    })
                    preFetchLanguageData()
                }
                else
                    navigate('/signup')
            }
        }
    }

    useEffect(() => {
        init()
    }, [])

    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [isFormValid, setisFormValid] = useState({ firstName: false, lastName: false, email: false, password: false });
    const [showForm, setShowForm] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    // ################# Form Functions #################
    const handleTextFieldChange = (event) => {
        const { name, value } = event.target;
        let newIsFormValid = { ...isFormValid };

        switch (name) {
            case 'firstName':
                newIsFormValid[name] = value.length > 0;
                break;
            case 'lastName':
                newIsFormValid[name] = value.length > 0;
                break;
            case 'email':
                newIsFormValid[name] = handleValidateEmail(value);
                break;
            case 'password':
                newIsFormValid[name] = value.length >= 6;
                break;
            default:
                break;
        }

        setisFormValid(newIsFormValid);
    };
    const handleValidateEmail = (email) => {
        return validateEmail(email) && ((org && !org.use_whitelist && org?.email_settings?.email_regex) ? validateEmailWithRegex(email, org?.email_settings?.email_regex) : true)
    }
    const checkValidForm = (obj) => {
        const { firstName, lastName, email, password } = obj
        return firstName && lastName && email && password
    }

    const handleSubmitInvalid = async (event) => {
        event.preventDefault();
        openSnackBar(SnackbarTypes.FORM_INVALID.field)
    }

    const validateEmailWithRegex = (email, regex) => {
        const emailRegex = new RegExp(regex);
        return emailRegex.test(email);
    };

    const handleEmailChange = (event) => {
        const input = event.target.value;
        if (input === '') {
            setEmailError(false);
            return;
        }
        setEmailError(!handleValidateEmail(input));
    };

    const handlePasswordChange = (event) => {
        const input = event.target.value;
        setPasswordError(!validatePassword(input));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!checkValidForm(isFormValid)) {
            openSnackBar(SnackbarTypes.FORM_INVALID.field)
            return
        }
        setIsLoading(true);
        const data = new FormData(event.currentTarget);
        const payload = {
            user: {
                email: data.get('email'),
                password: data.get('password'),
                firstname: data.get('firstName'),
                lastname: data.get('lastName'),
                orgId: orgId
            }
        }

        postOrgSignup(payload, openSnackBar).then(user => {
            userContext.login(user)
            navigate(onboarding);
        }).catch(err => {
            console.error(err)
            if (err.response.status === HttpStatusCode.Unauthorized) {
                openSnackBar(SnackbarTypes.UNAUTHORIZED_SIGNUP.field)
            } else if (err.response.status === HttpStatusCode.Conflict) {
                openSnackBar(SnackbarTypes.CONFLICT.field)
                navigate('/login')
            } else {
                openSnackBar(SnackbarTypes.ERROR.field)
            }
        }).finally(() => {
            setIsLoading(false);
        })
    };

    function handleIdmMoePreClick() {
        localStorage.setItem('orgId', orgId);
    }
    const handleEmailSignInToggle = () => {
        setShowForm(prevShowForm => !prevShowForm);
    };
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <>
            <Header />
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '15px',
                        direction: isRTL ? 'rtl' : 'ltr'
                    }}
                >
                    {org?.logo ?
                        <Avatar
                            alt='logo'
                            src={imgSrcPrefix + org.logo}
                            sx={{ width: 'auto', height: '150px', mt: 1, borderRadius: 0, backgroundColor: 'transparent' }}
                            slotProps={{ img: { sx: { objectFit: 'contain' } } }}
                        /> : <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}><LockOutlinedIcon /></Avatar>
                    }
                    {/* <Typography component="h1" variant="h5">
                    Sign up
                </Typography> */}
                    {!org?.logo && org?.name &&
                        <Typography variant="body1">{org.name}</Typography>
                    }
                    {showForm && <Box component="form" noValidate onSubmit={checkValidForm(isFormValid) ? handleSubmit : handleSubmitInvalid} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label={languageData?.sign_up?.first_name_label ?? "First Name"}
                                    onChange={handleTextFieldChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label={languageData?.sign_up?.last_name_label ?? "Last Name"}
                                    name="lastName"
                                    autoComplete="family-name"
                                    onChange={handleTextFieldChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    type='email'
                                    label={languageData?.sign_up?.email_label ?? "Email Address"}
                                    name="email"
                                    onChange={handleEmailChange}
                                    error={emailError}
                                    helperText={emailError ?
                                        languageData?.sign_up?.invalid_email_helper_text ?? 'invalid email' :
                                        languageData?.sign_up?.org_email_helper_text ?? 'Please use your organization email'}
                                    autoComplete="email"
                                    onInput={handleTextFieldChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    onInput={handleTextFieldChange}
                                    name="password"
                                    label={languageData?.sign_up?.password_label ?? "Password"}
                                    onChange={handlePasswordChange}
                                    type={showPassword ? 'text' : 'password'}
                                    error={passwordError}
                                    helperText={
                                        passwordError ? languageData?.sign_up?.password_helper_Text ?? 'Password must be at least 6 characters long' : ''
                                    }
                                    id="password"
                                    autoComplete="new-password"
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                sx={{ backgroundColor: 'transparent' }}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {isLoading ? <CircularProgress color='inherit' size={24} /> : "Sign Up"}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider>{languageData?.preview?.compare_dialog?.or || "or"}</Divider>
                            </Grid>
                        </Grid>
                    </Box>}
                    <Grid item xs={12}>
                        <Stack spacing={2} sx={{ alignItems: 'center', mt: 3 }}>
                            {SSO.isMoeIL(org?.sso_type) && <IdmMoeILButton preClick={handleIdmMoePreClick} />}
                            <Button variant="outlined" sx={{ backgroundColor: 'white', color: colors.main, width: '-webkit-fill-available', }} startIcon={!isRTL ? <EmailIcon /> : null} onClick={handleEmailSignInToggle}>
                                {languageData?.sign_up?.email ?? 'Sign In With Email'}
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid container justifyContent="center" textAlign="center">
                        <Grid item xs={12} pl={2}>
                            <Link href='/login' variant="body2">
                                {languageData?.sign_up?.already_account ?? 'Already have an account? Log in'}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}