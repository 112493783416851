import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useSchoolRegistrationDialog } from "./useSchoolRegistrationDialog";
import { buttonsIds } from "../../../requests/analytics";

export default function SchoolRegistrationDialogEn() {
    const { open, setOpen, handleRegisterClick } = useSchoolRegistrationDialog({
        showKey: buttonsIds.showSchoolRegisterationDialogEnKey,
        clickKey: buttonsIds.clickSchoolRegisterationDialogEnKey,
    });

    return (
        <Dialog open={open} onClose={() => setOpen(false)} dir='ltr'>
            <DialogTitle>Join with Your School!</DialogTitle>
            <DialogContent>
                <p>
                    Hey 👋<br />
                    We’d like to invite you to register your school and receive a special offer.<br />
                    Join the leading platform for smart and innovative learning! ✨
                </p>
                <p style={{ marginTop: '16px' }}>Don't miss out – let’s see how it can work for you!</p>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button onClick={() => handleRegisterClick("https://forms.gle/gBncH9jdmWuhhmwt9")} variant="contained" color="primary">
                    Register Your School 🚀
                </Button>
            </DialogActions>
        </Dialog>
    );
}
