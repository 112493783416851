import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

const TermsOfService = () => {
    return (
        <>
            <Helmet>
                <title>StudyWise | Terms of Service</title>
                <meta name="description" content="Read our Terms of Service to understand the rules and guidelines for using our AI-powered exam generator. We are committed to providing a reliable and secure platform for all users." />
            </Helmet>
            <Grid container spacing={2} sx={{ padding: '50px' }}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center" sx={{ fontWeight: "bolder", fontSize: "40px" }}>
                        Terms of Service - StudyWise.io
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>1. Acceptance of Terms</strong></p>
                        <p>Welcome to StudyWise.io! By using our website and services, you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms, please refrain from using our website.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>2. Use of Service</strong></p>
                        <p><strong>2.1. User Accounts:</strong> When you create an account on StudyWise.io, you are responsible for providing accurate and up-to-date information. You are also responsible for maintaining the confidentiality of your account credentials.</p>
                        <p><strong>2.2. Prohibited Activities:</strong></p>
                        <p>a. Violating any applicable laws or regulations.</p>
                        <p>b. Uploading, sharing, or generating content that is illegal, offensive, or violates the rights of others.</p>
                        <p>c. Attempting to gain unauthorized access to our systems or other users' accounts.</p>
                        <p>d. Using StudyWise.io for any commercial or marketing purposes without our prior written consent.</p>
                        <p><strong>2.3. Google Integration:</strong> When you choose to export exams to Google Forms, you grant StudyWise.io access to your Google account for this specific purpose. Your Google account data will only be used for exam export and authentication and will not be accessed or used for any other purpose.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>3. Privacy</strong></p>
                        <p>Please review our <a href='/privacy-policy'>Privacy Policy</a> to understand how we collect, use, and protect your personal information.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>4. Intellectual Property</strong></p>
                        <p><strong>4.1. Content Ownership:</strong> You acknowledge that all data generated using StudyWise.io is owned by StudyWise.io. Users do not retain ownership of the generated content.</p>
                        <p><strong>4.2. License:</strong> By using StudyWise.io, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and distribute your content solely for the purpose of providing our services.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                    <p><strong>5. Data Security</strong></p>
                    <p>We are committed to protecting the security of your personal information. We implement reasonable and appropriate security measures to protect your data from unauthorized access, use, or disclosure. However, no security measures are perfect, and we cannot guarantee absolute security.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>6. Termination</strong></p>
                        <p>We reserve the right to terminate or suspend your account at our discretion, without notice, for any violations of these Terms of Service.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>7. Changes to Terms</strong></p>
                        <p>We may update these Terms of Service from time to time. You will be notified of significant changes via email or a notice on our website.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>8. Contact Information</strong></p>
                        <p>If you have any questions, concerns, or requests regarding these Terms of Service, please contact us at <a href="mailto:support@studywise.io">support@studywise.io</a>.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>9. Disclaimer</strong></p>
                        <p>While StudyWise.io strives to provide a reliable and high-quality service, it is offered on an "as is" basis without any warranties of any kind, either express or implied. We do not guarantee that the service will be uninterrupted, secure, or error-free. Your use of the service is at your own risk, and we encourage you to review our policies regularly.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>10. User Responsibilities</strong></p>
                        <p>While StudyWise.io provides tools to assist users, it is essential that you review and verify all content generated by our services. You are responsible for ensuring that the content is appropriate and complies with applicable laws and regulations.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>12. Data Privacy Protection</strong></p>
                        <p>StudyWise takes data privacy protection seriously. We do not collect or process personally identifiable information unless explicitly provided by users. For more information on how we handle your data, please refer to our Privacy Policy.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>13. Limitation of Liability</strong></p>
                        <p>To the fullest extent permitted by law, StudyWise shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the service. This includes, but is not limited to, loss of profits, data, or goodwill.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>14. Data Collection</strong></p>
                        <p>StudyWise collects various types of personal data when you use our Services, including but not limited to your name, email address, and usage data. We take steps to minimize the collection of personal data to only what is necessary to provide our services.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>15. User Rights</strong></p>
                        <p>As a user, you have the right to access, correct, or delete your personal information. You may also request information about how your data is used and shared. To exercise these rights, please contact us at support@studywise.io.</p>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p><strong>16. Third-Party Services</strong></p>
                        <p>We may engage third-party service providers to assist us in providing our Services. These third parties are obligated to protect your personal information and may only use it for the purposes for which it was disclosed. We do not share your personal data with third parties for their own marketing purposes.</p>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" sx={{ fontSize: "20px" }}>
                        <p>By using StudyWise.io, you agree to the terms outlined in these Terms of Service. Thank you for using StudyWise.io!</p>
                        <p>StudyWise | <a href="https://studywise.io">www.studywise.io</a></p>
                    </Typography>
                </Grid>

            </Grid>
        </>
    );
};

export default TermsOfService;
