import React, { useState } from 'react';
import { Card, CardContent, Typography, Checkbox, Box } from '@mui/material';
import SettingsPanel from './SettingsPanel';

export const MergeTypes = {
    whole: 'whole',
    random: 'random',
    none: 'none'
}
const ExamList = ({ exams, selectedExams, handleToggleExam }) => {
    const [randomCounts, setRandomCounts] = useState({});

    const handleCheckboxChange = (event, examId) => {
        handleToggleExam(examId, event.target.checked ? MergeTypes.whole : null, randomCounts[examId] || 0);
    };

    const handleRandomCountChange = (count, examId) => {
        setRandomCounts(prevCounts => ({ ...prevCounts, [examId]: count }));
        handleToggleExam(examId, MergeTypes.random, count);
    };

    const handleRadioChange = (event, examId) => {
        const type = event.target.value;
        handleToggleExam(examId, type, randomCounts[examId] || 0);
    }

    return (
        <div>
            {exams.map(exam => {
                const selected = selectedExams[exam._id];
                return (
                    <Card key={exam._id} style={{ marginBottom: '10px', backgroundColor: !!selected ? '#e8f3ff' : 'transparent' }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'start' }}>
                            <Checkbox
                                checked={!!selected}
                                onChange={(e) => handleCheckboxChange(e, exam._id)}
                            />
                            <Box>
                                <Typography variant="h6" component="div">
                                    {exam.title}
                                </Typography>
                                <Typography color="text.secondary">
                                    {exam.date}
                                </Typography>
                            </Box>
                        </CardContent>
                        <SettingsPanel
                            examId={exam._id}
                            selected={selected}
                            handleRadioChange={(event) => handleRadioChange(event, exam._id)}
                            handleRandomCountChange={(count) => handleRandomCountChange(count, exam._id)}
                            randomCount={randomCounts[exam._id] || ''}
                        />
                    </Card>
                );
            })}
        </div>
    );
};

export default ExamList;
