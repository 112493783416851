import React, { useState, useEffect, useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, Typography, CircularProgress, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; // Import eye icons
import APIKeys from '../../requests/ApiKeys';
import { DeleteConfirmationPopver } from '../../components/DeleteConfirmationPopover';
import { isMobile } from '../../utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const ApiKeysTable = ({ orgId }) => {
    const [apiKeys, setApiKeys] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newKey, setNewKey] = useState({ secret: '', expiration: null });
    const [loaders, setLoaders] = useState({ deactivate: {}, create: false, page: true });
    const [anchorEl, setAnchorEl] = useState(null);
    const keyToDeactivate = useRef(null);
    const [showApiKey, setShowApiKey] = useState({}); // To manage visibility of API keys

    useEffect(() => {
        // Fetch API keys
        setLoaders({ ...loaders, page: true })
        APIKeys.fetchAllKeys(orgId).then(data => {
            if (data?.api_keys) {
                setApiKeys(data.api_keys)
            }
        }).catch((error) => console.error('Error fetching API keys:', error))
            .finally(() => setLoaders({ ...loaders, page: false }))
    }, [orgId]);

    const handleCreateKey = async () => {
        // Create new API key
        setLoaders(prevLoaders => ({ ...prevLoaders, create: true }));
        const keyData = {
            secret: newKey.secret,
            expiration: new Date(newKey.expiration)
        }
        return APIKeys.createKey(orgId, keyData).then((data) => {
            setApiKeys((prevKeys) => [...prevKeys, data.key]); // Add new key to table
            setIsDialogOpen(false);
            setNewKey({ secret: '', expiration: '' }); // Reset form
        }).catch((error) => console.error('Error creating API key:', error))
            .finally(() => setLoaders(prevLoaders => ({ ...prevLoaders, create: false })));
    };

    const handleDeactivateKey = async (apiKey) => {
        // Deactivate API key
        setAnchorEl(null);
        setLoaders(prevLoaders => ({ ...prevLoaders, deactivate: { [apiKey]: true } }));
        return APIKeys.deactivateKey(orgId, apiKey).then(() => {
            // Update state
            setApiKeys((prevKeys) =>
                prevKeys.map((key) =>
                    key.api_key === apiKey ? { ...key, active: false } : key
                )
            );
        }).catch((error) => console.error('Error deactivating API key:', error))
            .finally(() => setLoaders(prevLoaders => ({ ...prevLoaders, deactivate: { [apiKey]: false } })));
    };

    const handleToggleShowApiKey = (apiKey) => {
        setShowApiKey(prevState => ({
            ...prevState,
            [apiKey]: !prevState[apiKey] // Toggle visibility of the API key
        }));
    };

    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <Button variant="contained" onClick={() => setIsDialogOpen(true)}>
                    Create New Key
                </Button>
            </Grid>

            <Grid item>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>API Key</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Expiration</TableCell>
                                <TableCell>Active</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loaders.page ? <CircularProgress /> : apiKeys.map((key) => (
                                <TableRow key={key.api_key}>
                                    <TableCell sx={{ maxWidth: '65rem', overflowWrap: isMobile ? 'normal' : 'anywhere' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            {showApiKey[key.api_key] ? key.api_key : '••••••••••••••••••••'}
                                            <IconButton onClick={() => handleToggleShowApiKey(key.api_key)}>
                                                {showApiKey[key.api_key] ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                    <TableCell>{new Date(key.created_at).toLocaleString()}</TableCell>
                                    <TableCell>{new Date(key.expiration).toLocaleString()}</TableCell>
                                    <TableCell>{key.active && (new Date(key.expiration).getTime() > new Date().getTime()) ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            disabled={!key.active}
                                            onClick={e => {
                                                setAnchorEl(e.currentTarget)
                                                keyToDeactivate.current = key.api_key
                                            }}
                                        >
                                            {loaders.deactivate[key.api_key] ? <CircularProgress size={24} /> : "Deactivate"}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <CreateApiKeyDialog
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                newKey={newKey}
                setNewKey={setNewKey}
                handleCreateKey={handleCreateKey}
                isLoading={loaders.create}
            />
            <DeleteConfirmationPopver
                type="dialog"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                onCancel={() => setAnchorEl(null)}
                onDelete={() => handleDeactivateKey(keyToDeactivate.current)}
            />
        </Grid>
    );
};

export default ApiKeysTable;

function CreateApiKeyDialog({ isDialogOpen, setIsDialogOpen, newKey, setNewKey, handleCreateKey, isLoading }) {
    return (
        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
            <DialogTitle>Create New API Key</DialogTitle>
            <DialogContent>
                <TextField
                    label="Secret"
                    fullWidth
                    margin="dense"
                    value={newKey.secret}
                    onChange={(e) => setNewKey({ ...newKey, secret: e.target.value })}
                />
                <TextField
                    label="Expiration Date"
                    type="date"
                    fullWidth
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    value={newKey.expiration}
                    onChange={(e) => setNewKey({ ...newKey, expiration: e.target.value })}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
                <Button variant="contained" onClick={handleCreateKey}>
                    {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : "Create"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
