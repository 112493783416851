import { useLanguage } from '../contexts/languageContext';
import { Grid, Paper, Typography } from '@mui/material';
import { getFontSize, isMobile } from '../utils';
import { useMemo } from 'react';
const MAX_RESOURCES = 3;

function ResourceList({ resources, setSelectedResourceIds, selectedResourceIds }) {
    const { languageData, isRTL } = useLanguage();
    const sortedResources = useMemo(() => [...resources].sort((a, b) => a.name.localeCompare(b.name)), [resources]);
    const handleSelectResource = (resourceId) => {
        // If resource is already selected, remove it
        if (selectedResourceIds.includes(resourceId)) {
            setSelectedResourceIds(selectedResourceIds.filter(id => id !== resourceId));
            return;
        }

        // Check if we've reached the maximum number of resources
        if (selectedResourceIds.length >= MAX_RESOURCES) {
            return;
        }

        // Add the resource
        setSelectedResourceIds([...selectedResourceIds, resourceId]);
    }

    if (!resources?.length) return null;

    return (
        <Grid item xs={12} md={5.5} style={{ minHeight: '100%' }}>
            <Paper style={{ padding: '20px', display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
                <Typography variant="h6" style={{ marginLeft: '10px' }}>
                    {languageData?.resource_list?.title ?? "Use existing resource:"}
                </Typography>
                <Typography variant="subtitle1" style={{ color: 'gray', marginLeft: '10px', direction: isRTL ? 'rtl' : 'ltr', fontSize: isMobile ? '' : getFontSize() }}>
                    {languageData?.resource_list?.subtitle ?? "Use a resource you've already uploaded to generate the exam."}
                </Typography>
                <div style={{ flexGrow: 1, border: '1px solid #e0e0e0', borderRadius: '5px', marginTop: '16px', padding: '16px', paddingBottom: '32px', overflowY: 'auto', maxHeight: '50vh' }}>
                    <Grid container sx={{ justifyContent: 'flex-start', height: '100%' }}>
                        {sortedResources.map((resource, index) => {
                            const isSelected = selectedResourceIds.includes(resource.id);
                            const border = isSelected ? '2px solid #3f51b5' : 'none';
                            const isDisabled = !isSelected && selectedResourceIds.length >= MAX_RESOURCES;
                            return (
                                <Grid item xs={12} md={6} lg={4} key={index} sx={{ mb: 2 }}>
                                    <Paper
                                        key={index}
                                        sx={{
                                            padding: '4px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            cursor: isDisabled ? 'not-allowed' : 'pointer',
                                            height: '100%',
                                            justifyContent: 'center',
                                            border,
                                            mx: 1,
                                            backgroundColor: isSelected ? '#f5f5f5' : isDisabled ? '#f5f5f5' : 'white',
                                            opacity: isDisabled ? 0.6 : 1,
                                        }}
                                        elevation={3}
                                        square={false}
                                        onClick={() => !isDisabled && handleSelectResource(resource.id)}
                                    >
                                        <Typography variant="subtitle1" textAlign="center">
                                            {resource.name}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                {selectedResourceIds.length > 0 && (
                    <Typography variant="body2" style={{ color: 'gray', marginTop: '8px', textAlign: 'right' }}>
                        {selectedResourceIds.length}/{MAX_RESOURCES} {languageData?.resource_list?.selected ?? 'resources selected'}
                    </Typography>
                )}
            </Paper>
        </Grid>
    )
}

export default ResourceList;