import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { useManagerDashboard } from '../../contexts/ManagerDashboardContext';
import { logErrorToServer } from '../../requests/error';

const Teachers = ({ orgId }) => {
    const { fetchOrgUsers, managerDashboardData } = useManagerDashboard();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (orgId && !managerDashboardData?.users) {
            setLoading(true);
            fetchOrgUsers(orgId).catch((e) => {
                logErrorToServer('Error fetching org users:', e);
                setError('Failed to fetch teachers');
            }).finally(() => setLoading(false));
        }
    }, [orgId]);

    // Memoizing hasInstitute to prevent unnecessary re-computations
    const hasInstitute = useMemo(() => {
        return managerDashboardData?.users?.some(user => user.org_details?.institute_name);
    }, [managerDashboardData?.users]);

    // Memoize columns to ensure stability
    const columns = useMemo(() => [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'total_exams', headerName: 'Total Exams', type: 'number', flex: 1 },
        { field: 'total_responses', headerName: 'Total Responses', type: 'number', flex: 1 },
        { field: 'login_timestamps', headerName: 'Total Logins', type: 'number', flex: 1 },
        { field: 'last_login_date', headerName: 'Last Login', type: 'dateTime', flex: 1 },
        { field: 'last_exam_created_date', headerName: 'Last Exam Created', type: 'dateTime', flex: 1 },
        { field: 'last_response_date', headerName: 'Last Response Taken', type: 'dateTime', flex: 1 },
        ...(hasInstitute ? [{ field: 'institute', headerName: 'Institute Name', flex: 1 }] : []),
    ], [hasInstitute]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography variant="body1" color="error">{error}</Typography>;
    }

    if (managerDashboardData?.users?.length === 0) {
        return <Typography variant="body1">No users found for this organization.</Typography>;
    }

    // Prepare the rows
    const rows = managerDashboardData?.users.map((teacher) => ({
        id: teacher._id,
        name: teacher.fullname,
        email: teacher.email,
        total_exams: teacher.total_exams,
        total_responses: teacher?.total_responses,
        login_timestamps: teacher.login_timestamps,
        institute: teacher.org_details?.institute_name,
        last_response_date: teacher?.last_response_date ? new Date(teacher.last_response_date) : null,
        last_login_date: teacher?.last_login_date ? new Date(teacher.last_login_date) : null,
        last_exam_created_date: teacher?.last_exam_created_date ? new Date(teacher.last_exam_created_date) : null,
    }));

    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid
                columns={columns}
                rows={rows}
                slots={{ toolbar: GridToolbar }}
                slotProps={{ toolbar: { csvOptions: { utf8WithBom: true } } }}
            />
        </div>
    );
};

export default Teachers;
