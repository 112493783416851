import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl, FormLabel } from '@mui/material';
import ExamList from './ExamList';
import useHistory from '../../hooks/useHistory';
import { useDebounce } from '../../hooks/useDebounce';
import { useLanguage } from '../../contexts/languageContext';

const MergeDialog = ({ open, onClose, onMerge, currentExamId }) => {
    const { isRTL, languageData } = useLanguage();
    const { history, fetchHistory } = useHistory();
    const [filteredHistory, setFilteredHistory] = useState([]);
    const [selectedExams, setSelectedExams] = useState({}); // Store selection type and count
    const divideGrades = useRef(false);
    const debouncedSearch = useDebounce(handleChangeSearch, 400);

    useEffect(() => {
        if (open && !history) {
            fetchHistory();
        }
        setSelectedExams({})
        divideGrades.current = false;
    }, [open]);

    useEffect(() => {
        setFilteredHistory(history);
    }, [history]);

    const handleToggleExam = (examId, type, count) => {
        setSelectedExams(prevSelectedExams => {
            let newSelectedExams = { ...prevSelectedExams };
            if (!type || (newSelectedExams[examId] && newSelectedExams[examId].type === type && newSelectedExams[examId].count === count)) {
                delete newSelectedExams[examId]; // Deselect if the same option is chosen again
            } else {
                newSelectedExams[examId] = { type, count };
            }
            return newSelectedExams;
        });
    };

    function handleChangeSearch(e) {
        const value = e.target.value;
        setFilteredHistory(history.filter(exam => exam.title.toLowerCase().includes(value.toLowerCase())));
    }

    const handleMerge = () => {
        const examsToMerge = Object.keys(selectedExams).filter(examId => selectedExams[examId]);
        onMerge(examsToMerge.map(examId => ({
            examId,
            type: selectedExams[examId].type,
            count: selectedExams[examId].count
        })), divideGrades.current);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} slotProps={{ root: { sx: { direction: isRTL ? 'rtl' : 'ltr' } } }}>
            <DialogTitle>{languageData?.preview?.merge_dialog?.title ?? 'Choose exams to merge'}</DialogTitle>
            <DialogContent>
                <Typography variant="body1" sx={{ marginBottom: '10px' }}>{languageData?.preview?.merge_dialog?.explanation ?? "Selected exams will be merged into the current exam in preview page"}</Typography>
                <TextField type="text" label={languageData?.search_exam_label ?? 'Search Exam'} fullWidth sx={{ marginBottom: '15px' }} onChange={debouncedSearch} />
                <FormControlLabel
                    control={<Checkbox onChange={e => divideGrades.current = e.target.checked} />}
                    label={languageData?.preview?.merge_dialog?.divide_grade ?? "Divide grades among questions"}
                />

                {!history || history.length === 0 ? (
                    <p style={{ padding: '10px' }}>{languageData?.preview?.merge_dialog?.fetching_msg ?? 'Fetching exams to merge...'}</p>
                ) : (filteredHistory?.length > 0 ? (
                    <ExamList exams={filteredHistory.filter(exam => exam._id !== currentExamId)} selectedExams={selectedExams} handleToggleExam={handleToggleExam} />
                ) : (
                    <p style={{ padding: '10px' }}>{languageData?.preview?.merge_dialog?.empty_msg ?? 'No exams found'}</p>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{languageData?.preview?.merge_dialog?.cancel_button ?? 'Cancel'}</Button>
                <Button onClick={handleMerge} variant='contained' color="primary">{languageData?.preview?.merge_dialog?.merge_button ?? 'Merge'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default MergeDialog;