// B2BDashboard.js
import React, { useState, useEffect, useMemo } from 'react';
import { Container, Typography, AppBar, Tabs, Tab, FormControl, Button, Autocomplete, TextField } from '@mui/material';
import OrgForm from '../../components/B2B/OrgForm';
import { useNavigate } from 'react-router-dom';
import { useOrgDashboard } from '../../contexts/Dashboard/OrgContext';
import DashboardTable from '../../components/Dashboard/DashboardTable';
import { DownloadCSVButton } from '../../components/B2B/DownloadCSV';
import ApiKeysTable from './APIKeys';
import { isMobile } from '../../utils';

const b2bTabs = {
    add: 0,
    edit: 1,
    analytics: 2,
    api_keys: 3
}
const B2BDashboard = () => {
    const navigate = useNavigate();
    const { orgDashboardData, fetchOrgDashboardData, fetchOrgUsers } = useOrgDashboard()
    const { orgs } = orgDashboardData || {}
    const [tabValue, setTabValue] = useState(b2bTabs.add);
    const [selectedOrgId, setSelectedOrgId] = useState(null);
    const selectedOrg = useMemo(() => orgs?.find(org => org._id === selectedOrgId), [selectedOrgId, orgs])

    useEffect(() => {
        fetchOrgDashboardData().catch(e => console.error(e))
    }, []);

    useEffect(() => {
        // Fetch users everytime org is changed
        if (tabValue === b2bTabs.analytics && selectedOrg && !selectedOrg.users) {
            fetchOrgUsers(selectedOrgId).catch(e => console.error('could not fetch org users | ', e))
        }
    }, [selectedOrg, tabValue])

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        // setSelectedOrgId(null); // Reset selectedOrgId when switching tabs
    };

    const orgsDropdown = useMemo(() => (
        <FormControl fullWidth>
            <Autocomplete
                options={orgs || []}
                getOptionLabel={(org) => org.name || ""}
                value={orgs?.find((org) => org._id === selectedOrgId) || null}
                onChange={(e, newValue) => setSelectedOrgId(newValue?._id || null)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={`Choose Organization (${orgs?.length || 0})`}
                    />
                )}
                isOptionEqualToValue={(option, value) => option._id === value._id}
            />
        </FormControl>
    ), [orgs?.length, selectedOrgId])


    return orgDashboardData && (
        <Container sx={{ mt: 4, mb: 4 }} style={{ maxWidth: isMobile ? '100vw' : '80vw' }}>
            <Button variant='outlined' sx={{ display: 'block', marginBottom: '20px', marginX: 'auto', width: '20%', minWidth: '150px' }} onClick={() => navigate('/dashboard')}>Back to Dashboard</Button>
            <Typography variant="h4" align="center" sx={{ mb: 2 }}>
                B2B Dashboard
            </Typography>
            <AppBar position="static" sx={{ mb: 2, backgroundColor: 'white', boxShadow: 'none' }}>
                <Tabs value={tabValue} onChange={handleChange} centered indicatorColor="primary" textColor="primary">
                    <Tab label="Add" />
                    <Tab label="Edit" />
                    <Tab label="Analytics" />
                    <Tab label="API Keys" />
                </Tabs>
                {!(orgs?.length) && <Typography variant='body1'>Getting organizations...</Typography>}
            </AppBar>
            {tabValue === b2bTabs.add && (
                <TabPanel value={tabValue} index={0}>
                    <OrgForm />
                </TabPanel>
            )}
            {tabValue === b2bTabs.edit && (
                <TabPanel value={tabValue} index={1}>
                    {orgsDropdown}
                    {selectedOrgId && <OrgForm org={selectedOrg} />}
                </TabPanel>
            )}
            {tabValue === b2bTabs.analytics && (
                <TabPanel value={tabValue} index={2}>
                    {orgsDropdown}
                    <DownloadCSVButton
                        list={selectedOrg?.users?.map(user => ({ "שם מלא": user.fullname, "מייל": user.email, "סהכ מבחנים": user.total_exams }))}
                        filename={`${selectedOrg?.name} דוח משתמשים`}
                    />
                    {selectedOrg && (selectedOrg.users
                        ? <DashboardTable
                            items={selectedOrg.users}
                            tableName={selectedOrg.name + " users"}
                            orderByField={'total_exams'}
                            sortableFields={['exams_left', 'login_timestamps', 'total_exams', 'total_responses']}
                            fieldsToDisplay={['fullname', 'total_exams', 'total_responses', 'exams_left', 'email', 'roles', 'login_timestamps']}
                        />
                        : <p>Fetching users...</p>)}
                </TabPanel>
            )}
            {tabValue === b2bTabs.api_keys && (
                <TabPanel value={tabValue} index={3}>
                    {orgsDropdown}
                    {selectedOrgId && <ApiKeysTable orgId={selectedOrgId} />}
                </TabPanel>
            )}
        </Container>
    );
};

const TabPanel = ({ value, index, children }) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <div>{children}</div>}
        </div>
    );
};

export default B2BDashboard;
