import { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import Graph from '../../components/Recharts/Graph'
import { useDebounce } from '../../hooks/useDebounce';
import { isMobile, renderTextWithBreakLines } from '../../utils';

const EditableGraphQuestion = ({ languageData, questionIndex, title, grade, explanation, functions, isEditing = false, onChange, handleGradeChange, gradeError }) => {
    const [gradeState, setGradeState] = useState(grade);
    const debouncedGradeChange = useDebounce(handleGradeChange, 500);

    useEffect(() => {
        setGradeState(grade);
    }, [grade]);

    const handleTitleChange = (newTitle) => {
        onChange(questionIndex, { title: newTitle, explanation })
    }
    const handleExplanationChange = (newExplanation) => {
        onChange(questionIndex, { title, explanation: newExplanation })
    }

    const handleCurrentGradeChange = (event) => {
        setGradeState(event.target.value);
        debouncedGradeChange(event)
    };
    return (
        <Grid container>
            <Grid item xs={12} lg={8} order={{ xs: 2, lg: 1 }}>
                {isEditing ? (
                    <>
                        <TextField
                            label={languageData.grade_label}
                            value={gradeState ?? ""}
                            onChange={e => handleCurrentGradeChange(e)}
                            type='number'
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            error={gradeError}
                        />
                        <TextField
                            label={languageData.question_title}
                            value={title}
                            onChange={e => handleTitleChange(e.target.value)}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            error={!title}
                        />
                        <TextField
                            label={languageData.explanation_label}
                            value={explanation}
                            onChange={e => handleExplanationChange(e.target.value)}
                            fullWidth
                            multiline
                            minRows={4}
                            maxRows={10}
                            variant="outlined"
                            margin="normal"
                            error={!explanation}
                        />
                    </>
                ) : (
                    <>
                        <Typography variant={isMobile ? "body1" : "h6"} gutterBottom sx={{ fontWeight: "bolder", margin: '10px 0px' }}>
                            {renderTextWithBreakLines(title)}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {languageData.correct_answer + ': '}
                            {renderTextWithBreakLines(explanation)}
                        </Typography>
                    </>
                )}
            </Grid>
            <Grid item xs={12} lg={4} order={{ xs: 1, lg: 2 }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Graph fnStrings={functions} />
            </Grid>
        </Grid>
    );
};

export default EditableGraphQuestion;
