import React, { useState, useEffect } from "react";
// Hooks
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../../contexts/languageContext'
// MUI
import { Drawer, IconButton, CircularProgress, Typography, Divider, Button, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import LinkIcon from '@mui/icons-material/Link';
// Components
import EditableQuestionTypeManager from "../PreviewPage/EditableQuestionTypeManager";
// Utils
import { requestExamHub } from "../../requests/hub"
import { generateId, isMobile } from "../../utils";
import Unseen from "../../components/Unseen";
import ChooseLanguageBtn from "../../components/ChooseLanguageBtn";
import { handleRemix } from "../../utils/remixFunctions";
import DuplicateExamButton from "../../components/DuplicateExamButton";

function PreviewDrawer({ open, handleClose, examId }) {
    const [examJson, setExamJson] = useState(null);
    const { languageData } = useLanguage();
    const navigate = useNavigate();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (open) {
            const fetchExamJson = async () => {
                try {
                    const data = await requestExamHub(examId);
                    setExamJson(data);
                } catch (error) {
                    console.error("Error fetching exam JSON:", error);
                }
            };

            fetchExamJson();
        }
    }, [open, examId]);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopied(true);
        setTimeout(() => setCopied(false), 4000);
    };

    const direction = examJson?.parameters?.language !== 'English' ? 'rtl' : 'ltr';

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            sx={{
                '& .MuiDrawer-paper': {
                    width: isMobile ? '90vw' : '75vw',
                    padding: '16px',
                    boxSizing: 'border-box',
                    direction: direction,
                },
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
                <Typography variant="h6">{languageData?.hub?.table?.preview ?? 'Preview'}</Typography>
                <Box>
                    <ChooseLanguageBtn />
                </Box>
            </Box>

            {!examJson && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </Box>
            )}

            {examJson && (
                <Box sx={{ overflowY: 'auto', height: '100%', px: '16px', pb: 2 }}>
                    <Typography variant="h5">{examJson.title}</Typography>
                    <Divider sx={{ marginY: '1rem' }} />
                    {examJson?.instructions && <Unseen text={examJson.instructions} />}
                    {examJson?.text && <Unseen text={examJson.text} />}
                    {examJson.questions?.map((question, index) => (
                        <React.Fragment key={index}>
                            <Typography key={generateId()} variant="h5" mt={5}>
                                {languageData.preview.question + ' ' + (index + 1)}
                            </Typography>
                            {question?.image?.url && <img src={question.image.url} alt="" style={{ objectFit: 'contain', maxHeight: '300px', width: '100%' }} />}
                            <EditableQuestionTypeManager
                                key={index}
                                type={question.type}
                                allQuestionsProps={{
                                    languageData: languageData.preview,
                                    questionIndex: index,
                                    title: question.question,
                                    grade: question.grade,
                                    use_indicator: examJson?.parameters?.use_indicator
                                }}
                                nestedProps={{
                                    explanation: question.explanation,
                                    nested: question?.nested_questions,
                                    parentIndex: index + 1,
                                }}
                                openQuestionProps={{
                                    explanation: question.explanation,
                                    indicator: question?.indicator,
                                    source: question?.source_link,
                                }}
                                closeQuestionProps={{
                                    options: question.options,
                                    correctAnswer: question.correctAnswers?.[0],
                                    explanation: question.explanation
                                }}
                                GraphQuestionProps={{
                                    explanation: question.explanation,
                                    functions: question.functions,
                                }}
                            />
                        </React.Fragment>
                    ))}
                </Box>
            )}

            <Box sx={{ paddingTop: '8px', }}>
                <Divider />
                <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-start', paddingTop: '16px', gap: '16px' }}>
                    <Button
                        onClick={handleCopyLink}
                        color="primary"
                        variant="outlined"
                        startIcon={<LinkIcon />}
                        sx={{ gap: 1 }}
                    >
                        {copied ? languageData?.preview?.share_dialog?.shareWithStudents?.copied : languageData?.preview?.share_dialog?.shareWithStudents?.copy}
                    </Button>
                    <DuplicateExamButton
                        examId={examId}
                        userId={examJson?.author}
                        buttonProps={{ sx: { gap: '8px' }, color: 'secondary', variant: 'contained' }}
                        successCallback={(newId) => navigate(`/preview?id=${newId}`)}
                    />
                    <Button
                        onClick={() => handleRemix(examJson.parameters, navigate)}
                        variant="contained"
                        color="primary"
                        startIcon={<SwitchAccessShortcutIcon />}
                        sx={{ gap: 1 }}
                    >
                        {languageData?.hub?.dialog?.remix_button_text ?? 'Remix'}
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
}

export default PreviewDrawer;
