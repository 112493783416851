import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useUser } from '../hooks/useUser';

const useGoogleAds = () => {
    const { user, isPremium } = useUser();

    const GoogleAdsComponent = () => {
        return user && !isPremium() ? (
            <>
                <Helmet>
                    <script
                        async
                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8150904372533699"
                        crossOrigin="anonymous"
                    ></script>
                </Helmet>
            </>
        ) : null;
    };

    const VerticalBanner = () => {
        return user && !isPremium() ? (
            // <div style={{ height: '100%' }}>
            <ins
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-8150904372533699"
                data-ad-slot="1160892633"
                data-ad-format="auto"
                data-full-width-responsive="true"
            ></ins>
            // </div>
        ) : null;
    };

    const HorizontalBanner = () => {
        return user && !isPremium() ? (
            <ins
                className="adsbygoogle"
                style={{ display: 'inline-block', height: '50px' }}
                data-ad-client="ca-pub-8150904372533699"
                data-ad-slot="4948454826"
            ></ins>
        ) : null;
    }

    return { GoogleAdsComponent, VerticalBanner, HorizontalBanner };
};

export default useGoogleAds;
