import React, { createContext, useContext, useState } from 'react';
import useSnackBar from '../../hooks/useSnackBar';
import { SnackbarTypes } from '../../utils/snackbarTypes';
import AffiliatesAPI from '../../requests/affiliates';


const AffiliateContext = createContext();

export const useAffiliates = () => useContext(AffiliateContext);

const AffiliateProvider = ({ children }) => {
    const [affiliates, setAffiliates] = useState([]);
    const { openSnackBar } = useSnackBar()

    const fetchAffiliates = async () => {
        return AffiliatesAPI.getAll().then(affiliates => {
            setAffiliates(affiliates);
        }).catch(e => {
            console.error(e)
            openSnackBar(SnackbarTypes.ERROR.field)
        });
    };

    const createAffiliate = async (newAffiliate) => {
        const payload = {
            name: newAffiliate.name,
            exams: newAffiliate.exams,
            roles: newAffiliate.roles
        }
        return AffiliatesAPI.create(payload).then(() => {
            openSnackBar(SnackbarTypes.SUBMIT_SUCCESS.field)
            fetchAffiliates();
        }).catch(e => {
            console.error(e)
            openSnackBar(SnackbarTypes.ERROR.field)
        });;
    };

    const deleteAffiliate = async (id) => {
        return AffiliatesAPI.delete(id).then(() => {
            openSnackBar(SnackbarTypes.SUBMIT_SUCCESS.field)
            setAffiliates(affiliates.filter(affiliate => affiliate._id !== id));
        }).catch(e => {
            console.error(e)
            openSnackBar(SnackbarTypes.ERROR.field)
        });;
    };

    const updateAffiliate = async (id, newAffiliate) => {
        const payload = {
            name: newAffiliate.name,
            exams: newAffiliate.exams,
            roles: newAffiliate.roles,
            token: newAffiliate.token
        }
        return AffiliatesAPI.update(id, payload).then(() => {
            openSnackBar(SnackbarTypes.SUBMIT_SUCCESS.field)
            setAffiliates(affiliates.map(affiliate => {
                if (affiliate._id === id) {
                    return newAffiliate;
                }
                return affiliate;
            }));
        }).catch(e => {
            console.error(e)
            openSnackBar(SnackbarTypes.ERROR.field)
        });;
    };

    const copyAffiliateLink = (token) => {
        const link = `https://exam-generator.com/signup?token=${token}`;
        navigator.clipboard.writeText(link);
        alert('Affiliate link copied!');
    };

    return (
        <AffiliateContext.Provider value={{ affiliates, createAffiliate, deleteAffiliate, updateAffiliate, copyAffiliateLink, fetchAffiliates }}>
            {children}
        </AffiliateContext.Provider>
    );
};

export default AffiliateProvider;