import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useLanguage } from '../../contexts/languageContext';
import { Button, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { colors } from '../../utils/customTheme';

const StudentsList = ({ students }) => {
    const { isRTL, languageData } = useLanguage();
    const navigate = useNavigate();

    const columns = [
        {
            field: 'name',
            headerName: languageData?.review?.students_table?.name || 'Name',
            minWidth: 200,
            flex: 0.5
        },
        {
            field: 'examsTaken',
            headerName: languageData?.my_students?.exams_taken || 'Exams Taken',
            // type: 'number',
            minWidth: 200,
            flex: 0.5
        },
        {
            field: 'actions',
            flex: 0.5,
            minWidth: 200,
            headerName: languageData?.my_students?.student_overview?.actions || 'Actions',
            renderCell: (params) => (
                <Button
                    variant="contained"
                    onClick={() => navigate(`${params.row.id}`)}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 'medium',
                        bgcolor: colors.main,
                        '&:hover': { bgcolor: colors.mainHover },
                    }}
                >
                    {languageData?.my_students?.view_student || "View Student's Performance"}
                </Button>
            ),
            sortable: false,
            filterable: false,
        },
    ];

    const rows = students?.map((student, index) => ({
        id: student?.studentInfo?._id || index,
        name: student?.studentInfo?.name || '-',
        examsTaken: student?.responses?.length || 0,
    }));

    return (
        <div style={{ width: '100%', height: 'auto', marginBottom: '5rem' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
                disableSelectionOnClick
                autoHeight
                sx={{
                    direction: isRTL ? 'rtl' : 'ltr',
                    '& .MuiDataGrid-cell': {
                        direction: isRTL ? 'rtl' : 'ltr',
                        textAlign: isRTL ? 'right' : 'left',
                    },
                    '& .MuiDataGrid-footerContainer': {
                        direction: 'ltr'
                    }
                }}
            />
        </div>
    );
};

export default StudentsList;