import React, { useEffect, useState } from 'react';
// Components
import { Button, CircularProgress, Typography, Grid, Box } from '@mui/material';
import ExamDataGrid from '../components/HistoryDataGrid';
import Header from '../components/Header';
// Hooks
import useSnackBar from '../hooks/useSnackBar';
import useHistory from '../hooks/useHistory';
import { colors, paddingPageStyle } from '../utils/customTheme';
import { Add as AddIcon } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
// import { SnackbarTypes } from '../utils/snackbarTypes';
import { useLanguage } from '../contexts/languageContext';
import { useUser } from '../hooks/useUser';
import Filters from '../components/Filters/Filters';
import { applyExamFilters } from '../components/Filters/filtersUtils';
import useGoogleAds from '../components/GoogleAds';

const HistoryPage = () => {
    const { HorizontalBanner } = useGoogleAds();
    const { isPremium } = useUser();
    const { history, deleteFromHistory } = useHistory();
    const { languageData, isRTL } = useLanguage();
    const { openSnackBar } = useSnackBar();
    const [historyData, setHistoryData] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (history?.length > 0) {
            if (!initialized) {
                setLoading(true);
                setHistoryData(history);
                setInitialized(true);
                setLoading(false);
            } else {
                setHistoryData(history);
            }
        } else {
            setInitialized(false);
            setLoading(false);
        }
    }, [history, initialized]);

    const deleteExam = (examId) => {
        openSnackBar('DELETE_EXAM');
        deleteFromHistory(examId)
            .then(() => {
                openSnackBar('EXAM_DELETED');
            })
            .catch((err) => {
                openSnackBar('ERROR');
            });
    };

    const handleFilterChange = (filters) => {
        const filteredHistory = applyExamFilters(history, filters);
        setHistoryData(filteredHistory);
    };

    return (
        <>
            <Helmet>
                <title>StudyWise | My Exams</title>
                <meta
                    name="description"
                    content="Review your past exams and track your progress with our AI-powered exam generator. Easily revisit and analyze previous exams to enhance future learning outcomes."
                />
            </Helmet>
            <Header />
            <HorizontalBanner />
            <Box sx={{ ...paddingPageStyle, padding: '15px' }}>
                <Grid container spacing={0} justifyContent="center">
                    <Grid item xs={12}>
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 700,
                                fontSize: '2rem',
                                direction: isRTL ? 'rtl' : 'ltr',
                                textAlign: 'center',
                            }}
                        >
                            {languageData?.history?.title?.replace('{amount}', historyData?.length ?? 0) ?? ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Filters onFilterChange={handleFilterChange} />
                    </Grid>
                    <Grid item xs={12}>
                        {historyData?.length > 0 ? (
                            <ExamDataGrid exams={historyData} deleteExam={deleteExam} />
                        ) : loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '3rem' }}>
                                <CircularProgress disableShrink size="4rem" sx={{ color: colors.main }} />
                            </Box>
                        ) : (
                            <Box sx={{ textAlign: 'center', marginTop: '3rem' }}>
                                <Typography>{languageData?.history?.no_exams || 'No exams available'}</Typography>
                                <Button
                                    variant="contained"
                                    sx={{ borderRadius: '50%', width: '100px', height: '100px', marginTop: '2rem' }}
                                    onClick={() => (window.location.href = '/exam')}
                                >
                                    <AddIcon sx={{ fontSize: '3rem' }} />
                                </Button>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default HistoryPage;
