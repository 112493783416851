import { useState, useMemo, useEffect } from "react";
import { Popover, TextField, Button, Stack, Select, MenuItem } from "@mui/material"
import PropTypes from 'prop-types';
import ROLES from "../../utils/roles";
import { useOrgDashboard } from "../../contexts/Dashboard/OrgContext";

/**
 * @param {{anchorEl: Element, handleClosePopOver: Function, item: {_id: string, email: string, fullname: string, exams_left: number, role?: string, roles: string[], created_date: string}, submitEditUser: Function, onRequestUserAnalytics: Function}} param0 
 */
const EditUserPopover = ({ anchorEl, handleClosePopOver, item, submitEditUser, onRequestUserAnalytics }) => {
    const { orgDashboardData, fetchOrgDashboardData } = useOrgDashboard()
    const [newRoles, setNewRoles] = useState(item?.roles ?? (item?.role ? [item.role] : []))
    const [newExamsLeft, setNewExamsLeft] = useState(item?.exams_left)
    const [newOrgId, setNewOrgId] = useState(item?.org_id)
    const rolesOptions = useMemo(() => Object.values(ROLES).filter(role => role.label).map(role => ({ label: role.label, value: role.label })), [])

    useEffect(() => {
        if (Boolean(anchorEl) && !orgDashboardData)
            fetchOrgDashboardData().catch(e => console.error(e))
    }, [anchorEl])

    const handleSubmit = () => {
        handleClosePopOver()
        const roles = newRoles ?? item?.roles
        const examsLeft = newExamsLeft ?? item?.exams_left
        submitEditUser(item, { roles, examsLeft, newOrgId })
    }

    const handleRequestAnalytics = () => {
        handleClosePopOver()
        onRequestUserAnalytics(item?._id, item?.email)
    }
    return (
        <Popover
            id={'simple-popover'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClosePopOver}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                style: {
                    width: anchorEl ? anchorEl.clientWidth : 'auto',
                    padding: '10px'
                }
            }}
        >
            <Stack spacing={2}>
                {/* Dropdown for role */}
                <Select
                    id={'roles'}
                    label={'Roles:'}
                    multiple
                    value={newRoles}
                    onChange={e => setNewRoles(e.target.value)}
                    variant={'filled'}
                >
                    {rolesOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                {/* Input for exams_left */}
                <TextField
                    label="Exams Left"
                    type="number"
                    variant="outlined"
                    value={newExamsLeft}
                    onChange={(e) => setNewExamsLeft(e.target.value)}
                    fullWidth
                    autoComplete="off"
                    inputProps={{ style: { fontSize: 15 } }}
                    InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
                />
                <Select
                    id='orgs'
                    label='Orgs'
                    value={newOrgId}
                    onChange={(e) => setNewOrgId(e.target.value)}
                >
                    {orgDashboardData?.orgs?.concat({ _id: null, name: 'none' }).map(org => (
                        <MenuItem key={org._id} value={org._id}>
                            {org.name}
                        </MenuItem>
                    ))}
                </Select>
                {/* Submit button */}
                <Button variant="outlined" onClick={handleRequestAnalytics}>Request Analytics</Button>
                <Button variant="contained" onClick={handleSubmit}>Submit</Button>
            </Stack>

        </Popover>
    )
}

export default EditUserPopover

EditUserPopover.proptypes = {
    anchorEl: PropTypes.element,
    handleClosePopOver: PropTypes.func,
    item: PropTypes.shape({
        _id: PropTypes.string,
        email: PropTypes.string,
        fullname: PropTypes.string,
        exams_left: PropTypes.number,
        role: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]), //optional
        roles: PropTypes.string,
        created_date: PropTypes.string
    }),
    popoverSubmitFunc: PropTypes.func
}